export interface MatchdayActivityDto {
    id: number;
    uuid: string;
    sortOrder: number;
    name: string;
    shortDescription: string;
    description: string;
    imageUrl: string;
    active: boolean;
    iconUrl: string;
}

export enum FeedbackSource {
    Telegram = 'tg',
    VK = 'vk',
}

export enum FeedbackViewType {
    Manual = 'manual',
    TgWidget = 'tg',
    Image = 'image',
}

export interface FeedbackDto {
    id: number;
    name?: string;
    text: string;
    imageUrl?: string;
    linkUrl?: string;
    viewType: FeedbackViewType;
}

export interface MatchdayFeedbackDtoExt {
    id: number;
    name?: string;
    text: string;
    imageUrl?: string;
    createdAt: Date;
    matchId?: number;
    approved: boolean;
    sortOrder: number;
    linkUrl?: string;
    source?: FeedbackSource;
    viewType?: FeedbackViewType;
}

export interface MatchdayConfig {
    landingEnabled?: boolean;
    pageHeaderUrl?: string;
}
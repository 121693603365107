import { FC } from "react";
import { SocialLinksContainer, SocialLink, SocialIcon } from "../SocialLinks/styled";


interface SocialShareProps {
    title: string;
    imageUrl?: string;
    url: string;
    description?: string;
    onShareCallback?: () => void;
    keys?: string[];
    file?: File;
    iconClassName?: string;
}

interface SocialMediaShareConfig {
    url: string;
    icon: string;
    onClick?: () => void;
}

export const SocialShare: FC<SocialShareProps> = ({
    title,
    imageUrl,
    url,
    description,
    onShareCallback,
    keys,
    file,
    iconClassName,
}) => {
    const encodedParams = {
        title: encodeURIComponent(title),
        description: encodeURIComponent(description),
        imageUrl: encodeURIComponent(imageUrl),
        url: encodeURIComponent(url),
    };

    const socialMediaSharesUrls: Record<string, SocialMediaShareConfig> = {
        vk: {
            url: `https://vk.com/share.php?url=${encodedParams.url}&title=${encodedParams.title}&comment=${encodedParams.description}&image=${encodedParams.imageUrl}`,
            icon: "static/images/social/blue/vk.svg",
        },
        ok: {
            url: `https://connect.ok.ru/offer?url=${encodedParams.url}&title=${encodedParams.title}&description=${encodedParams.description}&imageUrl=${encodedParams.imageUrl}`,
            icon: "static/images/social/blue/ok.svg",
        },
        telegram: {
            url: `https://t.me/share/url?url=${encodedParams.url}&text=${encodedParams.description}&photo=${encodedParams.imageUrl}`,
            icon: "static/images/social/blue/telegram.svg",
        },
        whatsapp: {
            url: `https://api.whatsapp.com/send?text=${encodedParams.description} ${encodedParams.url}`,
            icon: "static/images/social/blue/whatsapp.svg",
        },
        twitter: {
            url: `https://twitter.com/intent/tweet?url=${encodedParams.url}&text=${encodedParams.description}`,
            icon: "static/images/social/blue/twitter.svg",
        },
        /*facebook: {
            url: `https://www.facebook.com/sharer/sharer.php?u=${url}&caption=${title}&description=${description}&picture=${imageUrl}`,
            icon: "static/images/social/blue/facebook.svg",
        },*/
    }

    if (navigator.share) {
        let files: File[];
        let text = description;

        let canShare = !!navigator.canShare && navigator.canShare({ text: description });
        const isAndroid = /Android/.test(navigator.userAgent);
        if (file && !isAndroid) {
            files = [file];
            canShare = canShare && navigator.canShare({ files: [file] })
        } else {
            text += ' ' + url;
        }

        if (canShare) {
            socialMediaSharesUrls["share"] = {
                url: "",
                icon: "static/images/social/blue/share.svg",
                onClick: () => {
                    if (navigator.share) {
                        navigator.share({
                            title,
                            text,// + ' ' + url,
                            // url,
                            files,
                        })
                            .then(() => onShareCallback && onShareCallback())
                            .catch((error) => console.error('Ошибка при попытке поделиться'));
                    }
                },
            };
        }
    }

    const onShare = (key: string) => {
        let shareData = socialMediaSharesUrls[key];

        if (shareData.onClick) {
            shareData.onClick();
            return;
        }

        window.open(shareData.url, "_blank", "width=420,height=350,resizable=yes,scrollbars=yes,status=yes");
        if (onShareCallback) {
            onShareCallback();
        }
    }

    const keysToRender = keys?.length ? keys : Object.keys(socialMediaSharesUrls);

    return (
        <SocialLinksContainer>
            {keysToRender.map((key) => {
                const val = socialMediaSharesUrls[key];
                return (
                    <SocialLink key={key} onClick={(e) => {
                        e.preventDefault();
                        onShare(key)
                        return false;
                    }}>
                        <SocialIcon src={`https://fcrodina.com/${val.icon}`} alt={key} className={iconClassName}/>
                    </SocialLink>
                );
            })}
        </SocialLinksContainer>
    );
}

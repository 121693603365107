import { FC } from "react";
import {
    Parameter,
    ParameterName,
    ParameterValue,
    ParametersContainer,
    PersonPhoto,
    PersonProfileCardContainer,
    PlayerBgNumber,
    PlayerBgNumberDigit,
    PlayerName,
    PlayerPosition,
    PlayerNumber,
    ControlsContainer,
} from "./styled";
import { CalendarOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { PlayerDto, PlayerPositionGroup } from "../../../types/player";
import { Loader } from "../../../components/Loader";
import { calculateAge, getFormattedDate } from "../../../utils";
import { PlayerNavigationButton } from "./PlayerNavigationButton";
import { Tooltip } from "antd";

interface PlayerMainInfoWidgetProps {
    playerData: PlayerDto | null;
    loading?: boolean;
}

export const PlayerMainInfoWidget: FC<PlayerMainInfoWidgetProps> = ({
    playerData,
    loading,
}) => {
    const isGk = playerData?.positionGroup === PlayerPositionGroup.Goalkeeper;
    const number = playerData?.number ? playerData?.number?.toString() : '';

    let content = <Loader />;

    if (!loading && playerData) {
        const age = calculateAge(playerData.birthDate);
        content = (
            <>
                {number && (
                    <PlayerBgNumber>
                        <PlayerBgNumberDigit key={number?.[0]}>{number?.[0]}</PlayerBgNumberDigit>
                        <PlayerBgNumberDigit key={number?.[1]}>{number?.[1]}</PlayerBgNumberDigit>
                    </PlayerBgNumber>
                )}
                {playerData?.photo && <PersonPhoto src={playerData?.photo} alt={playerData.name} />}
                <ParametersContainer>
                    {age &&
                        <Tooltip title={
                            <>
                                <CalendarOutlined style={{ marginRight: '8px' }} />{getFormattedDate(new Date(playerData.birthDate))}
                            </>
                        }
                        >
                            <Parameter>
                                <ParameterName>Возраст</ParameterName>
                                <ParameterValue>{age}</ParameterValue>
                            </Parameter>
                        </Tooltip>
                    }
                    <Parameter>
                        <ParameterName>Матчи</ParameterName>
                        <ParameterValue>{playerData?.stats?.matches}</ParameterValue>
                    </Parameter>
                    {isGk && <Parameter>
                        <ParameterName>Сухие</ParameterName>
                        <ParameterValue>{playerData?.stats?.cleanSheets}</ParameterValue>
                    </Parameter>}
                    {!isGk && <Parameter>
                        <ParameterName>Голы</ParameterName>
                        <ParameterValue>{playerData?.stats?.goals}</ParameterValue>
                    </Parameter>}
                </ParametersContainer>
                <PlayerNumber>{number}</PlayerNumber>
                <PlayerName>{playerData?.name}</PlayerName>
                <PlayerPosition>{playerData?.position}</PlayerPosition>
                <ControlsContainer>
                    <PlayerNavigationButton
                        direction="prev"
                        playerData={playerData?.prev}
                        icon={<LeftOutlined />}
                    />
                    <PlayerNavigationButton
                        direction="next"
                        playerData={playerData?.next}
                        icon={<RightOutlined />}
                    />
                </ControlsContainer>
            </>
        );

        return (
            <PersonProfileCardContainer>
                {content}
            </PersonProfileCardContainer>
        );
    }
}
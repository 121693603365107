import { Button, Form, Result, Spin, Upload, message } from "antd";
import { useState } from "react";
import { ButtonContainer, Container, Content, CosmosUploadForm, PhotoNumber, PreviewImage, PreviewImageContainer, SubmitButton } from "./styled";
import { UploadContent } from "../../components/forms/Upload";
import { DeleteOutlined } from "@ant-design/icons";
import { getBase64, validateImageSize } from "../../utils";
import { FileType } from "../../types/common";
import { sendCosmosPromoForm } from "../../api/transport/promoTransport";
import { FormInput } from "../../components/forms/styled";

const { Dragger } = Upload;

export const CosmosPromoUploaderPage = () => {
    const [loaded, setLoaded] = useState(false);
    const [formSent, setFormSent] = useState(false);
    const [formSending, setFormSending] = useState(false);
    const [previewImage, setPreviewImage] = useState<string | undefined>(undefined);
    const [previewProcessing, setPreviewProcessing] = useState(false);
    const [photoId, setPhotoId] = useState(0);

    const onDeleteUpload = () => {
        setPreviewImage(undefined);
    }

    // Image file validator for Upload component
    const imageFileValidator = (file: FileType) => {
        const isImage = file.type.startsWith('image/');
        if (!isImage) {
            console.error('Please upload only image files!');
            return false;
        }

        if (!validateImageSize(file)) {
            console.error('File size is too big or zero');
            message.error('Файл должен быть изображением и не превышать 20 мб');
            return false;
        }

        setPreviewProcessing(true);
        getBase64(file).then((url) => {
            setPreviewImage(url as string);
            setPreviewProcessing(false);
        });

        return false;
    };

    const onFormFinish = (values: any) => {
        const formData = new FormData();
        formData.append('image', values.upload[0].originFileObj);
        if (values.id) {
            formData.append('id', values.id);
        }
        setFormSending(true);
        sendCosmosPromoForm(formData).then(data => {
            setFormSent(true);
            setFormSending(false);
            setPhotoId(data?.id);
        }).catch((error) => {
            console.error(error);
            message.error('Ошибка отправки! Пожалуйста, попробуйте обновить страницу или повторить позже.');
            setFormSending(false);
        });
    }

    const resetForm = () => {
        setFormSent(false);
        setPhotoId(0);
        setPreviewImage(undefined);
    }

    if (formSent) {
        return (
            <Container>
                <Content>
                    <Result
                        status="success"
                        title={<>Номер загруженного фото:</>}
                        subTitle={<PhotoNumber>{photoId}</PhotoNumber>}
                        extra={[
                            <ButtonContainer>
                                <SubmitButton size="large" onClick={resetForm}>
                                    Загрузить новое
                                </SubmitButton>
                            </ButtonContainer>
                        ]}
                    />
                </Content>
            </Container>
        )
    }

    return (
        <Container>
            <Content>
                <CosmosUploadForm onFinish={onFormFinish}>
                    <Form.Item
                        name="upload"
                        valuePropName="fileList"
                        hidden={!!previewImage}
                        getValueFromEvent={(e) => e.fileList}
                        rules={[{
                            required: true,
                            message: 'Пожалуйста, загрузите фото!',
                        }, {
                            validator: (rule, value) => {
                                if (
                                    !value ||
                                    (!previewImage && !previewProcessing) ||
                                    value.length === 0 ||
                                    !value[0].originFileObj
                                ) {
                                    console.error('No file!');
                                    return Promise.reject('Пожалуйста, загрузите фото!');
                                } else if (!validateImageSize(value[0].originFileObj)) {
                                    console.error('Wrong file!');
                                    return Promise.reject('Файл должен быть изображением и не превышать 20 мб!');
                                }
                                return Promise.resolve();
                            }
                        }]}
                    >
                        <Dragger
                            name="file"
                            accept="image/*"
                            beforeUpload={imageFileValidator}
                            showUploadList={false}
                            maxCount={1}
                            style={{ display: previewImage ? 'none' : 'flex'}}
                        >
                            {!previewImage && (
                                <UploadContent
                                    text="Нажмите или перетяните фото в эту область"
                                />
                            )}
                        </Dragger>
                    </Form.Item>
                    {previewImage && (
                        <PreviewImageContainer>
                            <PreviewImage src={previewImage} alt="Preview" preview={true} />
                            <Button danger type="link" icon={<DeleteOutlined />} onClick={onDeleteUpload}>
                                Удалить фото
                            </Button>
                        </PreviewImageContainer>)
                    }
                    <Form.Item name="id" help="Если не указано, то будет сгенерировано автоматически">
                        <FormInput placeholder="Номер фото" />
                    </Form.Item>
                    <Form.Item>
                        <ButtonContainer>
                            {!formSending && <SubmitButton size="large">
                                Загрузить
                            </SubmitButton>}
                            {formSending && <Spin size="large" />}
                        </ButtonContainer>
                    </Form.Item>
                </CosmosUploadForm>
            </Content>
        </Container>
    );
}

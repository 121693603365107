import { BACKEND_URL } from "..";

export const getVacancies = async () => {
    const response = await fetch(BACKEND_URL + '/vacancies', {
        method: "GET",
    });

    const data = await response.json();
    if (response.status !== 200) {
        console.error(data);
        return [];
    }

    return data;
}

export const getVacancyById = async (uuid: string) => {
    const response = await fetch(BACKEND_URL + '/vacancies/' + uuid, {
        method: "GET",
    });

    const data = await response.json();
    if (response.status !== 200) {
        console.error(data);
        return null;
    }

    return data;
}

export const createApplication = async (uuid: string, data: FormData) => {
    const response = await fetch(BACKEND_URL + '/vacancies/' + uuid + '/applications', {
        method: "POST",
        body: data,
    });

    const responseData = await response.json();
    if (response.status !== 200) {
        console.error(responseData);
        return null;
    }

    return responseData;
}

import { FC } from "react";
import { StatCoachDto } from "../../../../types/stats";
import { getStaticUrl, getWordEnding } from "../../../../utils";
import { motion } from "framer-motion";

interface CoachProps extends StatCoachDto { }

export const CoachCard: FC<CoachProps> = ({
    name,
    wins,
    draws,
    losses,
    winStreak,
    noLossStreak,
    //firstMatch,
    //lastMatch,
    photoUrl,
    firstMatchData,
    lastMatchData,
}) => {
    const winsPecentage = ((wins / (wins + draws + losses)) * 100).toFixed(2);

    const firstMatch = <a href={firstMatchData.url} className="hover:no-underline hover:opacity-90">{firstMatchData.name}</a>;
    const lastMatch = <a href={lastMatchData.url} className="hover:no-underline hover:opacity-90">{lastMatchData.name}</a>;

    return (
        <div className="transition-all flex justify-between opacity-95 hover:opacity-100 items-center gap-4 border-0 border-b hover:bg-sky-100/25 hover:scale-105 border-solid pt-4 px-8 border-b-slate-100 rounded max-w-5xl">
            <motion.div
                initial={{ opacity: 0, x: -100 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5 }}
                className="text-center self-end pl-8"
            >
                <img src={getStaticUrl(photoUrl)} alt={name} className="w-80" />
            </motion.div>
            <motion.div
                initial={{ opacity: 0, x: 100 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5 }}
                className="pl-16 pb-4 w-2/3 text-3xl font-medium text-sky-950"
            >
                <h2 className="text-5xl text-sky-950">{name}</h2>
                <div className="flex flex-col gap-4 pt-12">
                    <div>Побед: {winsPecentage}%</div>
                    <div>Победная серия: {winStreak} {getWordEnding(winStreak, ['матч', 'матча', 'матчей'])}</div>
                    <div>Беспроигрышная серия: {noLossStreak} {getWordEnding(noLossStreak, ['матч', 'матча', 'матчей'])}</div>
                    <div>Первый матч: {firstMatch}</div>
                    <div>Последний матч: {lastMatch}</div>
                </div>
            </motion.div>
        </div>
    );
};
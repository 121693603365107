import { Card } from "antd";
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    min-height: 500px;
    padding-bottom: 64px;
    width: 100%;
    box-sizing: border-box;
    gap: 20px;
    margin: 0 auto;
    @media (min-width: 500px) {
        flex-direction: row;
        flex-wrap: wrap;
    }
`;

export const Content = styled.div`
`;

export const CardWrapper = styled.div`
    position: relative;
    max-width: 500px;
    display: flex;
    flex-direction: row;
`;

export const FeedbackContent = styled(Card)`
    font-size: 16px;
`;

export const BubbleIcon = styled.div`
    position: absolute;
    top: 0;
    left: 39px;
    z-index: 1;
`;

export const DefaultUserIcon = styled.div`
    width: 40px;
    height: 40px;
    padding: 10px;
`;

export const CollapseTitle = styled.div`
    font-family: 'Bebas Neue', sans-serif;
    font-weight: bold;
    font-size: 24px;
    color: #133D5E;
    margin-bottom: 20px;
`;
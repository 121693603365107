import styled from "styled-components";
import { FCRodinaButton } from "../../../components/Button";

export const Container = styled.div`
    position: relative;
    margin-top: 32px;
`;

export const BuyButton = styled(FCRodinaButton)`
    position: absolute;
    bottom: 40px;
    right: 40px;
    background-color: #3da13f;
    &:hover {
        box-shadow: 0 0 10px 0 rgba(255,255,255,0.3);
    }
`;

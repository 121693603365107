import { GetProp, UploadProps } from "antd";
import { StatsFilter } from "../stats";
export type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

export enum EntityType {
    PLAYER = 'player',
    TEAM_PLAYER = 'teamPlayer',
    NEWS = 'news',
}

export enum TeamContext {
    RODINA = 'rodina',
    ACADEMY = 'academy',
    RODINA_2 = 'rodina-2',
    RODINA_3 = 'rodina-3',
    RODINA_M = 'rodina-m',
}

export interface MainScreenFilter extends StatsFilter {
    title?: string;
}

interface MiniTableConfig {
    topTeams: number;
    bottomTeams: number;
}

interface MiniMatchesTablerConfig {
    prevMatches: number;
    totalMatches?: number;
}

interface MainScreenConfig {
    filters: MainScreenFilter[];
    table?: MiniTableConfig;
    matches?: MiniMatchesTablerConfig;
}

export interface Configuration extends StatsFilter {
    mainScreenConfig?: MainScreenConfig;
    teamName?: TeamContext;
    standalone?: boolean;
}

export enum MediaType {
    YOUTUBE = 'youtube',
    VK = 'vk',
    RT = 'rutube',
    MP4 = 'mp4',
    IMAGE = 'image',
    UNKNOWN = 'unknown',
}

export enum AccreditationType {
    Press = 'press',
    Blogger = 'blogger',
    Photo = 'photo',
    Writer = 'writer',
    Video = 'video',
    Other = 'other',
}

export interface AccreditationRequestDto {
    clubEmail?: string;
    fullName: string;
    email: string;
    phoneNumber: string;
    publisher: string;
    accreditationType: AccreditationType;
    comment: string;
    date: string;
}

import styled from 'styled-components';

export const PlayerNumber = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
    font-family: 'Bebas Neue', sans-serif;
    font-size: 320px;
    font-weight: 500;
    padding: 0 24px;
    color: #000;
    opacity: 0.15;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
    z-index: 0;
`;

export const PlayerNumberDigit = styled.div`
    padding-top: 4px;
    vertical-align: bottom;
`;
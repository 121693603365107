import { Carousel } from "antd";
import { CalendarEvent } from "./CalendarEvent";
import { CarouselContainer, NextButton, PrevButton } from "./styled";
import { CalendarDto, MatchStatus } from "../../types/stats";
import { FC, ReactNode, useEffect, useRef } from "react";
import { CarouselRef } from "antd/es/carousel";
import { CloseOutlined, LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";

interface CalendarCarouselProps {
    matches: CalendarDto;
    lastItemLink?: string;
}

export const CalendarCarousel: FC<CalendarCarouselProps> = ({
    matches,
    lastItemLink,
}) => {
    const settings = {
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
        infinite: false,
        arrows: false,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: false,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: false,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
        ],
        swipe: true,
        draggable: true,
    };

    const carouselRef = useRef<CarouselRef>(null);

    const nearestMatchIndex = matches.findIndex(
        (item) => (new Date(item.date)).getTime() > Date.now());

    let initialSlide = matches.length - settings.slidesToShow;
    if (nearestMatchIndex !== -1) {
        initialSlide = nearestMatchIndex - settings.slidesToShow + 1;
    }

    if (initialSlide < 0) {
        initialSlide = 0;
    }

    return (
        <CarouselContainer className="bg-slate-800 rounded-sm">
            <Carousel {...settings} ref={carouselRef} initialSlide={initialSlide}>
                {matches.map((match) => {
                    let scoreText: ReactNode = '';
                    if (match.status === MatchStatus.FINISHED) {
                        scoreText = match.score;
                    } else if (match.status === MatchStatus.IN_PROGRESS) {
                        scoreText = 'Live';
                    } else if (match.status === MatchStatus.UPCOMING) {
                        //scoreText = '-';
                    } else if (match.status === MatchStatus.СANCELED) {
                        scoreText = <CloseOutlined style={{color: 'red'}}/>;
                    }

                    const date = new Date(match.date);
                    const dateTime = (match.preliminaryDate || isNaN(date.getTime())) ? 'Дата не определена' : date.toLocaleDateString('ru-RU', {
                        day: 'numeric',
                        month: 'long',
                        hour: '2-digit',
                        minute: '2-digit',
                    });

                    return (
                        <CalendarEvent
                            key={match.id}
                            name={match.name}
                            url={match.url}
                            date={dateTime}
                            team1={match.homeTeam}
                            team2={match.guestTeam}
                            score={scoreText}
                            matchUrl={match.matchUrl}
                            ticketUrl={match.status === MatchStatus.UPCOMING ? match.tickets : undefined}
                        />
                    )
                })}
            </Carousel>
            <PrevButton onClick={() => carouselRef.current?.prev()}>
                <LeftCircleOutlined />
            </PrevButton>
            <NextButton onClick={() => {
                if (carouselRef?.current && carouselRef.current.innerSlider.state.currentSlide + 1 > initialSlide) {
                    return window.open(lastItemLink || '/calendar', '_self');
                }
                carouselRef.current?.next();
            }}>
                <RightCircleOutlined />
            </NextButton>
        </CarouselContainer>
    );
}
import { BACKEND_URL, buildApiUrl } from "..";
import { TeamContext } from "../../types/common";
import { StaffDto } from "../../types/staff";
import { getStaticUrl } from "../../utils";

const mapStaff = (staff: StaffDto) => ({
    ...staff,
    photoUrl: getStaticUrl(staff.photoUrl),
    birthDate: staff.birthDate.startsWith('1899') ? '' : staff.birthDate,
});

export const getManagement = async (teamContext?: TeamContext) => {
    const response = await fetch(
        BACKEND_URL + buildApiUrl('/staff/management', teamContext)
    );

    const data = await response.json();
    if (response.status !== 200) {
        console.error(data);
        return [];
    }
    return data.map(mapStaff) as StaffDto[];
}


export const getAdministrative = async (teamContext?: TeamContext) => {
    const response = await fetch(
        BACKEND_URL + buildApiUrl('/staff/administrative', teamContext)
    );

    const data = await response.json();
    if (response.status !== 200) {
        console.error(data);
        return [];
    }

    return data.map(mapStaff) as StaffDto[];
}

export const getCoaches = async (teamContext?: TeamContext) => {
    const response = await fetch(
        BACKEND_URL + buildApiUrl('/staff/coaches', teamContext)
    );

    const data = await response.json();
    if (response.status !== 200) {
        console.error(data);
        return [];
    }

    return data.map(mapStaff) as StaffDto[];
}


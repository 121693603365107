import { FC, useEffect, useState } from "react";
import { PlayerMainInfoWidget } from "../../widgets/player/PlayerMainInfo";
import { PlayerExtendedInfoWidget } from "../../widgets/player/PlayerExtendedInfo";
import { getPlayerInfo } from "../../api/transport/playersTransport";
import { PersonBio } from "../../components/player/PersonBio";
import { PlayerDto } from "../../types/player";
import { PlayerPageContainer } from "./styled";
import { TeamContext } from "../../types/common";
import { PlayerNewsWidget } from "../../widgets/player/PlayerNews";
import { Loader } from "../../components/Loader";
import { PlayerTournamentSelectorWidget } from "../../widgets/player/PlayerTournamentSelector";
import { TournamentUuids } from "../../types/stats";

interface PlayerProfilePageProps {
    playerId: string;
    teamContext?: TeamContext;
    tournamentId?: number;
    seasonId?: number;
    teamId?: number;
}

export const PlayerProfilePage: FC<PlayerProfilePageProps> = ({
    playerId,
    teamContext,
    tournamentId,
    seasonId,
    teamId,
}) => {
    const [playerData, setPlayerData] = useState<PlayerDto | null>(null);
    const [loading, setLoading] = useState(false);
    const [tournamentUuids, setTournamentUuids] = useState<TournamentUuids>();

    useEffect(() => {
        if (!playerId) {
            return;
        }

        setLoading(true);
        getPlayerInfo(playerId, teamContext).then((data: PlayerDto) => {
            setPlayerData(data);
        }).finally(() => {
            setLoading(false);
        });
    }, [playerId, teamContext]);

    if (loading) {
        return (
            <PlayerPageContainer>
                <Loader />
            </PlayerPageContainer>
        );
    }

    return (
        <PlayerPageContainer>
            <PlayerMainInfoWidget
                playerData={playerData}
                loading={loading}
            />
            <PersonBio
                title="Биография"
                content={playerData?.bio}
            />
            <PlayerTournamentSelectorWidget
                playerId={playerId}
                teamContext={teamContext}
                onTournamentChange={(tournamentUuid, seasonUuid) => {
                    console.log(tournamentId, seasonId);
                    setTournamentUuids({ tournamentUuid, seasonUuid });
                }}
            />
            {tournamentUuids?.seasonUuid && tournamentUuids?.tournamentUuid && (
                <PlayerExtendedInfoWidget
                    playerId={playerId}
                    playerData={playerData}
                    teamContext={teamContext}
                    tournamentUuid={tournamentUuids?.tournamentUuid}
                    seasonUuid={tournamentUuids?.seasonUuid}
                    teamId={teamId}
                />
            )}
            <PlayerNewsWidget playerId={playerId} />
        </PlayerPageContainer>
    );
}
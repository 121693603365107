import { useEffect, useState } from "react";
import { RecordCard } from "./RecordCard";
import { getClubRecords } from "../../../../api/transport/statsTransport";
import { StatsRecord } from "../../../../types/stats";
import { Loader } from "../../../../components/Loader";
import { motion } from "framer-motion";


const listVariants = {
    visible: {
        opacity: 1,
        transition: {
            staggerChildren: 0.05, // Задержка между анимациями элементов
        },
    },
    hidden: { opacity: 0 },
};

const itemVariants = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: 50 },
};

export const Records = () => {
    const [records, setRecords] = useState<StatsRecord[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        setLoading(true);
        getClubRecords().then((data) => {
            setRecords(data);
        }).catch((error) => {
            console.error(error);
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    if (loading) {
        return (
            <div className="text-center w-full min-h-100">
                <Loader />
            </div>
        )
    }


    return (
        <motion.div
            initial="hidden"
            animate="visible"
            variants={listVariants}
            className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 pb-1 box-border"
        >
            {records.map((record, index) => (
                <motion.div
                    key={index}
                    variants={itemVariants}
                >
                    <RecordCard
                        key={index}
                        title={record.title}
                        text={record.description}
                        linkUrl={record.linkUrl}
                    />
                </motion.div>
            ))}
        </motion.div>
    )
};
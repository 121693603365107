import { BellOutlined, EnvironmentOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { CitiesPromoButtons, ContestButton } from "../styled";
import { getStaticUrl } from "../../../../../utils";

export const CitiesButtons = () => {
    return (
        <CitiesPromoButtons>
        <ContestButton
            icon={<EnvironmentOutlined />}
            onClick={() => window.open('https://vk.com/video/playlist/-191701576_11')}
        >
            Прошедшие выезды
        </ContestButton>
        <ContestButton
            icon={<BellOutlined />}
            onClick={() => window.open('https://t.me/s/fcrodina?q=%23%D0%93%D0%BE%D1%80%D0%BE%D0%B4%D0%B0%D0%A0%D0%BE%D0%B4%D0%B8%D0%BD%D1%8B')}
        >
            Новости
        </ContestButton>
        <ContestButton
            icon={<InfoCircleOutlined />}
            onClick={() => window.open(getStaticUrl('/uploads/docs/CitiesPromoRules.pdf'))}
        >
            Полные правила конкурса
        </ContestButton>
    </CitiesPromoButtons>
    );
};
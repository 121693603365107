import { FC, useEffect, useState } from "react";
import { MenuItemDto } from "./types";
import { SocialLinksList } from "../../widgets/social/SocialLinks";
import styled from "styled-components";

interface MenuItemProps {
    title: string;
    link: string;
}

export const MenuItem: FC<MenuItemProps> = ({
    title,
    link
}) => {
    const classNames = 'text-slate-300 text-xl font-bold uppercase mb-2 transition-colors duration-200';
    if (link) {
        return (
            <a href={link} className={classNames + ' hover:text-white'}>
                {title}
            </a>
        );
    }

    return (
        <div className={classNames}>
            {title}
        </div>
    );
}

export const SubMenuItem: FC<MenuItemProps> = ({
    title,
    link
}) => {
    const classNames = 'text-slate-300 text-md transition-colors duration-200';
    if (link) {
        return (
            <a href={link} className={classNames + ' hover:text-white'}>
                {title}
            </a>
        );
    }

    return (
        <div className={classNames}>
            {title}
        </div>
    );
}

const Wrapper = styled.div`
    font-family: 'Futura', sans-serif;
`;

interface MobileMenuProps {
    collapsed?: boolean;
    items?: MenuItemDto[];
}

export const MobileMenu: FC<MobileMenuProps> = ({
    items,
    collapsed = false
}) => {
    const [isOpened, setIsOpened] = useState(false);

    useEffect(() => {
        setIsOpened(!collapsed);
    }, [collapsed]);

    const rightClass = isOpened ? 'right-0' : 'right-full';

    return (
        <Wrapper className={`transition-all duration-500 fixed left-0 top-16 bottom-0 ${rightClass} z-[999] overflow-clip`}>
            <div className={`overflow-clip h-full w-full max-w-full`}>
                <div className="border-t-2 border-solid border-slate-300 w-full" />
                <div className="flex flex-col items-start md:flex-wrap bg-sky-950 w-full min-w-96 p-4 overflow-scroll max-h-full h-full border-r border-solid border-slate-300">
                    {items.map((item) => {
                        return (
                            <div className="flex flex-col mb-3 w-full px-2 max-w-64" key={item.key}>
                                <MenuItem
                                    title={item.title}
                                    link={item.url}
                                />
                                {item?.children?.map((subItem) => {
                                    return (
                                        <SubMenuItem
                                            key={subItem.key}
                                            title={subItem.title}
                                            link={subItem.url}
                                        />
                                    );
                                })}
                            </div>
                        );
                    })}
                    <SocialLinksList color="white" className="scale-75 opacity-95" />
                </div>
            </div>
        </Wrapper>
    );
}
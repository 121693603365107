import { useEffect } from "react";

export const RequestForm = () => {
    useEffect(() => {
        const container = document.getElementById("requestProgramme");

        if (!container) {
            return;
        }

        const script = document.createElement("script");
        script.src = "https://cdn-ru.bitrix24.ru/b31529494/crm/form/loader_10.js?" + (Date.now() / 180000 | 0);
        script.async = true;
        script.dataset.b24Form = "inline/10/h4qnv1";
        script.dataset.skipMoving = "true";

        container.innerHTML = "";
        container.appendChild(script);

        return () => {
            container.removeChild(script);
        };
    }, []);

    return <div id="requestProgramme" className="rounded-3xl overflow-auto bg-white"></div>; // A container for the form
};

export default RequestForm;

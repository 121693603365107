import { FC, useEffect, useState } from "react";
import { TeamContext } from "../../../types/common";
import { LinkBlock } from "./LinkBlock";
import { getPartners, getSiteMap } from "../../../api/transport/configurationTransport";
import { PartnersGroupsDto } from "../../../types/configuration/partners";
import { PhoneOutlined } from "@ant-design/icons";
import { SiteMapDto } from "../../../types/configuration";

interface SiteMapProps {
    teamContext: TeamContext;
}

export const SiteMap: FC<SiteMapProps> = ({
    teamContext = TeamContext.RODINA
}) => {
    const [partners, setPartners] = useState<PartnersGroupsDto>({
        club: [],
        league: [],
    });

    const [siteMap, setSiteMap] = useState<SiteMapDto>([]);

    useEffect(() => {
        getPartners(teamContext).then((data) => {
            setPartners(data);
        });
        getSiteMap().then((data) => {
            setSiteMap(data[teamContext]);
        })
    }, [teamContext]);

    const isClub = (teamContext === TeamContext.RODINA || teamContext === TeamContext.RODINA_2 || teamContext === TeamContext.RODINA_3)

    const clubName = isClub ? "ФК «Родина»" : "Академия «Родина»";
    const phoneNumber = isClub ? "+7 (499) 918 00 35" : "+7 (495) 963-81-97";

    const mainBlock = (
        <div className="flex flex-col gap-2 text-white max-w-48">
            <h2 className="text-white uppercase">Адрес</h2>
            <div>
                <p className="text-gray-300">
                    107258, г. Москва <br />
                    ул. 3-я Гражданская, 47
                </p>
                <a className="text-gray-300 hover:text-gray-200 no-underline hover:no-underline flex flex-row items-center" href="tel:+74999180035">
                    <PhoneOutlined className="text-white" color="white" />
                    <span className="pl-2 hover:no-underline">{phoneNumber}</span>
                </a>
                <p className="text-gray-500">
                    Copyright © 2025 {clubName}. <br />
                    Все права защищены.
                </p>
            </div>
        </div>
    );

    const partnersBlock = (
        <LinkBlock title="Партнёры" links={[
            ...partners.club?.map((partner) => ({
                title: partner.shortName,
                url: partner.url,
            })),
            ...partners.league?.map((partner) => ({
                title: partner.shortName,
                url: partner.url,
            })),
        ]} />
    );

    return (
        <div className="hidden sm:flex flex-row justify-center gap-8 flex-wrap" style={{ fontFamily: 'Futura, sans-serif' }}>
            {mainBlock}
            {siteMap.map((block) => (
                <LinkBlock
                    key={block.title}
                    title={block.title}
                    links={block.children}
                    headLink={block.url}
                />
            ))}
            {partnersBlock}
        </div>
    );
}
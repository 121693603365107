import { useEffect, useState } from "react";
import { Pagination } from "../../../components/Pagination";
import { getSmiNewsLinks } from "../../../api/transport/newsTransport";
import { Loader } from "../../../components/Loader";
import { SmiNewsLinkDto } from "../../../types/news";
import { getHashParam, getStaticUrl, updateHashParams } from "../../../utils";
import { SmiLinkCard } from "./SmiLinkCard";

const SmiNewsPage: React.FC = () => {
    const [newsLinks, setNewsLinks] = useState<SmiNewsLinkDto[]>([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    useEffect(() => {
        const page = getHashParam('page');
        if (page) {
            setCurrentPage(+page);
        }
        setLoading(true);
        getSmiNewsLinks().then((data) => {
            setNewsLinks(data);
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    if (loading) {
        return <Loader />;
    }

    const perPage = 9;

    const totalPages = Math.ceil(newsLinks.length / perPage);
    if (currentPage > totalPages) {
        setCurrentPage(totalPages);
    }

    const links = newsLinks?.filter((l, i) => {
        return i >= (currentPage - 1) * perPage && i < currentPage * perPage;
    });

    const onPageChange = (page: number) => {
        setCurrentPage(page);
        updateHashParams({ page: page.toString() });
    }

    return (
        <div className="px-9 py-4">
            <div className="flex flex-row justify-start flex-wrap gap-8">
                {links.map((link) => <SmiLinkCard key={link.uuid} url={link.linkUrl} imageUrl={getStaticUrl(link.imageUrl)} title={link.name} />)}
            </div>
            <Pagination
                total={newsLinks.length}
                perPage={perPage}
                currentPage={currentPage}
                onChange={onPageChange}
            />
        </div>
    );
}

export default SmiNewsPage;
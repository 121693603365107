import { useEffect, useState } from "react";
import { getSummaryTable, getSummaryTableByTournament } from "../../../api/transport/statsTransport";
//import { Loader } from "../../../components/Loader";
import { TournamentTableMini } from "../../../components/TournamentTable/TableMini";
import { SummaryTableDto } from "../../../types/stats/summaryTables";

interface SummaryTableMiniWidgetProps {
    summaryTableId?: number;
    tournamentLegacyId?: number;
}

export const SummaryTableMiniWidget: React.FC<SummaryTableMiniWidgetProps> = ({
    summaryTableId,
    tournamentLegacyId,
}) => {
    const [summaryTable, setSummaryTable] = useState<SummaryTableDto>();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (tournamentLegacyId) {
            getSummaryTableByTournament(tournamentLegacyId).then(data => {
                if (!data) {
                    return Promise.reject();
                }

                setSummaryTable(data);
            }).finally(() => {
                setLoading(false);
            })
        } else if (summaryTableId) {
            getSummaryTable(summaryTableId).then((data) => {
                if (!data) {
                    return Promise.reject();
                }

                setSummaryTable(data);
            }).finally(() => {
                setLoading(false)
            });
        } else {
            setLoading(false);
        }
    }, [summaryTableId, tournamentLegacyId]);

    if (loading) {
        return null;
    }

    if (!summaryTable?.tableData?.length) {
        return null;
    }

    return (
        <div className="transiton-all rounded cursor-pointer hover:shadow-md p-2">
            <a href={`/tables/?shows=${summaryTable.id}`} title={"Перейти к " + summaryTable.name} className="text-black hover:no-underline">
                <TournamentTableMini
                    data={summaryTable?.tableData || []}
                    columns={['points']}
                    title="Общий зачёт"
                    noLogos
                />
            </a>
        </div>
    );
}

import styled from "styled-components";
import { FCRodinaButton } from "../../Button";

export const Wrapper = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    display: flex;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1000;
`;

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;

    @media (max-width: 768px) {
        padding: 0;
        overflow: hidden;
        max-height: 100vh;
    }
`;

export const Popup = styled.div`
    font-family: 'Roboto', sans-serif;
    color: #133D5E;
    background-color: #133D5E;
    margin: auto;
    border-radius: 8px;
    box-shadow: 0 0 4px 0 rgba(19, 61, 94, 0.2);
    max-width: 350px;
    //width: 350px;
    max-height: 90vh;
    height: 90vh;
    position: relative;
    overflow: hidden;
    transition: all 0.3s;

    &:hover {
        box-shadow: 0 0 4px 0 rgba(255,255,255,0.1);
    }

    @media (max-width: 500px) {
        width: 100%;
        max-width: 100%;
        border-radius: 0;
        height: 100%;
        overflow: hidden;
        max-height: 100vh;
    }
`;

export const BgImage = styled.img`
    height: 100%;
    object-fit: cover;
    object-position: top center;
    width: 100%;
`;

export const CloseButton = styled.button`
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 24px;
    color: #fff;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    opacity: 0.7;
    transition: all 0.3s;

    &:hover {
        box-shadow: 0 0 10px 0 rgba(19, 61, 94, 0.2) inset;
        inset;
        opacity: 1;
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    bottom: 16px;
    left: 16px;
    right: 16px;
`;

export const SubmitButton = styled(FCRodinaButton)`
    margin-top: 16px;
    min-height: 50px;
    background-color: white;
    color: #021026;
    text-transform: uppercase;
    transition: all 0.3s;
    border: 2px solid transparent;
    font-family: 'Bebas Neue', sans-serif;
    font-size: 36px;
    font-weight: 500;
    paddint-top: 4px;

    &:hover {
        background-color: #021026;
        color: #fff;
        border: 2px solid #fff;
        box-shadow: 0 0 10px 0 rgba(255,255,255,0.2);
    }

    @media (max-width: 500px) {
        min-width: 100%;
        margin: 0;
    }
`;

export const SubscribeButton = styled(FCRodinaButton)`
    text-transform: uppercase;
    background-color: transparent;
    font-size: 14px;
    margin: 4px auto;
`

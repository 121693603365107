import { PlayersList } from "../../../components/player/PlayersList";
import { PlayerShortDto, PlayersFilterBy } from "../../../types/player";
import { TeamContext } from "../../../types/common";
import { FC, useEffect, useState } from "react";
import { getExPlayers } from "../../../api/transport/playersTransport";
import { Loader } from "../../../components/Loader";
import { PageContent } from "../../../components/layout";

interface ExPlayersPageProps {
    teamName?: TeamContext;
}

export const ExPlayersPage: FC<ExPlayersPageProps> = ({
    teamName,
}) => {
    const [loading, setLoading] = useState(true);
    const [players, setPlayers] = useState<PlayerShortDto[]>([]);

    useEffect(() => {
        getExPlayers(teamName)
            .then((data) => {
                setPlayers(data);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [teamName]);

    if (loading) {
        return <Loader />;
    }

    return (
        <PageContent>
            <PlayersList
                defaultFilter={PlayersFilterBy.Matches}
                players={players as PlayerShortDto[]}
                showTitle
            />
        </PageContent>
    );
};
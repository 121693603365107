import { Button } from "antd";
import { Header, HeaderContent, Title } from "./styled";

export const AdminHeader = () => {
    return (
        <Header>
            <HeaderContent>
                <a href="/">
                    <img
                        src="https://fcrodina.com/i/logo.png"
                        width={50}
                        height={50}
                        alt="FC Rodina"
                    />
                </a>
                <Title>
                    ФК Родина | Панель администрирования
                </Title>
            </HeaderContent>
            <div>
                <Button type="link" href="?logout">
                    Выйти
                </Button>
            </div>
        </Header>
    );
}
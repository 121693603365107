import { CosmosPromoPage } from "../cosmos/cosmosPromo/page";
import { CosmosPromoLoginPage } from "../cosmos/cosmosPromoLogin";
import { CosmosPromoUploaderPage } from "../cosmos/cosmosPromoUploader/page";

export const CosmosPromoPageApp = () => {
    return (
        <CosmosPromoPage />
    );
}

export const CosmosPromoUploaderPageApp = () => {
    return (
        <CosmosPromoUploaderPage />
    );
}

export const CosmosPromoLoginPageApp = () => {
    return (
        <CosmosPromoLoginPage />
    );
}
import { MatchListDto, MatchStatus } from "../../types/stats";
import { MatchResultContainer, MatchResultMarker, MatchTypeIcon, ScoreLink, TableMiniContainer } from "./styled";
import { FCRodinaTable } from "../Table";
import { TableTeamTitle } from "../Table/TeamTitle";
import { getMatchResultColor } from "./utils";
import { CarOutlined, HomeOutlined } from "@ant-design/icons";
import { BuyTicketButton } from "../tickets/BuyTicketButton";
import { getStaticUrl } from "../../utils";

interface MatchListMiniProps {
    data: MatchListDto;
}

export const MatchListMini: React.FC<MatchListMiniProps> = ({data}) => {
    const dataColumns = [
        { title: 'Дата', dataIndex: 'date', key: 'date' },
        { title: 'Соперник', dataIndex: 'opponentTeamName', key: 'opponentTeamName' },
        { title: 'Счет', dataIndex: 'score', key: 'score' },
    ];

    const dataItems = data.map((item) => {
        return dataColumns.map((column) => {
            let value = item[column.dataIndex];
            if (column.dataIndex === 'opponentTeamName') {
                return {
                    key: column.key,
                    value: (
                        <>
                            <TableTeamTitle logoUrl={getStaticUrl(item.opponentTeamLogo)} name={value} />
                            <MatchTypeIcon>
                                {item.isHome ? <HomeOutlined /> : <CarOutlined />}
                            </MatchTypeIcon>
                        </>
                    )
                }
            } else if (column.dataIndex === 'date') {
                if (item.preliminaryDate) {
                    return {
                        key: column.key,
                        value: 'Не определена'
                    }
                }
                return {
                    key: column.key,
                    value: new Date(value).toLocaleString('ru-RU', {
                        day: 'numeric',
                        month: 'long',
                        hour: '2-digit',
                        minute: '2-digit',
                    })
                }
            } else if (column.dataIndex === 'score') {
                if (item.status === MatchStatus.UPCOMING) {
                    if (item.tickets) {
                        value = (
                            <BuyTicketButton url={item.tickets} size="sm" className="mx-auto"/>
                        );
                    } else {
                        value = (
                            <MatchResultContainer>
                                <MatchResultMarker $color={getMatchResultColor(item.result)} />
                            </MatchResultContainer>
                        );
                    }
                } else if (item.status === MatchStatus.СANCELED) {
                    value = 'Отменён';
                } else if (item.status === MatchStatus.IN_PROGRESS) {
                    value = (
                        <MatchResultContainer>
                            <ScoreLink href={item.videoUrl} target="_blank">
                                LIVE
                            </ScoreLink>
                        </MatchResultContainer>
                    );
                } else {
                    value = (
                        <MatchResultContainer>
                            <ScoreLink href={item.protocolUrl}>
                                {item.score}

                            </ScoreLink>
                            <MatchResultMarker $color={getMatchResultColor(item.result)} />
                        </MatchResultContainer>
                    );
                }
            }
            return {
                key: column.key,
                value
            }
        });
    });

    return (
        <TableMiniContainer>
            <FCRodinaTable columns={dataColumns} data={dataItems} />
        </TableMiniContainer>
    );
}


import { ButtonContainer, ButtonContent, IconContainer } from "./styled";

interface ButtonProps {
    children: React.ReactNode;
    onClick?: () => void;
    style?: React.CSSProperties;
    className?: string;
    size?: "small" | "medium" | "large";
    disabled?: boolean;
    icon?: React.ReactNode;
}

export const Button: React.FC<ButtonProps> = ({
    children,
    onClick,
    style,
    size = "medium",
    disabled = false,
    icon,
    className,
}) => {
    return (
        <ButtonContainer
            onClick={onClick}
            style={{...style}}
            $size={size}
            disabled={disabled}
            className={className}
        >
            <ButtonContent>
                {icon && <IconContainer>{icon}</IconContainer>}
                {children}
            </ButtonContent>
        </ButtonContainer>
    );
}
import { ConfigProvider, Space } from "antd";
import { SelectorTournamenGroup, StatsTournamentSelectorsDto, TournamentDto } from "../../types/stats";
import { useCallback, useEffect, useState } from "react";
import { Select } from "./styled";
import { MAX_SEASONS } from "./consts";

const getParams = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const seasonUuid = urlSearchParams.get('season');
    const tournamentUuid = urlSearchParams.get('tournament');
    const legacySeasonId = urlSearchParams.get('sezon');
    const legacyTournamentId = urlSearchParams.get('turnir');
    return { seasonUuid, tournamentUuid, legacySeasonId, legacyTournamentId };
};

interface TournamentSelectorProps {
    selectorOptions: StatsTournamentSelectorsDto;
    additionalParams?: string;
    onSelect?: (tournamentUuid: string, seasonUuid: string, legacyTournament?: number, legacySeason?: number) => void;
}

export const TournamentSelector: React.FC<TournamentSelectorProps> = ({
    selectorOptions,
    additionalParams = '',
    onSelect,
}) => {
    const [selectedSeasonIndex, setSelectedSeasonIndex] = useState<number>(0);
    const [tournamentData, setTournamentData] = useState<TournamentDto[]>();
    const [tournamentGroups, setTournamentGroups] = useState<SelectorTournamenGroup[]>();
    const [selectedGroup, setSelectedGroup] = useState<string>();
    const [selectedTournament, setSelectedTournament] = useState<string>();
    const [showAllSeasons, setShowAllSeasons] = useState<boolean>(false);
    const [hasAllSeasons, setHasAllSeasons] = useState<boolean>(true);

    useEffect(() => {
        const tournament: TournamentDto | undefined = tournamentData?.find((tournament) => tournament.uuid === selectedTournament);
        if (onSelect) {
            const seasonId = selectorOptions?.seasons?.find(season => season.uuid === tournament?.seasonUuid)?.legacyId;
            onSelect(tournament?.uuid, tournament?.seasonUuid, tournament?.legacyId, seasonId);
        }
    }, [selectedTournament, selectorOptions?.seasons, tournamentData]);

    useEffect(() => {
        if (!selectorOptions) {
            return;
        }

        const { seasonUuid, tournamentUuid, legacySeasonId, legacyTournamentId } = getParams();

        let seasonIndex = 0;

        if (seasonUuid) {
            seasonIndex = selectorOptions?.seasons?.findIndex((season) => season.uuid === seasonUuid);
        } else if (legacySeasonId) {
            seasonIndex = selectorOptions?.seasons?.findIndex((season) => season.legacyId === +legacySeasonId);
        }

        if (seasonIndex < 0) {
            seasonIndex = 0;
        }

        setSelectedSeasonIndex(seasonIndex);

        if (seasonIndex >= MAX_SEASONS) {
            setHasAllSeasons(false);
            setShowAllSeasons(true);
        } else if (selectorOptions?.seasons?.length < MAX_SEASONS) {
            setHasAllSeasons(false);
        }

        const selectedSeason = selectorOptions.seasons[seasonIndex];

        let tournamentDataToSet: TournamentDto[] = selectedSeason?.tournaments;
        let tournamentToSet: TournamentDto;

        if (tournamentUuid) {
            tournamentToSet = tournamentDataToSet.find((tournament) => tournament.uuid === tournamentUuid);
        } else if (legacyTournamentId) {
            tournamentToSet = tournamentDataToSet.find((tournament) => tournament.legacyId === +legacyTournamentId);
        } else {
            tournamentToSet = tournamentDataToSet[0];
        }

        if (tournamentToSet?.tournamentCategoryUuid) {
            setTournamentGroups(selectedSeason.groups);
            setSelectedGroup(tournamentToSet.tournamentCategoryUuid);
            tournamentDataToSet = tournamentDataToSet.filter(
                (t) => t.tournamentCategoryUuid === tournamentToSet.tournamentCategoryUuid
            );
        }

        if (selectorOptions?.seasons?.length < MAX_SEASONS) {
            setHasAllSeasons(false);
        }

        setTournamentData(tournamentDataToSet);

        setSelectedTournament(tournamentToSet.uuid);
    }, [selectorOptions]);

    const onSelectSeason = (value: string) => {
        const selectedIndex = selectorOptions?.seasons?.findIndex((season) => season.uuid === value);
        const selecteSeason = selectorOptions?.seasons?.[selectedIndex];
        const tournament = selecteSeason?.tournaments[0];

        if (tournament?.url) {
            window.open(tournament.url + additionalParams, '_self');
            //window.history.pushState({}, '', tournament.url + additionalParams);
            return;
        }

        setSelectedSeasonIndex(selectedIndex);
        setTournamentData(selecteSeason?.tournaments);
        setSelectedTournament(tournament?.uuid);
        setTournamentGroups(selecteSeason?.groups);
        setSelectedGroup(tournament?.tournamentCategoryUuid || selecteSeason?.groups?.[0]?.uuid);
    }

    const onSelectTournament = useCallback((value: string) => {
        const tournament = tournamentData.find((tournament) => tournament.uuid === value);
        if (tournament?.url) {
            window.open(tournament.url + additionalParams, '_self');
            return;
        }
        setSelectedGroup(value);
    }, [additionalParams, tournamentData]);

    const onSelectGroup = useCallback((value: string) => {
        if (value) {
            const tData = selectorOptions?.seasons?.[selectedSeasonIndex]?.tournaments;
            const newData = tData?.filter((t) => t.tournamentCategoryUuid === value);
            const tournament = newData?.[0];
            if (tournament?.url) {
                window.open(tournament.url + additionalParams, '_self');
                return;
            }
            setSelectedGroup(value);
            setTournamentData(newData);
            setSelectedGroup(newData[0]?.uuid);
        }
    }, [additionalParams, selectedSeasonIndex, selectorOptions?.seasons]);

    if (!selectorOptions) {
        return null;
    }

    if (!tournamentData) {
        return null;
    }

    let seasonsOptions;

    if (showAllSeasons || !hasAllSeasons) {
        seasonsOptions = selectorOptions.seasons.map((season) => ({ label: 'Сезон ' + season.name, value: season.uuid }));
    } else {
        seasonsOptions = selectorOptions.seasons.slice(0, 3).map((season) => ({ label: 'Сезон ' + season.name, value: season.uuid }));
        const allSeasonsButton = (
            <div onClick={
                (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setShowAllSeasons(true);
                    return false;
                }}
            >
                Ещё
            </div>
        );
        seasonsOptions.push({ label: allSeasonsButton, value: 'all' });
    }

    return (
        <ConfigProvider
            theme={{
                components: {
                    Select: {
                        selectorBg: '#E8F4FF',
                        borderRadius: 2,
                    },
                },
            }}
        >
            <Space wrap>
                <Select
                    popupMatchSelectWidth={false}
                    size="large"
                    value={tournamentData[0]?.seasonUuid}
                    onChange={onSelectSeason}
                    options={seasonsOptions}
                    onDropdownVisibleChange={(open) => {
                        if (!open) {
                            setShowAllSeasons(!hasAllSeasons);
                        }
                    }}
                />
                {tournamentGroups?.length && <Select
                    popupMatchSelectWidth={false}
                    size="large"
                    value={selectedGroup}
                    onChange={onSelectGroup}
                    options={tournamentGroups?.map((group) => ({ label: group.name, value: group.uuid }))}
                />}
                <Select
                    popupMatchSelectWidth={false}
                    size="large"
                    value={selectedTournament}
                    onChange={onSelectTournament}
                    options={tournamentData.map((tournament) => ({ label: tournament.name, value: tournament.uuid }))}
                />
            </Space>
        </ConfigProvider>
    );
};
import { FC, useEffect, useState } from "react";
import { VacancyListItem } from "./VacancyListItem";
import { Empty } from "antd";
import { VacancyShortDto } from "../../types/jobs";
import { getVacancies } from "../../api/transport/jobsTransport";
import { Loader } from "../../components/Loader";
import { useTeamContext } from "../../hooks/useConfiguration";
import { getUrlByContext } from "../../utils";

interface VacanciesListProps { }

export const VacanciesList: FC<VacanciesListProps> = () => {
    const [vacancies, setVacancies] = useState<VacancyShortDto[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    useEffect(() => {
        setLoading(true);
        getVacancies()
            .then((data) => {
                setVacancies(data);
            }).finally(() => {
                setLoading(false);
            });
    }, []);

    const teamContext = useTeamContext();

    if (loading) {
        return <Loader />;
    }

    return (
        <>
            {!!vacancies?.length && <h1 className="pl-8 text-4xl">Наши вакансии</h1>}
            <div className="flex flex-col justify-start items-center gap-3 sm:gap-6 min-h-96 p-8 pt-4">
                {vacancies.map((vacancy) => (
                    <VacancyListItem
                        key={vacancy.uuid}
                        title={vacancy.title}
                        url={getUrlByContext(`/jobs/${vacancy.uuid}/`, teamContext)}
                        description={vacancy.shortDescription}
                    />
                ))}
                {
                    vacancies.length === 0 && <Empty
                        description="У нас пока нет открытых вакансий, попробуйте заглянуть позже"
                    />
                }
            </div>
        </>
    );
}
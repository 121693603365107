import { ActivitiesContainer, Container } from "./styled";
import { MatchdayActivitiesWidget } from "../../../widgets/my/matchday/MatchdayActivities";
import { TicketBannerWidget } from "../../../widgets/tickets/Banner";
import { PageHeadMedia } from "../../../components/layout";
import { useEffect, useState } from "react";
import { getMatchdayConfig } from "../../../api/transport/matchdayTransport";
import { MatchdayConfig } from "../../../types/matchday";

const MatchdayPage = () => {
    const [config, setConfig] = useState<MatchdayConfig>({});
    useEffect(() => {
        getMatchdayConfig().then((data) => {
            setConfig(data);
        });
    }, []);
    return (
        <Container>
            <PageHeadMedia mediaUrl={config?.pageHeaderUrl} />
            <ActivitiesContainer>
                <MatchdayActivitiesWidget />
            </ActivitiesContainer>
            <TicketBannerWidget buyButton />
        </Container>
    );
};

export default MatchdayPage;
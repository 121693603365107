import { useEffect, useState } from "react";
import { FCRodinaButton } from "../../../components/Button"
import { Loader } from "../../../components/Loader";

export const SocialFeedPage = () => {
    const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://apps.elfsight.com/p/platform.js";
        script.defer = true;
        document.body.appendChild(script);
        script.onload = () => {
            setTimeout(() => setIsLoaded(true), 2000);
        };
        return () => {
            document.body.removeChild(script);
        }
    }, []);

    return (
        <div className="relative py-8 min-h-[500px]">
            {!isLoaded && <Loader popover />}
            <div className="relative w-fit m-auto">
                <div className="elfsight-app-fd7b251a-d0d7-4b70-9626-bf66a43f329c"></div>
                {isLoaded && <FCRodinaButton onClick={() => window.location.reload()} className="absolute top-0 right-0 z-10">
                    Обновить
                </FCRodinaButton>}
            </div>
        </div>
    )
};

export default SocialFeedPage;

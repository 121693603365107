import { FC, useEffect, useState } from "react";
import { getContestsList } from "../../../../api/transport/contestsTransport";
import { TimeLineAccordion, TimelineAccordionItem } from "../../../../components/TimelineAccordion";
import { EditOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { ExtraContainer, Icon } from "./styled";
import { ContestDto } from "../../../../types/contests";
import { Loader } from "../../../../components/Loader";
import { CitiesCarousel } from "./cities/CitiesCarousel";
import { CitiesButtons } from "./cities/CitiesButtons";

interface ContestsListWidgetProps {
    edit?: {
        onEdit: (id: string) => void;
    }
}

export const ContestsListWidget: FC<ContestsListWidgetProps> = ({
    edit
}) => {
    const [contests, setContests] = useState<TimelineAccordionItem[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        getContestsList().then((data: ContestDto[]) => {
            const items: TimelineAccordionItem[] = [];

            data.forEach((item) => {
                if (!edit && !item.active) {
                    return;
                }

                let descriptionExtra = null;

                //TODO: подумать, как это сделать через настройку в админке, может быть через дополнительные поля в конкурсе
                if (item.name === 'Города Родины') {
                    descriptionExtra = (
                        <>
                            <CitiesButtons />
                            <CitiesCarousel />
                        </>
                    );
                }

                const description =
                    (<>
                        <div dangerouslySetInnerHTML={{ __html: item.description }} />
                        {descriptionExtra}
                    </>);

                const res: TimelineAccordionItem = {
                    title: item.name + (item.active ? '' : ' (неактивен)'),
                    shortDescription: item.shortDescription,
                    description: description,
                    imageUrl: item.imageUrl,
                    icon: <Icon src={item.iconUrl} alt={item.name} />,
                }
                if (edit?.onEdit) {
                    res.extra = (
                        <ExtraContainer>
                            <Button onClick={() => edit.onEdit(item.id.toString())} icon={<EditOutlined />}>Редактировать</Button>
                        </ExtraContainer>
                    );
                }
                items.push(res);
            });

            setContests(items);
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        });
    }, []);

    if (loading) {
        return <Loader />;
    }

    if (!contests?.length) {
        return null;
    }

    return (
        <TimeLineAccordion items={contests} activeIndex={contests?.length === 1 ? 0 : null} />
    );
}

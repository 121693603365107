import { FC, useEffect, useState } from "react";
import { NewsCard } from "../../../components/news/NewsCard";
import { NewsList } from "../../../components/news/NewsList";
import { getPlayerNews } from "../../../api";
import { extractTextFromHtml } from "../../../utils";
import { Loader } from "../../../components/Loader";

interface PlayerNewsWidgetProps {
    playerId: string;
}

export const PlayerNewsWidget: FC<PlayerNewsWidgetProps> = ({ playerId }) => {
    const [playerNews, setPlayerNews] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (!playerId) {
            return;
        }

        setLoading(true);
        getPlayerNews({ playerId }).then((news) => {
            setPlayerNews(news);
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        });
    }, [playerId]);

    if (!playerNews?.length && !loading) {
        return null;
    }

    if (loading) {
        return <Loader />;
    }

    return (
        <NewsList title="МАТЕРИАЛЫ">
            {playerNews?.map((news) => {
                const content = extractTextFromHtml(news.description).split('.')[0];
                return (
                    <NewsCard
                        key={news.id}
                        title={news.title}
                        content={content}
                        cover={news.image}
                        date={news.date}
                        category={news.category}
                        url={news.url}
                    />
                )
            })}
        </NewsList>
    );
}
import { useState, useEffect, useRef, RefObject } from 'react';

const useElementHeight = (): [RefObject<HTMLDivElement>, number] => {
    const [height, setHeight] = useState<number>(0);
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const element = ref.current;
        if (element) {
            const observer = new ResizeObserver((entries) => {
                const newHeight = entries[0].contentBoxSize[0].blockSize;
                setHeight(newHeight);
            });

            observer.observe(element);

            return () => {
                observer.disconnect();
            };
        }
    }, []);

    return [ref, height];
};

export default useElementHeight;

import { Collapse, Flex } from "antd";
import { FC, useEffect, useState } from "react";
//import { UpOutlined } from "@ant-design/icons";
import { CollapseTitle } from "./styled";

interface CollapsePanelProps {
    children: React.ReactNode;
    opened?: boolean;
}

export const CollapsePanel: FC<CollapsePanelProps> = ({ children, opened }) => {
    const [collapseActiveKey, setCollapseActiveKey] = useState<string | string[]>([]);

    const [isOpened, setIsOpened] = useState(opened);

    useEffect(() => {
        setIsOpened(!!opened);
    }, [opened]);

    useEffect(() => {
        if (isOpened) {
            setCollapseActiveKey(['main']);
        } else {
            setCollapseActiveKey([]);
        }
    }, [isOpened]);

    return (
        <>
            <div className="cursor-pointer text-center transition-all hover:scale-105" onClick={() => setIsOpened(!isOpened)}>
                <CollapseTitle>
                    {isOpened ? 'Показать меньше' : 'Все отзывы'}
                </CollapseTitle>
            </div>
            <Collapse
                collapsible="header"
                defaultActiveKey={['main']}
                size="large"
                expandIconPosition="right"
                expandIcon={() => null}
                className="w-full"
                ghost
                activeKey={collapseActiveKey}
                //onChange={(activeKey) => setCollapseActiveKey(activeKey)}
                items={[
                    {
                        key: 'main',
                        label: '',//<CollapseTitle>{title}</CollapseTitle>,
                        children: (
                            <>
                                {children}
                                <Flex justify="center" gap="small" className="cursor-pointer" onClick={() => setIsOpened(false)}>
                                    <CollapseTitle>{/*<UpOutlined size={16}/> */}Показать меньше</CollapseTitle>
                                </Flex>
                            </>
                        )
                    },
                ]}
            />
        </>
    );
}
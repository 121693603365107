import { FC } from "react";
import { PlayersFilterBy } from "../../../types/player";
import { Filter } from "./styled";
import { RadioGroup } from "../../RadioGroup";

export const FILTER_VALUES = [
    {
        value: PlayersFilterBy.Position,
        label: 'Амплуа'
    },
    {
        value: PlayersFilterBy.Matches,
        label: 'Матчи'
    },
    {
        value: PlayersFilterBy.Goals,
        label: 'Голы'
    },
];

interface SortRadioProps {
    value: PlayersFilterBy;
    defaultValue?: PlayersFilterBy;
    onChange: (value: PlayersFilterBy) => void;
}

export const SortRadio: FC<SortRadioProps> = ({ value, defaultValue, onChange }) => {
    return (
        <Filter>
            Сортировать по:
            <RadioGroup
                options={FILTER_VALUES.map(({ value, label }) => ({ key: value, label }))}
                value={value}
                onChange={onChange}
                defaultValue={defaultValue || FILTER_VALUES[0].value}
                size="middle"
            />
        </Filter>
    );
}
import { FC } from "react";
import {
    HR,
    InfoParameterContainer,
    InfoParameterName,
    InfoParameterValue
} from "./styled";

interface InfoParameterProps {
    name: string;
    value: string;
    link?: string;
}

export const InfoParameter: FC<InfoParameterProps> = ({ name, value, link }) => {
    return (
        <>
            <InfoParameterContainer>
                <InfoParameterName>
                    {name}
                </InfoParameterName>
                <InfoParameterValue
                    onClick={() => link && window.open(link, '_self')}
                    $isLink={!!link}
                >
                    {value}
                </InfoParameterValue>
            </InfoParameterContainer>
            <HR width="80px" />
        </>
    );
}
import { Button } from "antd";
import { FC } from "react";
import { ContestsListWidget } from "../../widgets/my/contests/ContestsList";
import { Header, Title } from "../timelineEditor/styled";
import { PlusOutlined } from "@ant-design/icons";

interface ContestsListProps {
    onAddClick?: () => void;
    onEditClick?: (id: string) => void;
}

export const ContestsList: FC<ContestsListProps> = (
    {
        onAddClick = () => window.location.href = '/admin/?contests&edit',
        onEditClick = (id: string) => window.location.href = `/admin/?contests&edit=${id}`,
    }
) => {
    return (
        <div>
            <Header>
                <Button
                    type="primary"
                    onClick={onAddClick}
                    icon={<PlusOutlined />}
                >
                    Добавить
                </Button>
                <Title>Конкурсы</Title>
            </Header>
            <ContestsListWidget edit={{
                onEdit: onEditClick,
            }} />
        </div>
    );
}
import { PageContent } from "../../../components/layout";
import { useEffect, useRef } from "react";
import { setBgImage } from "../../../utils";
import { motion, useInView } from "framer-motion";

import styled from "styled-components";
import bg from '../../../assets/my/app-page-bg.png';
import { RequestForm } from "./RequestForm";
import { Button } from "../../../components/ui-kit/RoundButton";

const Wrapper = styled.div`
    font-family: 'Futura', sans-serif;
`;

const MatchProgrammePage = () => {
    useEffect(() => {
        setBgImage(bg);
    }, []);

    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    return (
        <Wrapper id="firstScreen">
            <PageContent className="text-xl">
                <div className="min-h-96 max-h-screen flex flex-col items-center justify-center gap-8 pt-8 max-w-4xl mx-auto text-center">
                    <motion.div
                        initial={{ opacity: 0, y: -50 }} // Начальное состояние (невидимо и смещено вниз)
                        animate={{ opacity: 1, y: 0 }}  // Конечное состояние (видимо и на месте)
                        transition={{ duration: 0.5 }}  // Длительность анимации
                        className="text-7xl font-bold italic text-white"
                    >
                        Получи программку в электронной версии
                    </motion.div>
                </div>
                <div className="max-w-3xl mx-auto text-white text-2xl text-center pb-16">
                    <motion.div
                        ref={ref}
                        initial={{ opacity: 0.3 }}
                        animate={isInView ? { opacity: 1 } : { opacity: 0 }}
                        transition={{ duration: 0.5, delay: 0.2 }}
                        className="flex flex-col items-center"
                    >
                        <div>
                            <RequestForm />
                        </div>

                        <p>
                            За печатным изданием сюда:
                        </p>
                        <Button title="Магазин" href="https://shop.fcrodina.com/#rec774999628" />
                    </motion.div>
                </div>
            </PageContent>
        </Wrapper>
    )
}

export default MatchProgrammePage;
import { MainLayout } from "../layout";
import { TeamHeader } from "../layout/desktop/TeamHeader";
import { Top } from "../layout/desktop/Top";
import { useConfiguration } from "../hooks/useConfiguration";
import { LegacyDesktopHeader } from "../widgets/header";
import { Configuration } from "../types/common";
import { PartnersWidget } from "../widgets/footer/Partners";
import { LegacyDesktopFooter } from "../widgets/footer";

export const MobileViewApp = () => {
    return <MainLayout />;
}

export const TopApp = () => {
    return <Top />
}

export const TeamHeaderApp = () => {
    return (
        <TeamHeader />
    );
}

export const PartnersApp = () => {
    const config: Configuration = useConfiguration();
    return (
        <PartnersWidget teamName={config.teamName} />
    );
}

export const LegacyDesktopHeaderApp = () => {
    return <LegacyDesktopHeader />;
}

export const LegacyDesktopFooterApp = () => {
    return <LegacyDesktopFooter />;
}

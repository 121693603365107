import styled from "styled-components";

export const TableMiniContainer = styled.div`
    width: 100%;
    @media (min-width: 768px) {
        max-width: 440px;
    }
`;

export const Title = styled.div`
    font-size: 20px;
    font-weight: 500;
    margin: 16px 0;
    width: 100%;
    color: #133D5E;
    text-align: left;

    @media (min-width: 500px) {
        text-align: center;
    }
`;

export const ExtraContainer = styled.div`
    margin-top: 20px;
    width: 100%;
`;
import { FC } from "react";
import { TeamContext } from "../../../types/common";
import { useConfiguration } from "../../../hooks/useConfiguration";
import { Header } from "../../../components/layout";
import { getStaticUrl } from "../../../utils";

export const TeamHeader: FC = () => {
    const config = useConfiguration();

    let teamTitle = "ФУТБОЛЬНЫЙ КЛУБ «РОДИНА»";
    let linkUrl = "/";
    if (config.teamName === TeamContext.ACADEMY) {
        teamTitle = "АКАДЕМИЯ «РОДИНА»";
    } else if (config.teamName === TeamContext.RODINA_2) {
        teamTitle = "ФУТБОЛЬНЫЙ КЛУБ «РОДИНА-2»";
        linkUrl = "/2/";
    } else if (config.teamName === TeamContext.RODINA_3) {
        teamTitle = "ФУТБОЛЬНЫЙ КЛУБ «РОДИНА-М»";
        linkUrl = "/3/";
    } else if (config.teamName === TeamContext.RODINA_M) {
        teamTitle = "ФУТБОЛЬНЫЙ КЛУБ «РОДИНА» Молодёжка";
        linkUrl = "/youth/";
    } else if (config.teamName === TeamContext.RODINA) {
        teamTitle = "ФУТБОЛЬНЫЙ КЛУБ «РОДИНА»";
        linkUrl = '/rodina/';
    }

    let timeline = null;

    if (config.teamName === TeamContext.RODINA) {
        timeline = (
            <div className="relative">
                <a href="/club/?club2015#club2015" className="absolute top-0 left-0 w-16 h-16"></a>
                <a href="/club/?club2016#club2016" className="absolute top-0 left-[90px] w-16 h-16"></a>
                <a href="/club/?club2019#club2019" className="absolute top-0 left-[210px] w-16 h-16"></a>
                <a href="/club/?club2021#club2021" className="absolute top-0 left-[360px] w-16 h-16"></a>
                <a href="/club/?club2022#club2022" className="absolute top-0 left-[445px] w-16 h-16"></a>
                <img src={getStaticUrl("/static/images/timeline/timeline.png")} alt="Timeline" className="h-[67px]" />
            </div>
        );
    }

    return (
        <Header
            clubTitle={teamTitle}
            timeline={timeline}
            onLogoClick={() => window.location.assign(linkUrl)}/>
    );
}
import { FC } from "react";
import {
    Content,
    ContentItem,
    ContentItemTitle,
    LinksContainer,
    MainScreenStatsContainer,
    StatLink
} from "./styled";
import { MainScreenFilter, TeamContext } from "../../../types/common";
import { MatchListMiniWidget } from "../../stats/match-list/MatchListMiniWidget";
import { TournamentTableMiniWidget } from "../../stats/tournament-table/TournamentTableMiniWidget";
import { getUrlByContext } from "../../../utils";
import { Tabs } from "antd";
import type { TabsProps } from 'antd';

interface MainScreenStatsWigdetProps {
    statsFilters?: MainScreenFilter[];
    topTeams?: number;
    bottomTeams?: number;
    prevMatches?: number;
    teamName?: TeamContext;
}

export const MainScreenStatsWigdet: FC<MainScreenStatsWigdetProps> = (
    { topTeams, bottomTeams, prevMatches, teamName, statsFilters }
) => {
    const statItems = statsFilters.map((filter, index) => {
        const { tournamentId, seasonId, teamId } = filter;
        const searchParams = new URLSearchParams();
        searchParams.set('turnir', tournamentId.toString());
        searchParams.set('sezon', seasonId.toString());
        return (
            <MainScreenStatsContainer key={`${tournamentId}-${seasonId}-${teamId}`}>
                <Content>
                    <ContentItem>
                        <ContentItemTitle>Турнирная таблица</ContentItemTitle>
                        <TournamentTableMiniWidget
                            tournamentId={tournamentId}
                            seasonId={seasonId}
                            teamId={teamId}
                            topTeams={topTeams}
                            bottomTeams={bottomTeams}
                            teamName={teamName}
                        />
                    </ContentItem>
                    <ContentItem>
                        <ContentItemTitle>Матчи</ContentItemTitle>
                        <MatchListMiniWidget
                            tournamentId={tournamentId}
                            seasonId={seasonId}
                            teamId={teamId}
                            prevMatches={prevMatches}
                            totalMatches={topTeams + bottomTeams + 1}
                            teamName={teamName}
                        />
                    </ContentItem>
                </Content>
                <LinksContainer>
                    <StatLink href={getUrlByContext(`/games/tables/?${searchParams.toString()}`, teamName)}>Полная таблица</StatLink>
                    <StatLink href={getUrlByContext(`/games/stat/?${searchParams.toString()}`, teamName)}>Бомбардиры</StatLink>
                    <StatLink href={getUrlByContext(`/games/?${searchParams.toString()}`, teamName)}>Матчи</StatLink>
                </LinksContainer>
            </MainScreenStatsContainer>
        );
    });

    if (statItems.length === 0) {
        return null;
    }

    if (statItems.length === 1) {
        return statItems[0];
    }

    const items: TabsProps['items'] = statItems.map((item, index) => {
        return {
            key: index.toString(),
            label: statsFilters?.[index]?.title || `Турнир ${index + 1}`,
            children: item
        };
    });

    return (
        <div className="px-3">
            <Tabs defaultActiveKey={`${items.length - 1}`} items={items} />
        </div>
    );
}
import { useEffect, useState } from "react";
import { MatchListDto } from "../../../types/stats";
import { getMatchList } from "../../../api/transport/statsTransport";
import { TeamContext } from "../../../types/common";
import { MatchListTable } from "../../../components/MatchListTable";
import { Loader } from "../../../components/Loader";

interface MatchListTableProps {
    tournamentId: number;
    seasonId: number;
    teamId?: number;
    teamName?: TeamContext;
}

export const MatchListWidget: React.FC<MatchListTableProps> = ({ tournamentId, seasonId, teamId, teamName }) => {
    const [mathcList, setMatchList] = useState<MatchListDto>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getMatchList(tournamentId, seasonId, teamId, teamName).then((data) => {
            setMatchList(data);
            setLoading(false);
            setTimeout(() => {
                const scroll = document.querySelector('#scrollTo');
                scroll?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                });
            }, 500);
        }).finally(() => {
            setLoading(false);
        });
    }, [seasonId, tournamentId, teamId, teamName]);

    if (loading) {
        return <Loader />;
    }

    return (
        <MatchListTable data={mathcList}/>
    );
}


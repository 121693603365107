import { useCallback, useState } from "react";
import { ButtonsContainer, Container, Content, Hr, Button } from "./styled";
import { UpOutlined, DownOutlined } from "@ant-design/icons";

interface CollapseProps {
    children: React.ReactNode;
    height?: number;
    onStateChange?: (open: boolean) => void;
    moreText?: string;
    lessText?: string;
}

export const Collapse: React.FC<CollapseProps> = ({
    children,
    height,
    onStateChange,
    moreText = "Читать больше",
    lessText = "Свернуть"
}) => {
    const [open, setOpen] = useState(false);

    const toggleContainer = useCallback(() => {
        onStateChange && onStateChange(!open);
        setOpen(!open);
    }, [open, onStateChange]);

    return (
        <Container>
            <Content $open={open} $height={height}>
                {children}
            </Content>
            <ButtonsContainer>
                {!open && <Button size="large" type="link" onClick={toggleContainer}>{moreText}<DownOutlined /></Button>}
                {open && <Button size="large" type="link" onClick={toggleContainer}>{lessText}<UpOutlined /></Button>}
            </ButtonsContainer>
            <Hr />
        </Container>
    );
}
import { FC, useEffect, useState } from "react";
import { PlayerMatchListDto } from "../../../types/stats";
import { getPlayerMatchStatsById } from "../../../api/transport/statsTransport";
import { TeamContext } from "../../../types/common";
import { PlayerMatchListTable } from "../../../components/MatchListTable/PlayerMatchList";
import { PlayerDto, StatParams } from "../../../types/player";
import { Container, Header, Title } from "./styled";
import { StatParamsRadioGroup } from "./StatParamsRadioGroup";
import { Loader } from "../../../components/Loader";
import { getHashParam, updateHashParams } from "../../../utils";

interface PlayerMatchesWidgetProps {
    teamContext?: TeamContext;
    tournamentUuid?: string;
    seasonUuid?: string;
    teamId?: number;
    playerId: string;
    playerData?: PlayerDto;
    filters?: StatParams[];
    defaultFilter?: StatParams;
}

export const PlayerMatchesWidget: FC<PlayerMatchesWidgetProps> = ({
    playerId,
    playerData,
    teamContext,
    tournamentUuid,
    teamId,
    seasonUuid,
    filters = [],
    defaultFilter = StatParams.MATCHES,
}) => {
    const [playerMatchStats, setPlayerMatchStats] = useState<PlayerMatchListDto>([]);
    const hashFilter= getHashParam('filter') as StatParams | null;
    const [selectedFilter, setSelectedFilter] = useState<StatParams>(hashFilter || defaultFilter);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!playerId || !playerData) {
            return;
        }

        if (filters?.length) {
            if (!filters.includes(selectedFilter)) {
                setSelectedFilter(filters[0]);
                return;
            }
        }

        setLoading(true);

        getPlayerMatchStatsById(playerId, tournamentUuid, seasonUuid, teamId, teamContext, selectedFilter).then(
            (data) => {
                setPlayerMatchStats(data.sort((a, b) => new Date(a.date) > new Date(b.date) ? -1 : 1));
            }
        ).finally(() => {
            setLoading(false);
        });
    }, [filters, playerData, playerId, seasonUuid, selectedFilter, teamContext, teamId, tournamentUuid]);

    if (!filters?.length && !playerMatchStats?.length) {
        return null;
    }

    const additionalColumns = [];
    let title = 'Сухие матчи:';

    if (selectedFilter === StatParams.GOALS) {
        additionalColumns.push({
            title: 'Голы',
            dataIndex: 'goals',
            key: 'goals',
        });
        title = 'Забивал в матчах:';
    } else if (selectedFilter === StatParams.ASSISTS) {
        additionalColumns.push({
            title: 'Ассисты',
            dataIndex: 'assists',
            key: 'assists',
        });
        title = 'Ассистировал в матчах:';
    } else if (selectedFilter === StatParams.YELLOW_CARDS) {
        additionalColumns.push({
            title: 'ЖК',
            dataIndex: 'yellowCards',
            key: 'yellowCards',
        });
        title = 'Получал желтые карточки:';
    } else if (selectedFilter === StatParams.RED_CARDS) {
        title = 'Получал красные карточки:';
    } else if (selectedFilter === StatParams.MATCHES || selectedFilter === StatParams.MINUTES_PLAYED) {
        additionalColumns.push({
            title: 'Минуты',
            dataIndex: 'minutesPlayed',
            key: 'minutesPlayed',
        });
        title = 'Выходил на поле в матчах:';
    } else if (selectedFilter === StatParams.PENALTIES_SAVED) {
        additionalColumns.push({
            title: 'Отбитые пенальти',
            dataIndex: 'penaltiesSaved',
            key: 'penaltiesSaved',
        });
        title = 'Отбивал пенальти:';
    }

    return (
        <Container>
            {filters?.length <= 1 && <Title>
                {title}
            </Title>}
            {filters?.length > 1 && (
                <Header>
                    <Title>
                        Матчи
                    </Title>
                    <StatParamsRadioGroup
                        value={selectedFilter}
                        onChange={(value: StatParams) => {
                            setSelectedFilter(value);
                            updateHashParams({ filter: value });
                        }}
                        params={filters}
                    />
                    <div />
                </Header>
            )}
            {!loading && <PlayerMatchListTable
                data={playerMatchStats}
                additionalColumns={additionalColumns}
            />}
            {loading && <Loader title="Считаем"/>}
        </Container>
    );
};

import { Configuration, TeamContext } from "../types/common";

export const useConfiguration = (asRef: boolean = false): Configuration => {
    const path = window.location.pathname;
    const config = {...globalThis.FCRC_PHP_DATA} as Configuration;

    if (path.indexOf('/3/') >= 0 || path.indexOf('/m/') >= 0) {
        config.teamName = TeamContext.RODINA_3;
        config.seasonId = 19;
        config.tournamentId = 181;
        config.teamId = 724;
        globalThis.FCRC_PHP_DATA = config;
    } else if (path.indexOf('/2/') >= 0) {
        const config = {...globalThis.FCRC_PHP_DATA} as Configuration;
        config.teamName = TeamContext.RODINA_2;
        config.seasonId = 18;
        config.tournamentId = 180;
        config.teamId = 135;
        globalThis.FCRC_PHP_DATA = config;
    }

    if (asRef) {
        return globalThis.FCRC_PHP_DATA as Configuration;
    }

    return {...globalThis.FCRC_PHP_DATA} as Configuration;
}

export const useTeamContext = () => {
    const config: Configuration = useConfiguration();
    return config?.teamName;
}

import { Button, Flex, message } from "antd"
import { ResponsibilitiesHeader, SecondaryHeader, SectionContent, VacancyContent, VacancyShareContainer } from "./styled"
import { ShareAltOutlined } from "@ant-design/icons"
import { SocialShare } from "../../../widgets/social/SocialShare";
import { useState } from "react";
import { FormattedText } from "./FormattedText";

export const Vacancy = ({
    title,
    responsibilities,
    requirements,
    functions,
    additionalRequirements,
}) => {
    const [sharing, setSharing] = useState(false);

    const onShare = async (e) => {
        if (navigator.share) {
            navigator.share({
                title: 'Присоединяйся к ФК Родина',
                url: window.location.href,
            })
                .catch((error) => message.error('Ошибка при попытке поделиться:' + error.message));
        } else {
            setSharing(!sharing);
        }
        e.preventDefault();
        return false;
    };

    return (
        <VacancyContent>
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/quill@2.0.2/dist/quill.snow.css" />
            <Flex justify="space-between" align="center">
                <h1>{title}</h1>
                {<VacancyShareContainer $sharing={sharing}>
                    <SocialShare
                        title='Присоединяйся к ФК Родина'
                        description={'Вакансия ' + title + ' в ФК Родина'}
                        url={window.location.href}
                        onShareCallback={() => setSharing(false)}
                    />
                </VacancyShareContainer>}
                <Button icon={<ShareAltOutlined />} onClick={onShare}>Поделиться</Button>
            </Flex>
            <ResponsibilitiesHeader>Зона ответственности</ResponsibilitiesHeader>
            <SectionContent>
                <FormattedText text={responsibilities} />
            </SectionContent>

            <SecondaryHeader>Требования</SecondaryHeader>
            <SectionContent>
                <h3>Профессиональные навыки:</h3>
                <FormattedText text={requirements} />
                <h3>Другие компетенции и навыки:</h3>
                <FormattedText text={additionalRequirements} />
            </SectionContent>

            <SecondaryHeader>Функционал</SecondaryHeader>
            <SectionContent>
                <FormattedText text={functions} />
            </SectionContent>
        </VacancyContent>
    )
}
export enum TournamentStageType {
    REGULAR,
    PLAYOFF,
    FINAL,
    FRIENDLY_MATCH,
}

export interface TournamentStageDto {
    uuid: string;
    name: string;
    type: TournamentStageType;
    description?: string;
    startDate?: string;
    endDate?: string;
}

export interface TournamentDto {
    uuid: string;
    name: string;
    seasonUuid: string;
    url?: string;
    legacyId?: number;
    tournamentCategoryUuid?: string;
}

export interface TournamentUuids {
    tournamentUuid: string;
    seasonUuid: string;
}

export interface TournamentIds extends TournamentUuids {
    legacyTournament?: number;
    legacySeason?: number;
}

export interface SeasonDto {
    uuid: string;
    name: string;
    start?: string;
    end?: string;
    url?: string;
    legacyId?: number;
}

export interface SelectorTournamenGroup {
    uuid: string;
    name: string;
}

export interface StatsTournamentSelectorItem extends SeasonDto {
    tournaments: TournamentDto[];
    groups?: SelectorTournamenGroup[];
}

export interface StatsTournamentSelectorsDto {
    seasons: StatsTournamentSelectorItem[];
}

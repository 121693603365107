import { FC, useEffect, useState } from "react";
import { PersonList, PersonListTitle } from "../../PersonCard";
import { Empty } from "antd";
import { PlayerShortDto, PlayersFilterBy } from "../../../types/player";
import { FILTER_VALUES, SortRadio } from "./SortRadio";
import { PlayerElement } from "./PlayerElement";
import { applySort, getGroupName, GROUP_ORDER, splitByPositionGroup } from "./helpers";
import { Loader } from "../../Loader";
import { getHashParam, updateHashParams } from "../../../utils";


interface PlayersListProps {
    defaultFilter?: PlayersFilterBy;
    onFilterChange?: (filter: PlayersFilterBy) => void;
    players?: PlayerShortDto[];
    showTitle?: boolean;
    showBirthDate?: boolean;
    noStats?: boolean;
    loading?: boolean;
}

export const PlayersList: FC<PlayersListProps> = ({
    defaultFilter = PlayersFilterBy.Position,
    onFilterChange,
    players,
    showTitle,
    showBirthDate,
    noStats,
    loading,
}) => {
    const [filterValue, setFilterValue] = useState<PlayersFilterBy>(defaultFilter);

    useEffect(() => {
        let filterToSet = defaultFilter;
        if (window.location.hash) {
            const filter = getHashParam('filter') as PlayersFilterBy;

            if (FILTER_VALUES.some(f => f.value === filter)) {
                filterToSet = filter;
            }
        }
        setFilterValue(filterToSet);
    }, [defaultFilter]);

    const handleFilterChange = (value: PlayersFilterBy) => {
        setFilterValue(value);
        updateHashParams({filter: value})
        if (onFilterChange) {
            onFilterChange(value);
        }
    }

    if (loading) {
        return <Loader />;
    }

    if (!players?.length) {
        return <Empty description={false} />;
    }

    let list = null;

    if (filterValue === PlayersFilterBy.Position) {
        const groups = splitByPositionGroup(players);
        list = (
            <>
                {GROUP_ORDER.map((group) => {
                    const playersGroup = groups.get(group);
                    if (!playersGroup?.length) {
                        return null;
                    }
                    const players = playersGroup
                        .sort(applySort(PlayersFilterBy.Position))
                        .map(player => (
                            <PlayerElement
                                key={player.id}
                                player={player}
                                showTitle={showTitle}
                                showBirthDate={showBirthDate}
                                noStats={noStats}
                            />
                        ));
                    return (
                        <div key={group}>
                            <PersonListTitle>{getGroupName(group)}</PersonListTitle>
                            <PersonList>
                                {players}
                            </PersonList>
                        </div>
                    )
                })}
            </>
        );
    } else {
        const sortedPlayers = players.sort(applySort(filterValue));
        list = (
            <PersonList>
                {sortedPlayers.map(player => (
                    <PlayerElement
                        key={player.id}
                        player={player}
                        showTitle={showTitle}
                        showBirthDate={showBirthDate}
                        noStats={noStats}
                    />
                ))}
            </PersonList>
        );
    }

    return (
        <div>
            {!noStats && <SortRadio value={filterValue} onChange={handleFilterChange} defaultValue={defaultFilter} />}
            {list}
        </div>
    )
}
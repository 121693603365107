import { FC } from "react";
import { HR, StatParameterContainer, StatParameterContent, StatParameterName, StatParameterValue } from "./styled";
import CountUp from "react-countup";

interface StatParameterProps {
    name: string;
    value: number;
    nameSize?: number;
}
export const StatParameter: FC<StatParameterProps> = ({ name, value, nameSize = 24 }) => {
    return (
        <StatParameterContainer>
            <StatParameterContent>
                <StatParameterName $size={nameSize}>
                    {name}
                </StatParameterName>
                <HR />
                <StatParameterValue>
                    <CountUp redraw end={value} enableScrollSpy scrollSpyOnce/>
                </StatParameterValue>
            </StatParameterContent>
        </StatParameterContainer>
    );
}
import { FC, ReactNode } from "react";

interface PageItemProps {
    number: ReactNode;
    active?: boolean;
    onClick: () => void;
    disabled?: boolean;

}

export const PageItem: FC<PageItemProps> = ({
    number,
    active,
    onClick,
    disabled,
}) => {
    let classNames = active ? "bg-sky-950 hover:bg-sky-900 text-white" : "bg-white hover:bg-sky-100 text-sky-950";
    if (disabled) {
        classNames = "text-slate-200 cursor-default no-mouse-events";
    }
    return (
        <div className={classNames + " transition-all p-2 w-6 h-6 flex items-center justify-center rounded-full cursor-pointer"} onClick={onClick}>
            {number}
        </div>
    );
}
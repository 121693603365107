import { Container, Title, Spin } from "./styled";

interface LoaderProps {
    size?: "small" | "large";
    title?: string;
    popover?: boolean;
    color?: string;
}

export const Loader: React.FC<LoaderProps> = ({
    size = "large",
    title = "Загружаем",
    popover = false,
    color = "#133D5E"
}) => {
    const classNames = popover ? "absolute top-0 left-0 w-full h-full bg-white bg-opacity-90 z-50 flex items-center justify-center" : "";
    return (
        <Container className={classNames}>
            <Spin size={size} $color={color} />
            {title && <Title $color={color}>{title}</Title>}
        </Container>
    );
}
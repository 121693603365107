import styled from "styled-components";

export const PageContent = styled.div`
    padding: 0 7px;
    padding-bottom: 1px;
`;

export const PageHead = styled.div`
    width: 100%;
    min-height: 450px;
    position: relative;
`;

export const PageHeadImage = styled.img`
    width: 100%;
    object-fit: cover;
`;

export const PageHeadTitle = styled.h1`
    color: #fff;
    font-size: 4rem;
    font-weight: 700;
    font-family: 'Bebas Neue', sans-serif;
    margin: 0;
    text-shadow: 0 3px 4px rgba(0, 0, 0, 1);
`;

export const Page = styled.div``;

export const HeaderTitle = styled.div`
    font-family: 'Bebas Neue', sans-serif;
`;
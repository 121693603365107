import styled from "styled-components";

export const Content = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 40px;
`;

export const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    padding-top: 20px;
    width: 520px;
    min-height: 400px;
    background: linear-gradient(to bottom, #ffffff 0%,#f8f8f8 7%,#ececec 28%,#ececec 75%,#f8f8f8 91%,#ffffff 100%);
`;

export const InfoContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
`;

export const InfoParameterContainer = styled.div`
    margin-top: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
`;

export const InfoParameterName = styled.div`
    font-size: 28px;
    font-family: 'Bebas Neue', sans-serif;
    color: #133D5E;
    text-align: center;
`;

interface InfoParameterValueProps {
    $isLink?: boolean;
}

export const InfoParameterValue = styled.div<InfoParameterValueProps>`
    font-size: 20px;
    font-family: 'Bebas Neue', sans-serif;
    color: #808a98;
    cursor: ${({ $isLink }) => $isLink ? 'pointer' : 'default'};
    text-align: center;
`;

interface HRProps {
    width?: string;
}

export const HR = styled.hr<HRProps>`
    width: ${({ width = '40px' }) => width};
    margin: 0;
    border: 0;
    border-top: 2px solid #133D5E;
    opacity: 0.85;
    &:last-child {
        display: none;
    }
`;

export const StatsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    width: 150px;
`;

export const StatParameterContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 140px;
    min-height: 140px;
    border-radius: 50%;
    background: #e3e3e3;
`;

export const StatParameterContent = styled.div`
    padding-top: 16px;
    max-width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
    font-size: 24px;
    font-family: 'Bebas Neue', sans-serif;
`;

interface StatParameterNameProps {
    $size?: number;
}

export const StatParameterName = styled.div<StatParameterNameProps>`
    font-size: ${({ $size = 24 }) => $size + 'px'};
    font-family: 'Bebas Neue', sans-serif;
    text-align: center;
`;

export const StatParameterValue = styled.div`
    font-size: 30px;
    font-family: 'Bebas Neue', sans-serif;
    font-weight: bold;
    text-align: center;
`;
import { ConfigProvider, Radio } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { FC } from "react";

interface RadioGroupProps {
    value?: string;
    defaultValue?: string;
    onChange?: (value: string) => void;
    options: { key: string; label: string }[];
    size?: SizeType;
}

export const RadioGroup: FC<RadioGroupProps> = ({ value, defaultValue, onChange, options, size }) => {
    return (
        <ConfigProvider
            theme={{
                components: {
                    Radio: {
                        buttonSolidCheckedActiveBg: '#0f243c',
                        buttonSolidCheckedBg: '#132d4b',
                        buttonSolidCheckedHoverBg: '#3d5769',
                        borderRadius: 4,
                    },
                },
            }}
        >
            <Radio.Group
                value={value}
                buttonStyle="solid"
                onChange={
                    (e) => {
                        onChange && onChange(e.target.value);
                    }
                }
                size={size}
                defaultValue={defaultValue || options[0].key}
            >
                {options.map(({ key, label }) => (
                    <Radio.Button key={key} value={key}>{label}</Radio.Button>
                ))}
            </Radio.Group>
        </ConfigProvider>
    );
}
import { PageContent } from "../../../components/layout";
import { useEffect, useRef, useState } from "react";
import { setBgImage } from "../../../utils";
import { motion, useInView } from "framer-motion";

import qrCode from '../../../assets/my/app_qr.svg';
import styled from "styled-components";
import bg from '../../../assets/my/app-page-bg.png';
import { getFanAppPageConfig } from "../../../api/transport/configurationTransport";
import { FanAppPageConfig } from "../../../types/configuration/fan-app";
import { FeedbackForm } from "./FeedbackForm";
import { Button } from "../../../components/ui-kit/RoundButton";

const Wrapper = styled.div`
    font-family: 'Futura', sans-serif;
`;

const MyAppPage = () => {
    const [fanAppPageConfig, setFanAppPageConfig] = useState<FanAppPageConfig>(null);

    useEffect(() => {
        setBgImage(bg);
        getFanAppPageConfig().then((data) => {
            setFanAppPageConfig(data);
        });
    }, []);

    /*useEffect(() => {
        setTimeout(() => {
            const anchorComment = document.getElementById('firstScreen');
            if (anchorComment) {
                anchorComment.scrollIntoView({ behavior: "smooth" });
            }
        }, 0);
    }, []);*/

    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    return (
        <Wrapper id="firstScreen">
            <PageContent className="text-xl">
                <div className="min-h-96 max-h-screen flex flex-col items-center justify-center gap-8 py-12">
                    <motion.div
                        initial={{ opacity: 0, y: -50 }} // Начальное состояние (невидимо и смещено вниз)
                        animate={{ opacity: 1, y: 0 }}  // Конечное состояние (видимо и на месте)
                        transition={{ duration: 0.5 }}  // Длительность анимации
                        className="text-8xl font-bold italic text-white"
                    >
                        Привет, Родной!
                    </motion.div>
                    <motion.div
                        initial={{ opacity: 0, x: 50, scale: 0.9 }} // Начальное состояние (невидимо и смещено вниз)
                        animate={{ opacity: 1, x: 0, scale: 1 }}  // Конечное состояние (видимо и на месте)
                        transition={{ duration: 0.5, type: 'spring', stiffness: 100 }}  // Длительность анимации
                    >
                        <Button title="ТВОЕ ПРИЛОЖЕНИЕ ТУТ" href="https://my.fcrodina.com" target="_blank" />
                    </motion.div>
                </div>
                <div className="max-w-3xl mx-auto text-white text-2xl">
                    <motion.div
                        ref={ref}
                        initial={{ opacity: 0.3 }}
                        animate={isInView ? { opacity: 1 } : { opacity: 0 }}
                        transition={{ duration: 0.5, delay: 0.2 }}
                        className="flex flex-col gap-12"
                    >
                        <div>
                            <p>
                                Устанавливай приложение «Родные» и выбирай лучшего игрока матча! <br />Так ты получаешь привилегии
                                от клуба, а еще возможность лично вручить подарок победителю голосования
                            </p>
                            <div className="flex flex-row gap-4 pt-6">
                                <Button title="Правила голосования" href={fanAppPageConfig?.rulesPageUrl} disabled={!fanAppPageConfig?.rulesPageUrl} />
                                <Button title="Лучшие игроки" href={fanAppPageConfig?.winnersPageUrl} inverted disabled={!fanAppPageConfig?.winnersPageUrl} />
                            </div>
                        </div>
                        <div>
                            <p>Совсем скоро мы запустим рейтинг болельщиков, а значит будем поощрять за активность:</p>
                            <ul>
                                <li>дарить персональные скидки на товары в магазине;</li>
                                <li>разыгрывать именные футболки;</li>
                                <li>раздавать крутые призы;</li>
                            </ul>
                            <p>
                                А еще хотим открыть геймзону с различными играми, интерактивами и викторинами!
                                Наши планы зависят от твоих предпочтений, поэтому пиши, что лично ты хочешь
                                увидеть в приложении для болельщика!
                            </p>
                            {/*<div className="flex flex-row gap-4 pt-6">
                                <Button title="Пара простых вопросов" href={fanAppPageConfig?.feedbackFormUrl} disabled={!fanAppPageConfig?.feedbackFormUrl} />
                            </div>*/}
                        </div>
                        <div className="hidden mx-auto text-center">
                            <img src={qrCode} alt="Ссылка на приложение" />
                        </div>
                        <FeedbackForm />
                    </motion.div>
                </div>
                <div className="flex flex-col items-center py-24">
                    <motion.div
                        initial={{ opacity: 0, x: 50, scale: 0.9 }} // Начальное состояние (невидимо и смещено вниз)
                        animate={{ opacity: 1, x: 0, scale: 1 }}  // Конечное состояние (видимо и на месте)
                        transition={{ duration: 0.5, type: 'spring', stiffness: 100 }}  // Длительность анимации
                    >
                        <Button title="Установи приложение" href="https://my.fcrodina.com" target="_blank" />
                    </motion.div>
                </div>
            </PageContent>
        </Wrapper>
    )
}

export default MyAppPage;
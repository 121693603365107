import { useEffect, useState } from "react";
import { getLauncherLive } from "../../api/transport/launcherTransport";
import { LauncherScheduleItemDto } from "../../types/launcher";
import LauncherPageVideo from "../../components/LauncherPageVideo";
import { Loader } from "../../components/Loader";
import { SocialLinksList } from "../../widgets/social/SocialLinks";
import { SkinOutlined } from "@ant-design/icons";
import { ShopIcon, TicketIcon } from "../../layout/Icons";
import { PartnerLink } from "../../components/Partners/PartnerLink";
import styled from "styled-components";
import bg from '../../assets/launcher/bg.jpg';
import { BottomMenu } from "../../layout/mobile/BottomMenu";

const LauncherContainer = styled.div`
    font-family: 'Bebas Neue', sans-serif;
    background-color: #0b324e;
    background-attachment: fixed;
    background-image: linear-gradient(to bottom, rgba(11,50,78,0.85) 0%,rgba(11,50,78,0.85) 100%), url(${bg});
    background-repeat: repeat;
    background-position: top center;
    background-size: cover;
    box-sizing: border-box;
`;

const LauncherMenu = () => {
    const siteSelecttorClasses = 'transition-all duration-500 w-full max-w-40 md:max-w-64 text-center block box-border !border-1 !border-solid !border-white text-white text-xl lg:text-3xl px-3 py-1 pt-2 md:px-6 md:py-2 md:pt-4 rounded-sm bg-sky-950/75 uppercase hover:no-underline hover:shadow hover:bg-white hover:text-sky-950';
    const teamLinks = [
        {
            key: 'rodina',
            title: 'Родина',
            url: '/rodina/',
        },
        {
            key: 'rodina-2',
            title: 'Родина-2',
            url: '/2/',
        },
        {
            key: 'rodina-3',
            title: 'Родина-М',
            url: '/m/',
        },
        {
            key: 'rodina-m',
            title: 'Молодёжка',
            url: 'https://academy.fcrodina.com/youth/',
        },
        {
            key: 'academy',
            title: 'Академия',
            url: 'https://academy.fcrodina.com/',
        },
    ]
    return (
        <div className="box-border flex flex-col items-center gap-2 sm:gap-3 min-w-80 xl:pt-24">
            <img src="https://fcrodina.com/i/logo.png" alt="ФК Родина" className="w-32 h-32" />
            <div className="text-xl mt-4">
                Перейти на сайт
            </div>
            <div className="flex justify-center flex-row flex-wrap xl:flex-col items-center gap-3 w-full">
                {teamLinks.map((link) => (
                    <a
                        key={link.key}
                        href={link.url}
                        className={siteSelecttorClasses}
                    >
                        {link.title}
                    </a>
                ))}
            </div>
            {/*<div className="text-xl mt-4">
                Премиум партнер
            </div>
            <PartnerLink
                url="https://lf-group.com"
                name="LF Group."
                logo="https://fcrodina.com/i/lf-group-white.png"
                className="!opacity-75 hover:!opacity-100 !h-auto"
            />*/}
        </div>
    );
}

export const LauncherPage: React.FC = () => {
    const [loading, setLoading] = useState(true);
    const [liveData, setLiveData] = useState<LauncherScheduleItemDto | null>(null);
    useEffect(() => {
        setLoading(true);
        getLauncherLive().then((response) => {
            setLiveData(response);
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    let videoElement = null;

    if (loading) {
        videoElement = (
            <div className="w-full aspect-video flex items-center justify-center bg-slate-300/75 rounded">
                <Loader />
            </div>
        );
    } else if (!liveData) {
        videoElement = <LauncherPageVideo url="https://fcrodina.com/static/video/launcher.mp4" />;
    } else {
        videoElement = <LauncherPageVideo url={liveData?.url} bannerUrl={liveData?.bannerUrl} />;
    }

    return (
        <LauncherContainer className="box-border p-2 pt-6 pb-24 sm:py-4 sm:px-8 text-white w-full min-h-full">
            <div className="box-border flex flex-row justify-between gap-4 w-full">
                <div className="hidden xl:block">
                    <LauncherMenu />
                </div>
                <div className="box-border flex flex-col justify-start gap-4 sm:gap-8 w-full">
                    <div className="box-border flex flex-col md:flex-row gap-4 justify-between items-center">
                        <div className="hidden box-border sm:flex flex-col w-full max-w-full sm:w-fit sm:flex-row gap-2 sm:gap-4">
                            <a
                                href="https://shop.fcrodina.com"
                                className="max-h-16 text-center box-border w-full sm:w-64 transition-all bg-white text-sky-950 text-4xl font-bold px-6 py-3 rounded-sm shadow-lg shadow-blue-500/50 uppercase hover:no-underline hover:shadow-sky-500/50 bg-gradient-to-tr from-sky-100 via-white to-sky-100 hover:from-white hover:to-white"
                            >
                                ФАНШОП <SkinOutlined />
                            </a>
                            <a
                                href="https://matchday.fcrodina.com"
                                className="max-h-16 box-border w-full sm:w-64 transition-all bg-white text-sky-950 text-4xl font-bold px-6 py-2 pt-4 rounded-sm shadow-lg shadow-blue-500/50 uppercase hover:no-underline hover:shadow-sky-500/50 flex flex-row items-center justify-center gap-1 bg-gradient-to-tl from-sky-100 via-white to-sky-100 hover:from-white hover:to-white"
                            >
                                Билеты <div className="mt-1"><TicketIcon className="!w-9 !h-9 !text-sky-950" /></div>
                            </a>
                        </div>
                        <div className="box-border flex flex-col sm:flex-row items-center gap-1 sm:gap-4">
                            <SocialLinksList color="white" className="!gap-3" iconClassName="!h-10 !w-10" contactsLink highlightKey="my" />
                        </div>
                    </div>
                    <div className="block xl:hidden">
                        <LauncherMenu />
                    </div>
                    {videoElement}
                </div>
            </div>
            <div className="sm:hidden box-border w-full">
                <BottomMenu items={[
                    { title: 'Забрать билет', href: 'https://matchday.fcrodina.com', icon: <TicketIcon /> },
                    { title: 'Выбрать мерч', href: 'https://shop.fcrodina.com', icon: <ShopIcon /> },
                ]} />
            </div>
        </LauncherContainer>
    );
}
export default LauncherPage;
import { MatchListDto, MatchResult, MatchStatus } from "../../types/stats";
import { Column, FCRodinaTable } from "../Table";
import { TableTeamTitle } from "../Table/TeamTitle";
import {
    IconLink,
    LegendContainer,
    LegendItem,
    LegendMarker,
    LegendText,
    MatchResultContainer,
    MatchResultMarker,
    ScoreLink,
    TableDateContainer
} from "./styled";
import { VideoCameraOutlined } from "@ant-design/icons";
import { getMatchResultColor } from "./utils";
import { getStaticUrl } from "../../utils";

interface PlayerMatchListTableProps {
    data: MatchListDto;
    additionalColumns?: Column[];
}

export const PlayerMatchListTable: React.FC<PlayerMatchListTableProps> = ({ data, additionalColumns = [] }) => {
    const dataColumns = [
        { title: 'Дата', dataIndex: 'date', key: 'date', width: 110 },
        { title: 'Соперник', dataIndex: 'opponentTeamName', key: 'opponentTeamName' },
        { title: 'Тип матча', dataIndex: 'isHome', key: 'isHome' },
        { title: 'Счет', dataIndex: 'score', key: 'score' },
        ...additionalColumns,
    ];

    const dataItems = data.map((item) => {
        return dataColumns.map((column) => {
            let value = item[column.dataIndex];
            if (column.dataIndex === 'opponentTeamName') {
                return {
                    key: column.key,
                    value: (
                        <TableTeamTitle logoUrl={getStaticUrl(item.opponentTeamLogo)} name={value} />
                    )
                }
            } else if (column.dataIndex === 'date') {
                const date = new Date(value).toLocaleDateString('ru-RU', {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                });
                const time = new Date(value).toLocaleTimeString('ru-RU', {
                    hour: '2-digit',
                    minute: '2-digit',
                });
                return {
                    key: column.key,
                    value: (
                        <TableDateContainer>
                            {date}<br />{time}
                        </TableDateContainer>
                    )
                }
            } else if (column.dataIndex === 'score') {
                if (item.status === MatchStatus.IN_PROGRESS) {
                    value = (
                        <MatchResultContainer>
                            <ScoreLink href={item.videoUrl} target="_blank">
                                LIVE
                            </ScoreLink>
                        </MatchResultContainer>
                    );
                } else {
                    value = (
                        <MatchResultContainer>
                            <ScoreLink href={item.protocolUrl}>
                                {item.score}
                            </ScoreLink>
                            <MatchResultMarker $color={getMatchResultColor(item.result)} />
                        </MatchResultContainer>
                    );
                }
            } else if (column.dataIndex === 'isHome') {
                if (item.onCamp) {
                    value = 'На сборах';
                } else {
                    value = value ? 'Дома' : 'На выезде';
                }
            } else if (column.dataIndex === 'videoUrl') {
                if (value) {
                    value = (
                        <IconLink href={value}>
                            <VideoCameraOutlined />
                        </IconLink>
                    );
                }
            }
            return {
                key: column.key,
                value
            }
        });
    });

    return (
        <>
            <LegendContainer>
                <LegendItem>
                    <LegendMarker $color={getMatchResultColor(MatchResult.DRAW)} />
                    <LegendText>
                        Ничья
                    </LegendText>
                </LegendItem>
                <LegendItem>
                    <LegendMarker $color={getMatchResultColor(MatchResult.WIN)} />
                    <LegendText>
                        Победа
                    </LegendText>
                </LegendItem>
                <LegendItem>
                    <LegendMarker $color={getMatchResultColor(MatchResult.LOSS)} />
                    <LegendText>
                        Поражение
                    </LegendText>
                </LegendItem>
            </LegendContainer>
            <FCRodinaTable
                columns={dataColumns}
                data={dataItems}
            />
        </>
    );
}


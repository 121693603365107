import styled from "styled-components";

export const PlayerPhoto = styled.img`
    width: 40px;
    height: 40px;
    object-fit: contain;
    margin-right: 12px;
`;

export const PlayerNameContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: -4px;
    padding-left: 16px;
    cursor: pointer;
`;

export const TextWrapper = styled.div`
    display: inline-block;
    padding-top: 4px;
`;
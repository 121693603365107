import { FileType } from "../types/common";

export const getBase64 = (file: FileType | File): Promise<string | ArrayBuffer> => {
    const res = new Promise<string | ArrayBuffer>((resolve, reject) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            if (!reader.result) {
                return reject('Error reading file');
            }
            resolve(reader.result);
        });
        reader.readAsDataURL(file);
    });

    return res;
};

export const validateImageSize = (file: FileType) => {
    if (
        !file.type.startsWith('image/') ||
        file.size > (1024 * 1024 * 20) ||
        file.size === 0
    ) {
        return false;
    }
    return true;
};

export const converUrlToBase64 = (url: string): Promise<string> => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = 'Anonymous';
        img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.width = img.width;
            canvas.height = img.height;
            ctx?.drawImage(img, 0, 0);
            const dataURL = canvas.toDataURL('image/png');
            resolve(dataURL);
        };
        img.src = url;
    });
}

export const getBase64Url = (base64: string) => {
    return `data:image/png;base64,${base64}`;
}

export const base64ImageToBlob = (str: string): Blob => {
    // extract content type and base64 payload from original string
    var pos = str.indexOf(';base64,');
    var type = str.substring(5, pos);
    var b64 = str.substr(pos + 8);

    // decode base64
    var imageContent = atob(b64);

    // create an ArrayBuffer and a view (as unsigned 8-bit)
    var buffer = new ArrayBuffer(imageContent.length);
    var view = new Uint8Array(buffer);

    // fill the view, using the decoded base64
    for (var n = 0; n < imageContent.length; n++) {
        view[n] = imageContent.charCodeAt(n);
    }

    // convert ArrayBuffer to Blob
    var blob = new Blob([buffer], { type: type });

    return blob;
}

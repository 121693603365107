import { FC } from "react";
import { PageItem } from "./PageItem";

interface PaginationProps {
    total: number;
    perPage: number;
    currentPage: number;
    onChange: (page: number) => void;
    endButtons?: boolean;
}

export const Pagination: FC<PaginationProps> = ({
    total,
    perPage,
    currentPage,
    onChange,
    endButtons,
}) => {
    const pages = Math.ceil(total / perPage);
    const pageItems = [];
    for (let i = 1; i <= pages; i++) {
        pageItems.push(
            <PageItem
                key={i}
                number={i}
                active={i === currentPage}
                onClick={() => onChange(i)}
            />
        );
    }

    const isFirstPage = currentPage <= 1;
    const isLastPage = currentPage >= pages;

    return (
        <div className="flex justify-center space-x-2 items-center min-h-12 pt-4">
            {endButtons && <PageItem key="start" disabled={isFirstPage} onClick={() => onChange(1)} number="<<" />}
            <PageItem key="prev" disabled={isFirstPage} onClick={() => onChange(!isFirstPage ? currentPage-1 : 1)} number="<" />
            {pageItems}
            <PageItem key="next" disabled={isLastPage} onClick={() => onChange(!isLastPage ? currentPage+1 : currentPage)} number=">" />
            {endButtons && <PageItem key="end" disabled={isLastPage} onClick={() => onChange(pages)} number=">>" />}
        </div>
    );
};

import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 32px;
    min-height: 500px;
    width: 100%;
    box-sizing: border-box;
`;

export const PartnersGroup = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    gap: 5px;
    padding: 0 32px;
    box-sizing: border-box;
`;

export const PartnersGroupTitle = styled.h2`
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 16px;
    margin-top: 20px;
    color: #133D5E;
`;

export const PartnerContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 20px 0;
    box-sizing: border-box;

    @media (max-width: 500px) {
        flex-direction: column;
        align-items: center;
    }
`;

export const PartnerLogo = styled.img`
    width: 200px;
    height: 80px;
    object-fit: contain;
    margin-right: 24px;

    @media (max-width: 500px) {
        margin-right: 0;
        margin-bottom: 16px;
    }
`;

export const PartnerInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @media (max-width: 500px) {
        align-items: center;
    }
`;

export const PartnerName = styled.div`
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 8px;
    max-width: 100%;
    @media (max-width: 500px) {
        text-align: center;
    }
`;

export const PartnerDescription = styled.p`
    font-size: 16px;
`;

export const PartnerLink = styled.a`
    font-size: 16px;
    color: #133D5E;
    text-decoration: none;
`;

export const HR = styled.hr`
    width: 90%;
    border: 1px solid #f0f0f0;
    align-self: center;
`;
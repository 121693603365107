import styled from "styled-components";

interface SlideProps {
    $fullWidth?: boolean;
}

export const Slide = styled.div<SlideProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 16px;
`;

export const SlideWrapper = styled.div`
    display: flex;
`

export const LastElementWrapper = styled.div`
    //filter: brightness(0.75) grayscale(1);
    opacity: 0.25;
    pointer-events: none;
    cursor: unset;
`;

export const ElementWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px;
    padding: 20px;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
`;

interface ButtonsContainerProps {
    $left?: number;
}

export const ButtonsContainer = styled.div<ButtonsContainerProps>`
    position: absolute;
    left: ${({ $left }) => $left ? `${$left}px` : '0'};
    top: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 100;

    @media (max-width: 500px) {
        top: -40px;
        right: 0;
        left: unset;
    }
`;

export const Button = styled.button`
    background-color: #fff;
    color: #133D5E;
    border: none;
    padding: 8px 12px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    vertical-align: middle;
    border-radius: 0;
    font-size: 24px;
    height: 64px;
    width: 54px;
    cursor: pointer;
    transition: all 0.8s;
    &:hover {
        background-color: #133D5E;
        color: #fff;
    }
    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
        background-color: #ccc;
        mouse-events: none;
    }
    @media (max-width: 500px) {
        height: 36px;
        width: 42px;
        font-size: 14px;
        background-color: unset;
    }
`;

export const PrevButton = styled(Button)`
    border-radius: 2px 0 0 2px;
`;

export const NextButton = styled(Button)`
    border-radius: 0 2px 2px 0;
`;

export const Container = styled.div`
    position: relative;
`;
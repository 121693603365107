import { PlayersList } from "../../../components/player/PlayersList";
import { PlayerShortDto, PlayersFilterBy } from "../../../types/player";
import { TeamContext } from "../../../types/common";
import { FC, useCallback, useEffect, useState } from "react";
import { getPlayers } from "../../../api/transport/playersTransport";
import { Tabs, type TabsProps } from 'antd';
import { SolutionOutlined } from "@ant-design/icons";
import { Header, ProfessinalsLinkText, ProfessionalsLink } from "./styled";
import { RadioGroup } from "../../../components/RadioGroup";
import { PageContent } from "../../../components/layout";

enum PlayersGroup {
    PLAYERS = 'players',
    GRADUATES = 'graduates',
}

interface AcademyPlayersPageProps { }

export const AcademyPlayersPage: FC<AcademyPlayersPageProps> = () => {
    const [loading, setLoading] = useState(true);
    const [players, setPlayers] = useState<PlayerShortDto[]>([]);
    const [selectedYear, setSelectedYear] = useState('9');
    const [playersType, setPlayersType] = useState(PlayersGroup.PLAYERS);
    const [searchParams, setSearchParams] = useState<URLSearchParams>();

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.hash.slice(1));
        const year = searchParams.get('year');
        const type = searchParams.get('type');
        setSearchParams(searchParams);

        if (type) {
            setPlayersType(type as PlayersGroup);
        } else {
            setPlayersType(PlayersGroup.PLAYERS);
        }
        if (year && year !== selectedYear) {
            setSelectedYear(year);
            return;
        }

        setLoading(true);
        getPlayers(TeamContext.ACADEMY, `?year=${selectedYear}`)
            .then((data) => {
                setPlayers(data);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [selectedYear]);

    const itemGroups: Record<PlayersGroup, TabsProps['items']> = {
        [PlayersGroup.PLAYERS]: [
            {
                key: '9',
                label: 'Родина 2008',
            },
            {
                key: '10',
                label: 'Родина 2009',
            },
            {
                key: '11',
                label: 'Родина 2010',
            },
            {
                key: '13',
                label: 'Родина 2011',
            },
            {
                key: '15',
                label: 'Родина 2012',
            },
            {
                key: '16',
                label: 'Родина 2013',
            },
            {
                key: '17',
                label: 'Родина 2014',
            },
            {
                key: '18',
                label: 'Родина 2015',
            },
            /*{
                key: '19',
                label: 'Родина 2016',
                disabled: true,
            },*/
        ],
        [PlayersGroup.GRADUATES]: [
            {
                key: '1',
                label: 'Родина 2000',
            },
            {
                key: '2',
                label: 'Родина 2001',
            },
            {
                key: '3',
                label: 'Родина 2002',
            },
            {
                key: '4',
                label: 'Родина 2003',
            },
            {
                key: '5',
                label: 'Родина 2004',
            },
            {
                key: '6',
                label: 'Родина 2005',
            },
            {
                key: '7',
                label: 'Родина 2006',
            },
            {
                key: '8',
                label: 'Родина 2007',
            },
        ],
    }

    const onParamsChange = useCallback((teamType: PlayersGroup, teamYear: string) => {
        setPlayersType(teamType);
        setSelectedYear(teamYear);
        searchParams.set('type', teamType);
        searchParams.set('year', teamYear);
        window.location.hash = searchParams.toString();
    }, [searchParams]);

    const onTeamChange = useCallback((key: string) => {
        onParamsChange(playersType, key);
    }, [onParamsChange, playersType]);

    return (
        <PageContent>
            <Header>
                <h1>Команды Академии</h1>
                <div>
                    <RadioGroup
                        options={[
                            { key: PlayersGroup.PLAYERS, label: 'Действующие игроки' },
                            { key: PlayersGroup.GRADUATES, label: 'Выпускники' },
                        ]}
                        value={playersType}
                        onChange={(v) => {
                            let year = '';
                            if (v === PlayersGroup.GRADUATES) {
                                year = itemGroups[v][itemGroups[v].length - 1].key;
                            } else {
                                year = itemGroups[v][0].key;
                            }
                            onParamsChange(v as PlayersGroup, year);
                        }}
                    />
                </div>
                <ProfessionalsLink href="/academy/">
                    <ProfessinalsLinkText>
                        Профессионалы
                    </ProfessinalsLinkText>
                    <SolutionOutlined />
                </ProfessionalsLink>
            </Header>
            <Tabs
                activeKey={selectedYear}
                items={itemGroups[playersType]}
                onChange={onTeamChange}
                centered
            />
            <PlayersList
                defaultFilter={PlayersFilterBy.Position}
                loading={loading}
                players={players as PlayerShortDto[]}
                showBirthDate
                noStats={+selectedYear > 13}
            />
        </PageContent>
    );
};
import { Checkbox, DatePicker, Form, Input, Result, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import { FC, useState } from "react";
import { FCRodinaButton } from "../../../components/Button";
import dayjs from "dayjs";
import { accreditationRequest } from "../../../api/transport/common";
import { AccreditationType } from "../../../types/common";

interface AccreditationFormValues {
    fullName: string;
    publisher: string;
    accreditationType: AccreditationType;
    teams: string[];
    date: string;
    phoneNumber: string;
    email: string;
    consent: boolean;
}

interface AccreditationFormProps { }

export const AccreditationForm: FC<AccreditationFormProps> = () => {
    const [form] = useForm<AccreditationFormValues>();
    const [completed, setCompleted] = useState(false);
    const [error, setError] = useState(false);

    const onFormReset = () => {
        setCompleted(false);
        setError(false);
        if (!error) {
            form.resetFields();
        }
    }

    const onFormFinish = (values: AccreditationFormValues) => {
        const comment = values.teams.map((t: string) => `ЮФЛ-${t[3]}`).join(', ');

        try {
            accreditationRequest({
                fullName: values.fullName,
                publisher: values.publisher,
                accreditationType: values.accreditationType,
                date: dayjs(values.date).format('DD.MM.YYYY'),
                phoneNumber: values.phoneNumber,
                email: values.email,
                comment,
            });
            setCompleted(true);
        } catch (e) {
            setError(true);
        }
    }

    if (completed) {
        return (
            <Result
                status='success'
                title='Заявка отправлена'
                subTitle='Ваша заявка отправлена на рассмотрение'
                extra={[
                    <FCRodinaButton key='reset' size='large' onClick={onFormReset}>Отправить еще</FCRodinaButton>
                ]}
            />
        )
    } else if (error) {
        return (
            <Result
                status='error'
                title='Отправить заявку не удалось'
                subTitle='Пожалуйста, попробуйте заполнить форму заново или воспользуйтесь контактным e-mail'
                extra={[
                    <FCRodinaButton key='reset' size='large' onClick={onFormReset}>Попробовать еще раз</FCRodinaButton>
                ]}
            />
        )

    }

    return (
        <div className='mx-auto w-full'>
            <h2 className="text-center py-8">Оформить заявку</h2>
            <Form
                form={form}
                wrapperCol={{ span: 12 }}
                labelCol={{ span: 6 }}
                onFinish={onFormFinish}
                size="large"
                initialValues={{
                    teams: ['yfl1', 'yfl2', 'yfl3'],
                    date: dayjs(),
                }}
            >
                <Form.Item
                    label='Фамилия и имя'
                    name='fullName'
                    rules={[{ required: true, message: 'Введите фамилию' }]}
                >
                    <Input allowClear placeholder="Ваше имя" />
                </Form.Item>
                <Form.Item
                    label='Издание'
                    name='publisher'
                    rules={[{ required: true, message: 'Укажите издание' }]}
                >
                    <Input allowClear placeholder="Название издания" />
                </Form.Item>
                <Form.Item
                    label='Тип аккредитации'
                    name='accreditationType'
                    rules={[{ required: true, message: 'Выберите тип аккредитации' }]}
                >
                    <Select
                        placeholder='Выберите тип аккредитации'
                        allowClear
                    >
                        <Select.Option value={AccreditationType.Photo}>Фото</Select.Option>
                        <Select.Option value={AccreditationType.Video}>Видео</Select.Option>
                        <Select.Option value={AccreditationType.Writer}>Пишущий</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label='Команды'
                    name='teams'
                    rules={[{ required: true, message: 'Выберите команды' }]}
                >
                    <Select
                        mode='multiple'
                        placeholder='Выберите команды'
                        allowClear
                        defaultValue={['yfl1', 'yfl2', 'yfl3']}
                    >
                        <Select.Option value='yfl3'>ЮФЛ-3</Select.Option>
                        <Select.Option value='yfl2'>ЮФЛ-2</Select.Option>
                        <Select.Option value='yfl1'>ЮФЛ-1</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Дата"
                    name='date'
                    rules={[{ required: true, message: 'Выберите дату' }]}
                    help='Выберите дату матча'
                >
                    <DatePicker allowClear defaultValue={dayjs()} format={'DD.MM.YYYY'} placeholder="Дата" minDate={dayjs()} maxDate={dayjs('2025-11-30')} />
                </Form.Item>
                <Form.Item
                    label='Телефон'
                    name='phoneNumber'
                    rules={[
                        { required: true, message: 'Введите номер телефона' },
                        { pattern: /^\+?\d+$/, message: 'Номер телефона должен состоять из цифр' },
                    ]}
                >
                    <Input allowClear placeholder="Номер телефона" maxLength={15} />
                </Form.Item>
                <Form.Item
                    label='Email'
                    name='email'
                    rules={[
                        { required: true, message: 'Введите email' },
                        { type: 'email', message: 'Введите корректный email' }
                    ]}
                >
                    <Input allowClear placeholder="Email" />
                </Form.Item>
                <Form.Item
                    label=" "
                    colon={false}
                    name='consent'
                    valuePropName="checked"
                    rules={[{ required: true, message: 'Подтвердите согласие' }]}
                >
                    <Checkbox>
                        Я согласен c <a rel="noopener" href="https://fcrodina.com/static/docs/PrivacyPolicy.docx" target="_blank">политикой конфиденциальности</a>
                    </Checkbox>
                </Form.Item>
            </Form>

            <div className="flex justify-center">
                <FCRodinaButton size="large" onClick={() => form.submit()}>Отправить</FCRodinaButton>
            </div>
        </div>
    );
}
import styled from 'styled-components';

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
`;

export const ProfessionalsLink = styled.a`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;
    font-size: 24px;
    font-weight: 500;
    font-family: 'Bebas Neue', sans-serif;
    color: #132d4b;
    text-decoration: none;
    transition: border-color 0.3s;
    cursor: pointer;

    border-bottom: 2px solid transparent;

    &:hover {
        border-color: #132d4b;
        text-decoration: none;
    }
`;

export const ProfessinalsLinkText = styled.span`
    display: flex;
    align-items: center;
    padding-top: 4px;
    text-transform: uppercase;
`;

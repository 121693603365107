import { useState } from "react";

/**
 * Hook for resetting iframe content
*/
const useResetIframe = (): [number, () => void] => {
    const [resetCounter, setResetCounter] = useState<number>(0);

    const onReset = () => {
        setResetCounter(resetCounter + 1);
    }

    return [resetCounter, onReset];
};

export default useResetIframe;

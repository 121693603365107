import { Button } from "antd";
import { FC } from "react";
import { MatchdayActivitiesWidget } from "../../widgets/my/matchday/MatchdayActivities";
import { Header, Title } from "../timelineEditor/styled";
import { PlusOutlined } from "@ant-design/icons";

interface MatchdayAdminProps {
    onAddClick?: () => void;
    onEditClick?: (id: string) => void;
    onDeleteClick?: (id: string) => void;
}

export const MatchdayAdmin: FC<MatchdayAdminProps> = ({
    onAddClick = () => window.location.href = '/admin/?matchday&edit',
    onEditClick = (id: string) => window.location.href = `/admin/?matchday&edit=${id}`,
    onDeleteClick,
}) => {
    return (
        <div>
            <Header>
                <Button
                    type="primary"
                    onClick={onAddClick}
                    icon={<PlusOutlined />}
                    size="large"
                >
                    Добавить активность
                </Button>
                <Title>Активности</Title>
            </Header>
            <MatchdayActivitiesWidget edit={{
                onEdit: onEditClick,
                onDelete: onDeleteClick,
            }} />
        </div>
    );
}
import { BACKEND_URL } from "..";
import { AccreditationRequestDto } from "../../types/common";

export const accreditationRequest = async (
    requestData: AccreditationRequestDto,
) => {
    const response = await fetch(BACKEND_URL + '/services/press/accreditation-request', {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestData)
    });

    const data = await response.json();
    if (response.status !== 200) {
        console.error(data);
        return Promise.reject(data);
    }

    return data;
}
import { Carousel } from "antd";
import { FC, useEffect, useState } from "react";
import { FeatureNewsDto } from "../../../types/news";
import { getFeaturedNews } from "../../../api/transport/newsTransport";
import { TeamContext } from "../../../types/common";
import { getStaticUrl, getUrlByContext } from "../../../utils";
import { Loader } from "../../../components/Loader";
import { FeaturedCard } from "./FeaturedCard";

const getFullUrl = (url: string) => {
    return url.includes('http') ? url : `${window.location.origin}${url}`;
}

interface FeaturedNewsWidgetProps {
    teamContext?: TeamContext;
}

export const FeaturedNewsWidget: FC<FeaturedNewsWidgetProps> = ({
    teamContext = TeamContext.RODINA,
}) => {
    const [news, setNews] = useState<FeatureNewsDto[]>([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setLoading(true);
        getFeaturedNews(teamContext).then((data) => {
            setNews(data);
        }).finally(() => {
            setLoading(false);
        });
    }, [teamContext]);

    if (loading) {
        return <Loader />;
    }

    if (!news.length) {
        return null;
    }

    return (
        <div className="rounded-sm overflow-hidden opacity-90 hover:opacity-100">
            <Carousel autoplay arrows>
                {news.map((newsItem) => {
                    const newsUrl = getUrlByContext(`/${newsItem.entityType}/${newsItem.link}/`, teamContext);
                    return (
                        <FeaturedCard
                            key={newsItem.id}
                            url={getFullUrl(newsUrl)}
                            mediaUrl={getStaticUrl(newsItem.mediaUrl)}
                            title={newsItem.title}
                            description={newsItem.description}
                        />
                    );
                })}
            </Carousel>
        </div>
    );
};
import styled from "styled-components";

export const PartnersContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 40px;
    padding: 20px 0 40px;
    border-bottom: 1px solid #3c3f46;
`;
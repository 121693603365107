import { Carousel, Image } from "antd";
import { getStaticUrl } from "../../../../../utils";

const MAX_WIDTH = 645;
const MAX_HEIGHT = Math.ceil(MAX_WIDTH * 0.8);

export const CitiesCarousel = () => {
    return (
        <Carousel
            autoplay
            style={{
                backgroundColor: '#133D5E80',
                textAlign: 'center',
                maxWidth: MAX_WIDTH,
                maxHeight: MAX_HEIGHT,
                overflow: 'hidden',
                marginBottom: '24px'
            }}>
            <Image height={MAX_HEIGHT} src={getStaticUrl("/uploads/images/contests/cities/gallery/cities_00001.png")} />
            <Image height={MAX_HEIGHT} src={getStaticUrl("/uploads/images/contests/cities/gallery/cities_00002.png")} />
            <Image height={MAX_HEIGHT} src={getStaticUrl("/uploads/images/contests/cities/gallery/cities_00003.png")} />
            <Image height={MAX_HEIGHT} src={getStaticUrl("/uploads/images/contests/cities/gallery/cities_00004.png")} />
            <Image height={MAX_HEIGHT} src={getStaticUrl("/uploads/images/contests/cities/gallery/cities_00005.png")} />
            <Image height={MAX_HEIGHT} src={getStaticUrl("/uploads/images/contests/cities/gallery/cities_00006.png")} />
            <Image height={MAX_HEIGHT} src={getStaticUrl("/uploads/images/contests/cities/gallery/cities_00007.png")} />
            <Image height={MAX_HEIGHT} src={getStaticUrl("/uploads/images/contests/cities/gallery/cities_00008.png")} />
            <Image height={MAX_HEIGHT} src={getStaticUrl("/uploads/images/contests/cities/gallery/cities_00009.jpg")} />
            <Image height={MAX_HEIGHT} src={getStaticUrl("/uploads/images/contests/cities/gallery/cities_00010.jpg")} />
            <Image height={MAX_HEIGHT} src={getStaticUrl("/uploads/images/contests/cities/gallery/cities_00011.jpg")} />
            <Image height={MAX_HEIGHT} src={getStaticUrl("/uploads/images/contests/cities/gallery/cities_00012.jpg")} />
        </Carousel>
    );
}
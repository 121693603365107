import {
    Button,
    Checkbox,
    GetProp,
    Input,
    InputNumber,
    UploadProps,
    Image,
    Alert,
    Divider,
    Typography,
} from "antd";
import {
    Form,
    FormContainer,
    Container,
    BackButtonContainer,
    Header,
    Title,
    PointPreview
} from "../timelineEditor/styled";
import { FC, useState } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { getBase64 } from "../utils";

interface EditMatchdayActivityProps {
    initialValues?: Record<string, unknown>;
}

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

const { Text } = Typography;

export const EditMatchdayActivity: FC<EditMatchdayActivityProps> = ({ initialValues = {} }) => {
    const [previewIcon, setPreviewIcon] = useState((initialValues?.icon || '') as string);
    const [previewImage, setPreviewImage] = useState((initialValues?.image || '') as string);
    const initial = {
        ...initialValues,
        description: initialValues?.description || "",
        active: isNaN(+initialValues?.active) ? false : !!+initialValues?.active,
    }

    const formProps = {
        labelCol: { span: 8 },
        wrapperCol: { span: 12 }
    }

    const handleChangeIconInput = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            const preview = await getBase64(file as FileType);
            setPreviewIcon(preview);
        }
    }

    const handleChangeImageInput = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            const preview = await getBase64(file as FileType);
            setPreviewImage(preview);
        }
    }

    return (
        <Container>
            <Header>
                <BackButtonContainer>
                    <Button type="link" onClick={() => window?.history?.back()} size="large" icon={<ArrowLeftOutlined />}>
                        Назад
                    </Button>
                </BackButtonContainer>
                <Title>
                    {initial["id"] ? "Редактирование" : "Добавление"} активности {initial?.["name"] || ''}
                </Title>
            </Header>
            <FormContainer>
                <Alert
                    message="Важная информация"
                    description={
                        <>
                            <p>
                                Функционал загрузки фото сейчас перерабатывается, поэтому при необходимости добавить/заменить галерею к
                                активности (как, например, в "Угадай счёт"), это придётся сделать вручную. Для этого нужно написать мне на
                                почту <a href="mailto:it@fcrodina.com"><b>it@fcrodina.com</b></a> и прикрепить фото, которые нужно добавить как галерею или прислать их мне в телеграм,
                                после чего я оперативно добавлю нужную галерею к активности.
                            </p>
                            <p>
                                Текст выше касается только <b>АКТИВНОСТЕЙ С ГАЛЕРЕЕЙ</b>, единичные фото можно добавить в поле "Описание"
                                обычным способом.
                            </p>
                        </>
                    }
                    type="info"
                    showIcon
                />
                <Divider />
                <Form.Item label="numId" hidden initialValue={initial["id"]}>
                    <Input name="id" defaultValue={initial["id"]} />
                </Form.Item>
                <Form.Item label="Название" required {...formProps} initialValue={initial["name"]}>
                    <Input name="name" defaultValue={initial["name"]} />
                </Form.Item>
                <Form.Item label="Короткое описание" required {...formProps} initialValue={initial["shortDescription"]}>
                    <Input.TextArea name="shortDescription" defaultValue={initial["shortDescription"]} />
                </Form.Item>
                <Form.Item label="Сортировка" {...formProps} initialValue={initial["sortOrder"]}>
                    <InputNumber name="sortOrder" defaultValue={initial["sortOrder"] || 0} />
                </Form.Item>
                <Form.Item
                    label="Активно"
                    valuePropName="checked"
                    {...formProps}
                    initialValue={initial["active"]}
                >
                    <Checkbox name="active" defaultChecked={initial["active"]} />
                </Form.Item>
                <Form.Item label="Картинка" {...formProps}>
                    <input type="file" name="image" onChange={handleChangeImageInput} />
                    <div>
                        <Text type="secondary">Минимальный размер картинки: 268 x 179px</Text>
                    </div>
                    {previewImage && (
                        <Image
                            style={{ width: '280px' }}
                            preview
                            src={previewImage}
                        />
                    )}
                </Form.Item>
                <Form.Item label="Иконка" {...formProps}>
                    <input type="file" name="icon" onChange={handleChangeIconInput} />
                    <div>
                        <Text type="secondary">Размер картинки: 24 x 24px</Text>
                    </div>
                    {previewIcon && (
                        <PointPreview>
                            <Image
                                wrapperStyle={{ width: '25px', height: '25px' }}
                                style={{ width: '25px', height: '25px' }}
                                preview
                                src={previewIcon}
                            />
                        </PointPreview>
                    )}
                </Form.Item>
                <Form.Item
                    label="Описание"
                    required
                    {...formProps}
                >
                </Form.Item>
            </FormContainer>
        </Container>
    );
}
import { RightCircleOutlined } from "@ant-design/icons";
import styled from "styled-components";

const FIXED_WIDTH = 280;

export const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
    @media (max-width: 500px) {
        margin-bottom: 24px;
        margin-top: 48px;
    }
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    color: #133D5E;
    max-width: 100%;
    min-width: 240px;
    padding: 6px 12px;
    border-radius: 4px;
    background-color: #f0f0f0;
    cursor: pointer;
    box-sizing: border-box;
    transition: all 0.3s;
    &:hover {
        //background-color: #e0e0e0;
        box-shadow: 0 0 4px 0 rgba(0,0,0,0.2);
    }
    @media (max-width: 500px) {
       width: 100%;
    }
`;

export const Title = styled.h2`
    font-family: 'Bebas Neue', sans-serif;
    font-size: 32px;
    font-weight: 600;
    padding-top: 8px;
    color: #133D5E;
    text-align: left;
`;

export const CollapseIcon = styled.div`
    cursor: pointer;
    font-size: 20px;
    margin-left: 24px;
`;


interface ContentProps {
    $collapsed?: boolean;
}

export const Content = styled.div<ContentProps>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    position: relative;
    box-sizing: border-box;
    text-align: justify;
    font-size: 18px;
    ${({ $collapsed }) => $collapsed && `
        max-width: 400px;
        font-size: 20px;
        cursor: pointer;
    `}
`;

interface CollapsingTextProps {
    $collapsed: boolean;
    $maxHeight: number;
    $transitionDuration?: number;
}

export const CollapsingText = styled.div<CollapsingTextProps>`
    margin: 0;
    overflow: hidden;
    max-height: ${({ $collapsed, $maxHeight }) => $collapsed ? `${$maxHeight}px` : "0"};
    opacity: ${({ $collapsed }) => $collapsed ? "1" : "0"};
    transition: all ${({ $transitionDuration }) => $transitionDuration || 0.7}s linear;
    //trasition: max-height 0.3s linear, opacity 0.3s linear;
    //trasition: opacity 1.5s linear;
    max-width: 100%;
    box-sizing: border-box;
`;

interface LeftSideContainerProps {
    $height?: number;
    $collapsed?: boolean;
}

export const LeftSideContainer = styled.div.attrs<LeftSideContainerProps>(({ $height }) => ({
    style: {
        height: $height ? `${$height}px` : 'auto',
    },
})) <LeftSideContainerProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 0 40px 0 0;
    max-width: ${FIXED_WIDTH}px;
    min-width: ${FIXED_WIDTH}px;
    transition: all 0.1s linear;
    box-sizing: border-box;
    ${({ $collapsed }) => !$collapsed && `
        padding-right: 12px;
        padding-top: 72px;
    `}
    @media (max-width: 500px) {
       display: none;
    }
`;

interface RightSideContainerProps {
    $isLast?: boolean;
    $collapsed?: boolean;
}

export const RightSideContainer = styled.div<RightSideContainerProps>`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 0 20px 40px;
    flex: 1;
    border-left: 2px solid ${({ $isLast }) => !$isLast ? '#133D5E' : '#ffffff00'};
    transition: all .7s;
    box-sizing: border-box;
    min-height: 180px;
    max-width: calc(100% - ${FIXED_WIDTH}px);
    @media (max-width: 500px) {
       max-width: 100%;
       padding-left: 12px;
       padding-right: 12px;
       border-left: none;
    }
`;

export const Image = styled.img`
    max-height: 100%;
    object-fit: contain;
    max-width: 100%;
    cursor: pointer;
`;

export const Point = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #133D5E;
    color: white;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    position: absolute;
    left: -31px;
    cursor: pointer;
    &:hover {
        box-shadow: 0 0 4px 0 rgba(19, 61, 94, 0.8);
    }
    @media (max-width: 500px) {
        left: 0;
        right: 0;
        top: -72px;
        margin: 0 auto;
    }
`;

export const TextWrapper = styled.div`
    box-sizing: border-box;
    padding-bottom: 16px;
`;

export const ExpandButton = styled(RightCircleOutlined)`
    color: #0070B4;
    transition: all 0.3s;

    &:hover {
        color: #005A8C;
        scale: 1.05;
    }
`
import { FC, useEffect, useState } from "react";
import { getPlayerStatsById } from "../../../api/transport/statsTransport";
import { TeamContext } from "../../../types/common";
import { PlayerDto, PlayerPositionGroup, PlayersStats, StatParams } from "../../../types/player";
import { Content, InfoContainer, InfoContent, StatsContainer } from "./styled";
import { StatParameter } from "./StatParameter";
import { InfoParameter } from "./InfoParameter";
import { PlayerMatchesWidget } from "../PlayerMatches";
import { getFormattedDate } from "../../../utils";

interface PlayerExtendedInfoWidgetProps {
    teamContext?: TeamContext;
    teamId?: number;
    playerId: string;
    playerData?: PlayerDto;
    tournamentUuid?: string;
    seasonUuid?: string;
}

export const PlayerExtendedInfoWidget: FC<PlayerExtendedInfoWidgetProps> = ({
    playerId,
    playerData,
    teamContext,
    tournamentUuid,
    seasonUuid,
    teamId,
}) => {
    const [playerStats, setPlayerStats] = useState<PlayersStats>();
    useEffect(() => {
        if (tournamentUuid && seasonUuid) {
            getPlayerStatsById(playerId, tournamentUuid, seasonUuid, teamContext).then((data) => {
                setPlayerStats(data);
            });
        }
    }, [playerId, seasonUuid, teamContext, tournamentUuid]);

    if (!playerData) {
        return null;
    }

    let debutMatch = null;

    if (playerData.debutMatch) {
        const val = `${playerData.debutMatch.homeTeam.name} ${playerData.debutMatch.score} ${playerData.debutMatch.guestTeam.name}`;
        const date = new Date(playerData.debutMatch.date).toLocaleDateString('ru-RU', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
        debutMatch = (
            <InfoParameter
                name={`Дебют за "РОДИНУ" ${date}`}
                value={val}
                link={playerData.debutMatch.matchUrl}
            />
        )
    }

    const isGk = playerData.positionGroup === PlayerPositionGroup.Goalkeeper;

    let filters: {
        params: StatParams[];
        default: StatParams
    } = null;

    if (playerStats) {
        if (isGk) {
            filters = {
                params: [
                    StatParams.MATCHES, StatParams.CLEAN_SHEETS, StatParams.PENALTIES_SAVED, StatParams.YELLOW_CARDS, StatParams.RED_CARDS
                ],
                default: StatParams.CLEAN_SHEETS
            }
        } else {
            filters = {
                params: [
                    StatParams.MATCHES, StatParams.GOALS, StatParams.ASSISTS, StatParams.YELLOW_CARDS, StatParams.RED_CARDS
                ],
                default: StatParams.GOALS
            }
        }

        filters.params = filters.params.filter((param) => playerStats[param] > 0);

        /*if (teamContext !== TeamContext.ACADEMY && teamContext !== TeamContext.RODINA_M) {
            filters.params = [];
        }*/
    }

    return (
        <>
            <Content>
                <StatsContainer>
                    <StatParameter name="Матчей" value={playerStats?.matches || 0} />
                    {!isGk && <StatParameter name="Голов" value={playerStats?.goals || 0} />}
                    {!isGk && <StatParameter name="Ассистов" value={playerStats?.assists || 0} />}
                    {isGk && <StatParameter name="Сухих" value={playerStats?.cleanSheets || 0} />}
                    {isGk && <StatParameter name="Отбитые пенальти" value={playerStats?.penaltiesSaved || 0} />}
                </StatsContainer>
                <InfoContainer>
                    <InfoContent>
                        {playerData.birthDate && (
                            <InfoParameter
                                name="Дата рождения"
                                value={getFormattedDate(new Date(playerData.birthDate))}
                            />
                        )}
                        {playerData.birthPlace && <InfoParameter name="Место рождения" value={playerData.birthPlace} />}
                        {!playerData.nextClub && playerData.prevClub && <InfoParameter name="Пришёл из" value={playerData.prevClub} />}
                        {playerData.nextClub && <InfoParameter name="Ушёл в" value={playerData.nextClub} />}
                        {debutMatch}
                    </InfoContent>
                </InfoContainer>
                <StatsContainer>
                    <StatParameter name="Минут" value={playerStats?.minutesPlayed || 0} />
                    <StatParameter name="Предупреждений" value={playerStats?.yellowCards || 0} nameSize={18} />
                    <StatParameter name="Удалений" value={playerStats?.redCards || 0} />
                </StatsContainer>
            </Content>
            {playerStats && <PlayerMatchesWidget
                playerId={playerId}
                playerData={playerData}
                teamContext={teamContext}
                tournamentUuid={tournamentUuid}
                seasonUuid={seasonUuid}
                teamId={teamId}
                filters={filters?.params}
                defaultFilter={filters?.default}
            />}
        </>
    );
};

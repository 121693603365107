import styled from "styled-components";

export const BannerContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: #000;
    border-radius: 2px;

    @media (min-width: 500px) {

    }
`;

export const Banner = styled.img`
    width: 100%;
    height: auto;
    max-width: 1024px;
`;
import { useEffect, useState } from "react";
import { Partners } from "../../../components/Partners"
import { PartnersGroupsDto } from "../../../types/configuration/partners";
import { getPartners } from "../../../api/transport/configurationTransport";
import { TeamContext } from "../../../types/common";
import { PartnersContainer } from "./styled";

interface PartnersWidgetProps {
    teamName?: TeamContext;
}

export const PartnersWidget: React.FC<PartnersWidgetProps> = ({teamName}) => {
    const [partners, setPartners] = useState<PartnersGroupsDto>();

    useEffect(() => {
        getPartners(teamName).then((data) => {
            setPartners(data);
        });
    }, [teamName]);

    if (!partners) {
        return null;
    }

    return (
        <PartnersContainer>
            <Partners
                groups={{
                    club: {
                        title: teamName === TeamContext.ACADEMY ? '' : '',
                        partners: partners?.club || [],
                    },
                    league: {
                        title: teamName === TeamContext.ACADEMY ? 'Лига' : '',
                        partners: partners?.league || [],
                    },
                }}
            />
        </PartnersContainer>
    );
}

import { ConfigProvider } from "antd";
import { Container } from "./styled";
import { useEffect, useState } from "react";
import { getMatchdayFeedback } from "../../../../api/transport/matchdayTransport";
import { Loader } from "../../../../components/Loader";
import { FeedbackItem } from "./FeedbackItem";
import { CollapsePanel } from "./CollapsePanel";
import { TgPostFeedbackItem } from "./TgPostFeedbackItem";
import { ItemsList } from "./ItemsList";

const TOP_ITEMS_COUNT = 3;

export const MatcdayFeedbackWidget = () => {
    const [feedbackData, setFeedbackData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        getMatchdayFeedback()
            .then((data) => setFeedbackData(data))
            .finally(() => setLoading(false));
    }, []);


    if (loading) {
        return <Loader />;
    }

    const topItems = feedbackData?.length > TOP_ITEMS_COUNT * 2 ? feedbackData.slice(0, TOP_ITEMS_COUNT) : feedbackData;
    const otherItems = feedbackData?.length > TOP_ITEMS_COUNT * 2 ? feedbackData.slice(TOP_ITEMS_COUNT) : [];

    return (
        <ConfigProvider
            theme={{
                token: {
                    fontSize: 16,
                    colorText: "#000",
                    colorBorderSecondary: '#d7e3ec',
                    colorTextHeading: '#2481cc',
                    colorTextDescription: '#000',
                },
            }}
        >
            <>
                <Container>
                    {/*<script async src="https://telegram.org/js/telegram-widget.js?22" data-telegram-post="fcrodinachat/36685"></script>
                    <script async src="https://telegram.org/js/telegram-widget.js?22" data-telegram-post="fcrodinachat/35832"></script>
                    <script async src="https://telegram.org/js/telegram-widget.js?22" data-telegram-post="fcrodinachat/34486"></script>
                    <script async src="https://telegram.org/js/telegram-widget.js?22" data-telegram-post="fcrodinachat/34099"></script>
                    <script async src="https://telegram.org/js/telegram-widget.js?22" data-telegram-post="fcrodinachat/33766"></script>
                    <TgPostFeedbackItem postId="36685" />
                    <TgPostFeedbackItem postId="35832" />
                    <TgPostFeedbackItem postId="34486" />
                    <TgPostFeedbackItem postId="34099" />
                    <TgPostFeedbackItem postId="33766" />*/}
                    <ItemsList items={topItems} />
                </Container>
                {!!otherItems?.length && <CollapsePanel>
                    <Container>
                        <ItemsList items={otherItems} />
                    </Container>
                </CollapsePanel>}
            </>
        </ConfigProvider>
    );
}
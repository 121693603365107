import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 20px;
`;

export const PartnersGroup = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 8px;
`;

interface PartnerProps {
    $disabled: boolean;
}

export const Partner = styled.div<PartnerProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0.4;
    cursor: ${({ $disabled }) => $disabled ? 'unset' : 'pointer'};
    transition: opacity 0.3s;
    min-width: 120px;
    height: 50px;
    &:hover {
        opacity: 1;
    }

    @media(max-width: 500px) {
        opacity: 1;
    }
`;

export const PartnerLogo = styled.img`
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
`;
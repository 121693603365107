import styled from "styled-components";
import bg from '../../../assets/club100/bg.jpg';
import cardBg from '../../../assets/club100/cardBg.jpg';
import logo from '../../../assets/club100/logo.png';

export const Container = styled.div`
    background-image: url(${bg});
    background-repeat: repeat;
    background-position: top left;
    background-size: 512px;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 32px 32px 0;
    font-family: 'Futura', sans-serif;
    box-sizing: border-box;
    gap: 32px;
    @media (max-width: 1024px) {
        flex-direction: column;
        align-items: center;
        padding-bottom: 32px;
    }
`;

export const LeftSideContent = styled.div`
    width: 300px;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    box-sizing: border-box;
`;

export const Logo = styled.div`
    width: 230px;
    height: 230px;
    background-image: url(${logo});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 32px;
`;

export const MemeberListContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    gap: 6px;
`;

export const MembersList = styled.div`
    box-sizing: border-box;
    width: 100%;
    min-height: 60px;
    background-color: rgba(7, 42, 80, 0.65);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    padding: 20px;
    &:hover {
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.75);
    }
`;

export const MemeberElement = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    text-transform: uppercase;
`;

export const MemberListShowAll = styled.div`
    font-size: 12px;
    font-weight: medium;
    text-transform: uppercase;
    align-self: flex-end;
`;

export const YudaImage = styled.img`
    width: 100%;
`;

export const CandidateListTitle = styled.div`
    font-size: 32px;
    font-weight: bold;
    font-style: italic;
    padding: 20px;
    text-transform: uppercase;
`;

export const CandidatesList = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
    width: 100%;
    max-width: 700px;
    box-sizing: border-box;
`;

export const CandidateCardContent = styled.a`
    width: 100%;
    height: auto;
    min-height: 50px;
    background-image: url(${cardBg});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    color: white;
    text-transform: uppercase;
    text-decoration: none;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    &:hover {
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.75);
        text-decoration: none;
    }
`;

export const TextsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    padding-left: 32px;
`;

export const NameText = styled.div`
    font-size: 30px;
    font-weight: bold;
    font-style: italic;
    max-width: 150px;
    line-height: 1.1;
`;

export const MatchesText = styled.div`
    font-size: 18px;
`;

export const PhotoContainer = styled.div`
    width: 220px;
    height: 150px;
    overflow: hidden;
`;

export const Photo = styled.img`
    width: 100%;
`;
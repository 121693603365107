import { FC } from "react";

interface SmiLinkCardProps {
    url: string;
    imageUrl: string;
    title: string;
}

export const SmiLinkCard: FC<SmiLinkCardProps> = ({
    url,
    imageUrl,
    title
}) => {
    return (
        <a href={url} className="w-full sm:w-80 rounded overflow-hidden hover:shadow-lg opacity-95 hover:opacity-100" target="_blank" rel="noreferrer">
            <img src={imageUrl} alt={title} className="w-full h-full hover:shadow-lg" />
        </a>
    );
}
import { FC, useEffect } from "react";

interface TgPostFeedbackItemProps {
    postId: string;
}
export const TgPostFeedbackItem: FC<TgPostFeedbackItemProps> = ({
    postId,
}) => {
    useEffect(() => {
        const container = document.getElementById(`post-${postId}`);

        if (!container) {
            return;
        }

        const script = document.createElement("script");
        script.src = "https://telegram.org/js/telegram-widget.js?22";
        script.async = true;
        script.setAttribute('data-telegram-post', `${postId}`);
        container.appendChild(script);

        return () => {
            container.removeChild(script);
        };
    }, []);

    return <div id={`post-${postId}`} className="max-w-[500px] min-w-[400px]"></div>; // A container for the form
};
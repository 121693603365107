import Contests from "../pages/my/Contests/page";
import MatchdayPage from "../pages/my/Matchday/page";
import { MyTDesignPage } from "../pages/my/TDesign/page";
import { MatchdayActivitiesWidget } from "../widgets/my/matchday/MatchdayActivities";
import { MatcdayFeedbackWidget } from "../widgets/my/matchday/MatchdayFeedback";
import { MatchdayConfig } from "../types/matchday";
import { getMatchdayConfig } from "../api/transport/matchdayTransport";
import { useEffect, useState } from "react";
import MyAppPage from "../pages/my/MyApp/page";

export const MyContestsPageApp = () => {
    return (
        <Contests />
    );
}

export const MyMatchdayPageApp = () => {
    return (
        <MatchdayPage />
    );
}

export const MyMatchdayLandingActivitiesApp = () => {
    const [config, setConfig] = useState<MatchdayConfig>({});
    useEffect(() => {
        getMatchdayConfig().then((data) => {
            setConfig(data);
        });
    }, []);

    if (!config?.landingEnabled) {
        return null;
    }

    return (
        <>
            <div style={{ height: 32 }} />
            <MatchdayActivitiesWidget buyButton />
        </>
    );
}

export const MyMatchdayLandingFeedbackApp = () => {
    return (
        <MatcdayFeedbackWidget />
    );
}

export const MyTDesignPageApp = () => {
    return (
        <MyTDesignPage />
    );
}

export const MyAppPageApp = () => {
    return (
        <MyAppPage />
    );
}

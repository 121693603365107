import { Alert } from "antd";
import { TournamentTableDto } from "../../types/stats";
import { FCRodinaTable } from "../Table";
import { TableTeamTitle } from "../Table/TeamTitle";
import { ExtraContainer, Title } from "./styled";
import { getStaticUrl } from "../../utils";

interface TournamentTableProps {
    data: TournamentTableDto;
    title?: string;
    extra?: Record<number, string>;
}

export const TournamentTable: React.FC<TournamentTableProps> = ({ data, title, extra }) => {
    const dataColumns = [
        { title: 'Место', dataIndex: 'position', key: 'position' },
        { title: 'Команда', dataIndex: 'teamName', key: 'teamName', left: true },
        { title: 'И', dataIndex: 'matchesPlayed', key: 'matchesPlayed' },
        { title: 'В', dataIndex: 'wins', key: 'wins' },
        { title: 'Н', dataIndex: 'draws', key: 'draws' },
        { title: 'П', dataIndex: 'losses', key: 'losses' },
        { title: 'ГЗ', dataIndex: 'goalsFor', key: 'goalsFor' },
        { title: 'ГП', dataIndex: 'goalsAgainst', key: 'goalsAgainst' },
        { title: 'О', dataIndex: 'points', key: 'points' }
    ];

    let extraCount = 0;
    const dataItems = data.map((item) => {
        return dataColumns.map((column) => {
            const value = item[column.dataIndex];
            if (column.dataIndex === 'teamName') {
                return {
                    key: column.key,
                    value: (
                        <TableTeamTitle logoUrl={getStaticUrl(item.teamLogo)} name={value} />
                    )
                }
            }
            if (column.dataIndex === 'points' && extra?.[item.position]) {
                extraCount++;
                let val = value + ' ';
                for (let i = 0; i < extraCount; i++) {
                    val += '*';
                }
                return {
                    key: column.key,
                    value: val
                }
            }
            return {
                key: column.key,
                value
            }
        });
    });

    let extraText = [];

    if (extra) {
        extraText = Object.values(extra).map((value, index) => {
            let text = '';
            for (let i = 0; i < index + 1; i++) {
                text += '*';
            }
            return <p key={index}>{text + ' - ' + value}</p>;
        });
    }

    return (
        <>
            {title && <Title>{title}</Title>}
            <FCRodinaTable
                columns={dataColumns}
                data={dataItems}
            />
            {extraText?.length > 0 && <ExtraContainer>
                <Alert message={extraText} type="info" />
            </ExtraContainer >}
        </>
    );
}


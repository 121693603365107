import styled from 'styled-components';
import { Segmented as AntSegmented } from 'antd';

export const PlayersListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: relative;
`;

export const Filter = styled.div`
    font-family: 'Roboto', sans-serif;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    @media (max-width: 500px) {
        flex-direction: column;
        gap: 4px;
    }
`;

export const StatsInfo = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: column;
    font-family: 'Bebas Neue', sans-serif;
    color: #fff;
    position: absolute;
    top: 8px;
    right: 8px;
    gap: 4px;
    font-size: 20px;
    z-index: 2;
    background: rgba(0, 0, 0, 0.2);
    padding: 8px 12px;
    border-radius: 4px;
    transition: background 0.5s;
    &:hover {
        background: rgba(0, 0, 0, 0.5);
    }
`;

export const StatsInfoItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    text-shadow: 0 1px 2px rgba(0,0,0,0.7);
`;

export const StatsInfoItemText = styled.div`
    padding-top: 4px;
    width: 22px;
    text-align: right;
`;

export const StatsInfoItemIcon = styled.img`
    width: 20px;
    height: 20px;
    object-fit: contain;
`;

export const Segmented = styled(AntSegmented)`
    font-family: 'Roboto', sans-serif;
    border-radius: 4px !important;
`;
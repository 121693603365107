import styled from 'styled-components';
import { FCRodinaButton } from '../../../components/Button';

export const Container = styled.div`
    color: #133d5e;
`;

export const DescriptionTitle = styled.h2`
   color: #133d5e;
   text-align: center;
`;

export const DescriptionContent = styled.div`
    max-width: 900px;
    margin: 24px auto;
    font-size: 16px;
`;

export const Rules = styled.div`
    padding-left: 24px;
`;

export const ConstructorFrame = styled.iframe`
    margin: 24px auto;
    width: 100%;
    height: 600px;
    position: relative;
    border: none;
`;

export const OrderButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 0 auto 32px;
    gap: 16px;
`;

export const OrderButton = styled(FCRodinaButton)`
    width: 300px;
    @media (max-width: 450px) {
        width: 100%;
    }
`;

import { RightCircleOutlined } from "@ant-design/icons";
import { FC } from "react"
import { SocialShare } from "../../social/SocialShare";


interface FeaturedCardProps {
    url: string;
    mediaUrl: string;
    title: string;
    description: string;
}

export const FeaturedCard: FC<FeaturedCardProps> = ({
    url,
    mediaUrl,
    title,
    description,
}) => {
    return (
        <div className="w-full aspect-[12/5] relative bg-sky-900">
            <div onClick={() => globalThis.location.assign(url)} title={title} className="cursor-pointer h-full">
                <img src={mediaUrl} alt={title} className="object-cover w-full aspect-[12/5] pointer-events-none" />
                <div className="absolute rounded-sm text-black top-8 right-16 bg-slate-200/95 p-8 max-w-full w-full sm:max-w-[400px] sm:w-[400px] box-border z-10">
                    <h2>{title}</h2>
                    <p>{description}</p>
                    <div className="flex flex-row justify-between">
                        <span className="text-lg text-blue-500 hover:text-blue-400" title={title}><RightCircleOutlined /> Читать</span>
                        <div className="flex flex-col gap-2">
                            Рассказать друзьям:
                            <SocialShare
                                title={title}
                                description={description}
                                imageUrl={mediaUrl}
                                url={url}
                                keys={['vk', 'telegram', 'whatsapp', 'twitter']}
                                iconClassName="!w-9 !h-9"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

import { FC, useState } from "react"
import { TeamContext } from "../../../types/common";
import { StatsPage } from "../page";
import { StatsType, TournamentUuids } from "../../../types/stats";
import { PlayersStatsWidget } from "../../../widgets/stats/players/PlayersStatsWidget";

interface PlayersStatsPageProps {
    teamContext?: TeamContext;
    teamId?: number;
}

export const PlayersStatsPage: FC<PlayersStatsPageProps> = ({
    teamContext,
    teamId,
}) => {
    const [tournamentUuids, setTournamentUuids] = useState<TournamentUuids>();
    return (
        <StatsPage
            teamContext={teamContext}
            pageType={StatsType.PLAYER}
            onTournamentChange={(tournamentUuid, seasonUuid) => setTournamentUuids({
                tournamentUuid, seasonUuid
            })}
        >
            {tournamentUuids?.tournamentUuid && tournamentUuids?.seasonUuid && (
                <PlayersStatsWidget
                    teamContext={teamContext}
                    seasonUuid={tournamentUuids?.seasonUuid}
                    tournamentUuid={tournamentUuids?.tournamentUuid}
                    teamId={teamId}
                />
            )}
        </StatsPage>
    );
}
import { FC } from "react";
import { VacanciesList } from "./VacanciesList";

export const VacanciesListPage: FC = () => {
    return (
        <>
            <VacanciesList />
        </>
    )
}

export default VacanciesListPage;
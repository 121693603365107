import { FC, useEffect } from "react";
import { getQticketsUrl } from "../../utils";
import { Icon, IconType } from "../Icon";
import { Button } from "./styled";

interface BuyTicketButtonProps {
    url: string;
    color?: 'green' | 'default';
    icon?: boolean;
    size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl';
    className?: string;
    provider?: 'qtickets' | 'ticketland';
    title?: string;
}

export const BuyTicketButton: FC<BuyTicketButtonProps> = ({
    url,
    icon,
    className,
    color = 'default',
    size = 'md',
    provider='qtickets',
    title = 'Билеты'
 }) => {
    useEffect(() => {
        if (!url) {
            return;
        }

        if (provider === 'qtickets') {
            setTimeout(() => {
                window.qTickets.init();
            }, 1000);
        }
    }, [provider, url]);

    if (!url) {
        return null;
    }

    const href = getQticketsUrl(url);
    const colorClass = color === 'green' ? 'bg-green-600 hover:bg-green-500' : 'bg-sky-950 hover:bg-sky-900';
    let sizeClass = '';
    let iconSize = 20;
    let spanPadding = 'pt-1';
    if (size === 'sm') {
        sizeClass = 'py-1 px-2';
        iconSize = 20;
    } else if (size === 'md') {
        sizeClass = 'py-2 px-4';
        iconSize = 22;
    } else if (size === 'lg') {
        sizeClass = 'py-3 px-6 text-lg';
        iconSize = 24;
    } else if (size === 'xl') {
        sizeClass = 'py-3 px-6 text-xl';
        iconSize = 26;
    } else if (size === '2xl') {
        sizeClass = 'py-4 px-8 text-2xl uppercase font-bold';
        iconSize = 28;
    } else if (size === '3xl') {
        sizeClass = 'py-4 px-8 text-3xl uppercase font-bold';
        iconSize = 32;
        spanPadding = 'pt-2';
    }

    return (
        <Button
            className={`flex flex-row gap-2 w-fit items-center justify-center mb-1 transition-all hover:scale-105 rounded-sm text-white ${colorClass} hover:-translate-y-px hover:no-underline hover:text-white hover:shadow ${sizeClass} ${className}`}
            href={href}
        >
            {!!icon && <Icon type={IconType.Ticket} size={iconSize}/>}
            <span className={spanPadding}>
                {title}
            </span>
        </Button>
    );
}
import styled from "styled-components";

export const CarouselContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    padding-left: 24px;
    box-sizing: border-box;
`;

export const ElementWrapper = styled.div`
    font-family: 'Bebas Neue',sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 130px;
    cursor: grab;
    color: #f0f0f0;
    padding-left: 24px;
    box-sizing: border-box;
`;

export const ResultContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
`;

export const Score = styled.div`
    margin: 8px;
    cursor: pointer;
`;

export const ScoreInner = styled.div`
    font-size: 18px;
    font-weight: 600;
    border-radius: 4px;
    padding: 3px 0 0;
    background-color: #f0f0f0;
    line-height: 1.2;
    color: #133D5E;
    text-align: center;
    min-width: 40px;
`;

export const TeamInfo = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    max-width: 120px;
`;

export const TeamName = styled.div`
    font-size: 16px;
    font-weight: 500;
    line-height: 1.2;
    padding: 3px 0 0;
`;

export const TeamLogo = styled.img`
    width: 40px;
    height: 40px;
    object-fit: contain;
    margin-right: 6px;
`;

export const EventDate = styled.div`
    font-size: 16px;
    font-weight: 600;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;
`;

export const EventName = styled.div`
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
`;

export const NavigationButtons = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #f0f0f0;
    font-size: 24px;
    top: 0;
    bottom: 0;
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 0.2s;
    &:hover {
        opacity: 1;
    }
`;

export const NextButton = styled(NavigationButtons)`
    right: 12px;
`;

export const PrevButton = styled(NavigationButtons)`
    left: 12px;
`;

import { FC } from "react";

interface RecordCardProps {
    title: string;
    text: string;
    linkUrl?: string;
}

export const RecordCard: FC<RecordCardProps> = ({ title, text, linkUrl }) => {
    return (
        <a href={linkUrl ? linkUrl : window.location.href} className="hover:no-underline">
            <div className="p-6 h-40 bg-white rounded-lg cursor-pointer border border-solid border-gray-200 hover:shadow-md text-slate-950 no-underline flex flex-col gap-4">
                <div className="font-bold text-2xl text-center">{title}</div>
                <div className="text-xl flex justify-center items-center h-full text-center">{text}</div>
            </div>
        </a>
    );
}
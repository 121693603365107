import { FC } from "react";
import { TopPanel } from "../../../components/layout/TopPanel";
import { ClubLinks } from "./ClubLinks";
import { SocialLinksList } from "../../../widgets/social/SocialLinks";
import { useConfiguration } from "../../../hooks/useConfiguration";
import { TeamContext } from "../../../types/common";
import { Search } from "./Search";

export const Top: FC = () => {
    const config = useConfiguration();

    let additionalLinks = null;
    if (config.teamName === TeamContext.ACADEMY) {
        additionalLinks = (
            <div className="flex justify-center items-center space-x-4 transition-all px-3 py-1 rounded hover:bg-sky-950">
                <img src="https://academy.fcrodina.com/i/kodeks.png" alt="Кодекс" className="h-7 w-7" />
                <a href="/codex/" className="text-white text-lg hover:no-underline">Кодекс</a>
            </div>
        );
    }

    return (
        <TopPanel
            clubLinks={<ClubLinks teamName={config.teamName} />}
            //socialLinks={<SocialLinksList teamContext={config.teamName} color="white" className="!gap-3 ml-3" iconClassName="!h-10 !w-10" />}
            rightSideElements={<SocialLinksList teamContext={config.teamName} color="white" className="!gap-3 ml-3" iconClassName="!h-10 !w-10" />}
            //additionalLinks={additionalLinks}
            //rightSideElements={<Search />}
        />
    );
}
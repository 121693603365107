import { ContactUsButton } from "../../../components/ContactUsButton";
import { useConfiguration } from "../../../hooks/useConfiguration"
import { TeamContext } from "../../../types/common";

export const ContactUsWidget = () => {
    const config = useConfiguration();

    if (config.teamName !== TeamContext.RODINA) {
        return null;
    }

    return (
        <ContactUsButton phoneNumber="+79032801727">
            Появился вопрос - спрашивай :)
        </ContactUsButton>
    );
}
import { FC } from 'react';
import { Video } from '../Video';
import { PageHeadImage } from './styled';
import { getMediaTypeByUrl, getStaticUrl } from '../../utils';
import { MediaType } from '../../types/common';

interface PageHeadProps {
    mediaUrl?: string;
    alt?: string;
}

export const PageHeadMedia: FC<PageHeadProps> = ({ mediaUrl, alt }) => {
    if (!mediaUrl) {
        return null;
    }

    const mediaType = getMediaTypeByUrl(mediaUrl);

    if (mediaType !== MediaType.IMAGE && mediaType !== MediaType.UNKNOWN) {
        return (
            <Video
                src={mediaUrl}
                type={mediaType}
                title={alt || 'Видео'}
            />
        );
    }

    return (
        <PageHeadImage
            src={getStaticUrl(mediaUrl)}
            alt={alt || 'Изображение'}
        />
    );
}

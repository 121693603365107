import { FC } from "react";
import { ActivityButtons } from "./styled";
import { BuyTicketButton } from "../../../../components/tickets/BuyTicketButton";

interface ItemActivityButtonsProps {
    buyTicketUrl?: string;
    children?: React.ReactNode;
}

export const ItemActivityButtons: FC<ItemActivityButtonsProps> = ({
    buyTicketUrl = 'https://fcrodina.com/ticket-id9/',
    children,
}) => {
    return (
        <ActivityButtons>
            <BuyTicketButton url={buyTicketUrl} icon size="2xl" title="Купить билет"/>
            {children}
        </ActivityButtons>
    );
}
import styled from "styled-components";

export const HeadTitleContainer = styled.div`
    position: absolute;
    left: 0;
    bottom: 16px;
    right: 0;
    text-align: center;
`;

export const HeadAdditionalInfo = styled.div`
    color: #fff;
    font-size: 32px;
    font-weight: 700;
    font-family: 'Bebas Neue', sans-serif;
    margin-bottom: 1rem;
    text-shadow: 0 3px 4px rgba(0, 0, 0, 1);
    display: flex;
    justify-content: space-between;
    padding: 0 32px;
`;

export const SectionTitle = styled.h3`
    font-size: 18px;
    text-align: center;
    color: #02142E;
    width: 100%;
`;

export const RulesTitle = styled.h2`
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    color: #02142E;
    width: 100%;
    margin: 32px auto;
    max-width: 860px;
`;

export const RulesDescription = styled.p`
    font-size: 16px;
    margin-bottom: 1rem;
    text-align: justify;
    color: #02142E;
`;

export const RulesGroups = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 24px;
    margin-bottom: 2rem;
    box-sizing: border-box;

    @media (max-width: 500px) {
        flex-direction: column;
    }
`;

export const RulesGroup = styled.div`
    padding: 16px 20px 72px;
    border-radius: 4px;
    box-sizing: border-box;
    max-width: 450px;
    width: 100%;
    &:hover {
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    }
`;

export const RulesGroupTitle = styled.div`
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 1rem;
    color: #02142E;
    text-align: center;
`;

export const RulesGroupGood = styled(RulesGroup)`
    background-color: #E5F9E0;
`;

export const RulesGroupBad = styled(RulesGroup)`
    background-color: #FDE7E7;
`;

export const RulesGroupTitleGood = styled(RulesGroupTitle)`
    color: #4CAF50;
`;

export const RulesGroupTitleBad = styled(RulesGroupTitle)`
    color: #F44336;
`;

export const RulesList = styled.ul`
    padding-left: 20px;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    height: 100%;
`;

export const GoodRule = styled.li`
`;

export const BadRule = styled.li`
`;

export const DescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    margin-bottom: 32px;
`;

export const DiscountContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 16px;
    font-family: 'Bebas Neue', sans-serif;
`;

export const Discount = styled.div`
    font-size: 42px;
    font-weight: 700;
    padding: 16px 16px 8px;
    text-align: center;
    color: #02142E;
    background-color: #99c6e1;
    border-radius: 8px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
`;

export const DescriptionText = styled.div`
    font-size: 32px;
    font-weight: 600;
    text-align: center;
    color: #02142E;
    padding-top: 6px;
    font-family: 'Bebas Neue', sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 6px;
`;

export const DescriptionTitle = styled(DescriptionText)`
    font-size: 42px;
    font-weight: 700;
`;

export const ParametersContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    gap: 8px;
    font-family: 'Bebas Neue', sans-serif;
    margin-bottom: 32px;

    @media (max-width: 500px) {
        flex-direction: column;
    }
`;

export const Parameter = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    font-size: 32px;
    font-weight: 700;
    text-align: center;
    color: #02142E;
    border-radius: 8px;
    padding: 16px;
    max-width: 255px;
    &:hover {
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    }
    box-sizing: border-box;
`;

export const ParameterIcon = styled.img`
    width: 75px;
    color: #02142E;
`;

export const Contacts = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 64px;
    @media (min-width: 500px) {
        flex-direction: row;
    }
`;

export const ContactsItem = styled.a`
    text-decoration: none;
    font-size: 32px;
    font-weight: 600;
    text-align: center;
    color: #02142E;
    padding-top: 6px;
    font-family: 'Bebas Neue', sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 6px;
    transition: all 0.2s;
    &:hover {
        transform: scale(1.02) translateY(-2px);
        color: #0079ba;
        text-decoration: none;
    }
`;

interface ContactsIconProps {
    $color?: string;
}

export const ContactsIcon = styled.div<ContactsIconProps>`
    font-size: 42px;
    color: ${props => props.$color || 'inherit'};
    margin-bottom: 4px;
`;

export const PromoCode = styled.span`
    color: #0079ba;
    cursor: pointer;
    transition: all 0.2s;
    &:hover {
        transform: scale(1.02) translateY(-2px) translateX(2px);
    }
`;
import { useCallback, useEffect, useState } from "react";
import { FCRodinaButton } from "../../components/Button";
import {
    Container,
    Title,
    SubTitle,
    PreviewImageContainer,
    PhotoIdInput,
    Content,
    Header,
    ShareContainer,
    ShareTitle,
    ButtonContainer,
    LinkButton
} from "./styled";
import { Form, Image, Result, message } from "antd";
import { getCosmosPhoto } from "../../api/transport/promoTransport";
import { SocialShare } from "../../widgets/social/SocialShare";
import { SocialLinksList } from "../../widgets/social/SocialLinks";
import { base64ImageToBlob } from "../../utils";

interface CosmosPromoFormValues {
    photoId: string;
}

export const CosmosPromoPage = () => {
    const [photoUrl, setPhoto] = useState<string>("");
    const [shared, setShared] = useState<boolean>(false);
    const [form] = Form.useForm<CosmosPromoFormValues>();
    const [file, setFile] = useState<File | null>(null);
    const [shareUrl, setShareUrl] = useState<string>('');
    const [photoId, setPhotoId] = useState<number>(0);

    const handleReset = () => {
        form.resetFields();
        setPhoto('');
    }

    const onFinish = (values: CosmosPromoFormValues) => {
        getCosmosPhoto(values.photoId).then((response) => {
            setPhotoId(parseInt(values.photoId));
            setPhoto(response.photoUrl.replace('.com//', '.com/'));
        }).catch((error) => {
            console.error(error);
            message.error('Фото c таким номером не найдено');
        });
    }

    const download = useCallback(() => {
        /*let element = document.createElement('a');
        window.URL = window.URL || window.webkitURL;
        element.setAttribute('href', window.URL.createObjectURL(file));
        element.setAttribute('download', file.name);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);*/
    }, [file]);

    const fetchFile = async (url: string) => {
        if (!url) {
            return;
        }
        const response = await fetch('https://fcrodina.com/utils/image.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: `url=${encodeURIComponent(url)}`,
        });
        const responseJson = await response.json();
        if (response.status !== 200) {
            console.error(responseJson);
            return;
        }
        const blob = base64ImageToBlob(responseJson.url);
        const file = new File([blob], 'cosmos.jpg', { type: blob.type });
        setFile(file);
    }

    useEffect(() => {
        if (!photoUrl) {
            return;
        }
        fetchFile(photoUrl);
        setShareUrl(`https://fcrodina.com/cosmos/${photoId}/`);
    }, [photoId, photoUrl]);


    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const id = searchParams.get('photoId');
        if (id) {
            form.setFieldValue('photoId', id);
        }
    }, [form]);

    const onShare = useCallback(async () => {
        if (navigator.share) {
            navigator.share({
                title: '#ЯвКосмосе',
                text: '#ЯвКосмосе на матче Родина - Ленинградец',
                files: [file],
            })
                .then(() => setShared(true))
                .catch((error) => message.error('Ошибка при попытке поделиться:' + error.message));
        }
    }, [file]);

    let content = null;
    let shareLinks = null;

    if (photoUrl) {
        if (!navigator.share || true) {
            shareLinks = (
                <ShareContainer>
                    <ShareTitle>Поделиться:</ShareTitle>
                    <SocialShare
                        title='#ЯвКосмосе'
                        description='#ЯвКосмосе на матче Родина - Ленинградец'
                        imageUrl={photoUrl}
                        url={shareUrl}
                        file={file}
                        onShareCallback={() => setShared(true)}
                    />
                </ShareContainer>
            );
        } else {
            shareLinks = (
                <ShareContainer>
                    <FCRodinaButton size="large" onClick={onShare}>Поделиться</FCRodinaButton>
                </ShareContainer>
            );
        }
    }

if (shared) {
    content = (
        <Result
            status="success"
            title="Спасибо!"
            subTitle={<>Подписывайся на нас в соцсетях<br /><SocialLinksList /></>}
            extra={[
                <ButtonContainer key="ok_button">
                    <FCRodinaButton size="large" onClick={() => setShared(false)}>
                        Хорошо
                    </FCRodinaButton>
                </ButtonContainer>
            ]}
        />
    );
} else {
    content = (
        <>
            {!photoUrl && <Form form={form} onFinish={onFinish}>
                <Form.Item
                    name="photoId"
                    required
                    hasFeedback
                    rules={
                        [
                            {
                                required: true,
                                message: 'Пожалуйста, введите номер фото',
                            },
                            {
                                pattern: /^[0-9]+$/,
                                message: 'Номер фото должен содержать только цифры',
                            },
                        ]
                    }
                >
                    <PhotoIdInput placeholder="Введите номер фото" size="large" disabled={!!photoUrl} />
                </Form.Item>
            </Form>}
            {shareLinks}
            {photoUrl &&
                <>
                    <PreviewImageContainer>
                        <Image src={photoUrl} alt="#ЯвКосмосе" preview />
                    </PreviewImageContainer>
                    <LinkButton type="link" size="large" onClick={handleReset}>Получить ещё одно</LinkButton>
                </>
            }
            {photoUrl && false && <FCRodinaButton size="large" onClick={() => download()}>
                Скачать фото
            </FCRodinaButton>}
            {!photoUrl && <FCRodinaButton size="large" onClick={() => form.submit()}>Получить фото</FCRodinaButton>}
        </>
    );
}

return (
    <Container>
        <Content>
            <Header>
                <Title>#ЯвКосмосе</Title>
                <SubTitle>на матче Родина - Ленинградец</SubTitle>
            </Header>
            {content}
        </Content>
    </Container>
);
}

import { FC, useEffect } from "react";
import styled from "styled-components";
import bg from '../../assets/my/app-page-bg.png';
import { setBgImage } from "../../utils";
import { PageContent } from "./styled";

const Wrapper = styled.div`
    font-family: 'Futura', sans-serif;
`;

interface PageTemplateProps {
    children: React.ReactNode;
}

const PageTemplate: FC<PageTemplateProps> = ({
    children
}) => {
    useEffect(() => {
        setBgImage(bg);
    }, []);

    return (
        <Wrapper>
            <PageContent className="text-xl">
                {children}
            </PageContent>
        </Wrapper>
    )
}

export default PageTemplate;

import { MouseEventHandler } from 'react';
import { PersonCard } from '../../PersonCard';
import { PlayerNumber, PlayerNumberDigit } from './styled';

interface PlayerCardProps {
    name: string;
    number: string;
    photo?: string;
    onClick?: MouseEventHandler<HTMLAnchorElement>;
    children?: React.ReactNode;
    link?: string;
    title?: string;
    birthDate?: string;
}

export const PlayerCard: React.FC<PlayerCardProps> = ({
    name,
    number,
    photo,
    onClick,
    children,
    link,
    title,
    birthDate,
}) => {
    return (
        <PersonCard
            onClick={onClick}
            name={name}
            photo={photo}
            title={title}
            number={number || ''}
            link={link}
            birthDate={birthDate}
        >
            {number && <PlayerNumber>{number.split('').map((n,i) => {
                return <PlayerNumberDigit key={i}>{n}</PlayerNumberDigit>
            })}</PlayerNumber>}
            {children}
        </PersonCard>
    );
}
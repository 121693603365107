import { FC } from "react"
import { useConfiguration } from "../../hooks/useConfiguration";
import { PartnersWidget } from "./Partners";
import { SiteMap } from "../../layout/legacy/sitemap";

export const LegacyDesktopFooter: FC = () => {
    const config = useConfiguration();
    const hidePartners = false;
    return (
        <div className="bg-slate-900 pb-5">
            <div className="mx-auto px-10 py-5">
                {!hidePartners && <PartnersWidget teamName={config.teamName} />}
            </div>
            <div className="max-w-[1008px] py-5 mx-auto">
                <SiteMap teamContext={config.teamName} />
            </div>
        </div>
    );
}
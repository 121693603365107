import styled from "styled-components";
import { FCRodinaButton } from "../../../../components/Button";

export const ExtraContainer = styled.div`
    position: absolute;
    top: -20px;
    left: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 12px;
    z-index: 1;
`;

export const Icon = styled.img`
    width: 25px;
    height: 25px;
    object-fit: contain;
`;

export const CitiesPromoButtons = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
`;

export const ContestButton = styled(FCRodinaButton)`
    margin-right: 12px;
    &:last-child {
        margin-right: 0;
    }
`;

import { FC, useEffect, useState } from "react";
import { TournamentSelector } from "../../../components/TournamentSelector";
import { getPlayerSelectors } from "../../../api/transport/statsTransport";
import { StatsTournamentSelectorsDto } from "../../../types/stats";
import { TeamContext } from "../../../types/common";

interface PlayerTournamentSelectorWidgetProps {
    playerId: string;
    teamContext?: TeamContext;
    onTournamentChange?: (tournamentUuid: string, seasonUuid: string) => void;
}

export const PlayerTournamentSelectorWidget: FC<PlayerTournamentSelectorWidgetProps> = ({
    playerId,
    teamContext,
    onTournamentChange
}) => {
    const [selectorOptions, setSelectorOptions] = useState<StatsTournamentSelectorsDto>();
    useEffect(() => {
        getPlayerSelectors(playerId, teamContext).then((data) => {
            setSelectorOptions(data);
        }).catch(() => {
            setSelectorOptions(undefined);
        });
    }, [playerId, teamContext]);
    return (
        <TournamentSelector
            selectorOptions={selectorOptions}
            additionalParams={`&gamer=${playerId}`}
            onSelect={onTournamentChange}
        />
    );
};

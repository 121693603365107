import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
    //min-height: 0;
    height: 0;
    width: 0;
  }
  to {
    opacity: 1;
   // min-height: 250px;
    height: 300px;
    width: 300px;
  }
`;

export const PersonCardImage = styled.img`
    height: 90%;
    object-fit: contain;
    transition: all 0.6s;
    pointer-events: none;
    z-index: 1;
    border-radius: 8px 8px 0 0;
`;

interface PersonCardContainerProps {
    $noLink?: boolean;
}

export const PersonCardContainer = styled.a<PersonCardContainerProps>`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 300px;
    height: 300px;
    background: radial-gradient(circle, #7A787D, #373737);
    //background: #fff;
    box-sizing: border-box;
    overflow: hidden;
    cursor: ${({ $noLink }) => $noLink ? 'default' : 'pointer'};
    //animation: ${fadeIn} 0.5s ease-in forwards;

    &:hover {
        ${PersonCardImage} {
            transform: translate(0, -10px) scale(1.1);
        }
    }
`;

export const PersonInfoContainer = styled.div`
    position: absolute;
    font-family: 'Bebas Neue', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    font-weight: 500;
    text-shadow: 0 1px 2px rgba(0,0,0,0.7);
    font-size: 22px;
    bottom: 0;
    left: 0;
    top: 0;
    right: 0;
    padding: 8px 16px;
    color: #fff;
    box-sizing: border-box;
    width: 100%;
    z-index: 2;
`;

export const PersonName = styled.p`
    margin: 0;
    font-size: 28px;
    max-width: 50%;
`;

export const PersonTitle = styled.p`
    margin: 0;
    font-size: 18px;
    font-weight: 400;
    max-width: 80%;
`;

export const PersonBirthDate = styled.div`
    position: absolute;
    top: 16px;
    left: 16px;
    font-size: 18px;
    font-weight: 400;
    vertical-align: bottom;
    display: flex;
    align-items: center;
    gap: 4px;
    text-shadow: 1px 1px 2px rgba(0,0,0,1);
    //padding-bottom: 3px;
    //border-top: 2px solid #B3DC87;
`;

export const PersonBirthDateText = styled.div`
    padding-top: 6px;
`;

export const Divider = styled.div`
    margin-top: 5px;
    margin-bottom: 8px;
    border-top: 2px solid #B3DC87;
    width: 35%;
`;

export const PersonList = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 12px 20px;
    margin: 8px 5px 24px;
    justify-content: flex-start;
    @media (max-width: 500px) {
        flex-direction: column;
        align-items: center;
    }
`;

export const PersonListTitle = styled.div`
    font-family: 'Bebas Neue', sans-serif;
    font-size: 28px;
    font-weight: 500;
    color: #133D5E;
    width: 100%;
    border-bottom: 1px solid #ddd;
    margin-top: 12px;
    margin-bottom: 12px;
`;
import { FC, useEffect, useState } from "react"
import { getSummaryTablesSelector } from "../../../api/transport/statsTransport"
import { ConfigProvider, Select } from "antd";
import { SummaryTableSelectorDto } from "../../../types/stats/summaryTables";

interface SummaryTableSelectorProps {
    tableId?: number;
    onSelect: (summaryTableId: number) => void;
}

export const SummaryTableSelector: FC<SummaryTableSelectorProps> = ({
    onSelect,
}) => {
    const [selectorData, setSelectorData] = useState<SummaryTableSelectorDto>();
    const [seasonsOptions, setSeasonsOptions] = useState<{ label: string, value: string }[]>([]);
    const [tableOptions, setTableOptions] = useState<{ label: string, value: number }[]>([]);
    const [selectedSeason, setSelectedSeason] = useState<string>();
    const [selectedTable, setSelectedTable] = useState<number>();

    useEffect(() => {
        getSummaryTablesSelector().then(data => {
            setSelectorData(data);
            const urlParams = new URLSearchParams(window.location.search);
            const urlTableId = urlParams.get('shows');
            const seasons = Object.keys(data);

            let seasonToSelect = seasons[0];
            if (urlTableId) {
                seasonToSelect = seasons.find(season => {
                    return !!data[season].find(table => {
                        return table.id === Number(urlTableId);
                    });
                })
            }

            setSeasonsOptions(Object.keys(data).map((season) => ({ label: season, value: season })));
            setSelectedSeason(seasonToSelect);

            const tables = data[seasonToSelect];
            setTableOptions(tables.map((table) => ({ label: table.name, value: table.id })));
            setSelectedTable(+urlTableId || tables[0].id);

            onSelect(+urlTableId || tables[0].id);
        });
    }, []);

    const onSelectSeason = (season: string) => {
        setSelectedSeason(season);
        const tables = selectorData[season];
        setTableOptions(tables.map((table) => ({ label: table.name, value: table.id })));
        setSelectedTable(tables[0].id);
        onSelect(tables[0].id);
    }

    const onSelectTable = (tableId: number) => {
        setSelectedTable(tableId);
        onSelect(tableId);
    }

    return (

        <div className="flex flew-row gap-2">
            <ConfigProvider
                theme={{
                    components: {
                        Select: {
                            selectorBg: '#E8F4FF',
                            borderRadius: 2,
                            borderRadiusLG: 2,
                        },
                    },
                }}
            >
                <Select
                    popupMatchSelectWidth={false}
                    size="large"
                    value={selectedSeason}
                    onChange={onSelectSeason}
                    options={seasonsOptions}
                />
                <Select
                    popupMatchSelectWidth={false}
                    size="large"
                    value={selectedTable}
                    onChange={onSelectTable}
                    options={tableOptions}
                />
            </ConfigProvider>
        </div>
    )
}

import { useConfiguration } from "../../hooks/useConfiguration";
import { TeamContext } from "../../types/common";
import { CalendarCarouselWidget } from "../../widgets/mainScreen/CalendarCarousel";
import { LeagueBannerWidget } from "../../widgets/mainScreen/LeagueBanner";
import { MainScreenStatsWigdet } from "../../widgets/mainScreen/MainScreenStats";
import { FeaturedNewsWidget } from "../../widgets/mainScreen/FeatureNews";

export const HomePage = () => {
    //const path = window.location.pathname;

    const config = useConfiguration();

    let seasonId = config.seasonId;
    if (config.teamName === TeamContext.RODINA) {
        seasonId = 18;
    } else if (config.teamName === TeamContext.ACADEMY) {
        seasonId = 17;
    } else if (config.teamName === TeamContext.RODINA_M) {
        seasonId = 17;
    }

    let statsFilters = config.mainScreenConfig?.filters;
    if (!statsFilters?.length) {
        statsFilters = [{
            tournamentId: config.tournamentId || 178,
            seasonId: config.seasonId || 18,
            teamId: config.teamId,
        }]
    }

    return (
        <div className="flex flex-col">
            <FeaturedNewsWidget teamContext={config.teamName} />
            <LeagueBannerWidget teamName={config.teamName} />
            <CalendarCarouselWidget
                seasonId={seasonId}
                teamId={config.teamId}
                teamName={config.teamName}
            />
            <MainScreenStatsWigdet
                statsFilters={statsFilters}
                topTeams={config.mainScreenConfig?.table.topTeams || 3}
                bottomTeams={config.mainScreenConfig?.table?.bottomTeams || 2}
                prevMatches={config.mainScreenConfig?.matches?.prevMatches || 2}
                teamName={config.teamName}
            />
        </div>
    );
}
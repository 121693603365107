import styled from "styled-components";

interface SocialLinksContainerProps {
    $isVertical?: boolean;
}

export const SocialLinksContainer = styled.div<SocialLinksContainerProps>`
    display: flex;
    flex-direction: ${({ $isVertical }) => $isVertical ? 'column' : 'row'};
    align-items: center;
    justify-content: center;
    gap: 8px;
`;

export const SocialLink = styled.a`
    transition: 0.3s;
    opacity: 0.8;
    color: #133D5E;
    &:hover {
        opacity: 1;
        transform: scale(1.05);
    }
`;

export const SocialIcon = styled.img`
    width: 48px;
    height: 48px;
    cursor: pointer;
`;

import { FC, ReactNode } from "react";
import { HeaderTitle } from "./styled";

interface HeaderProps {
    className?: string;
    clubTitle?: string;
    timeline?: ReactNode;
    onLogoClick?: () => void;
}

export const Header: FC<HeaderProps> = ({
    className = "",
    clubTitle,
    timeline,
    onLogoClick,
}) => {
    const classNames = "w-full bg-white/75 py-4" + (className ? ` ${className}` : "");
    return (
        <header className={classNames}>
            <div className="flex flex-row items-center justify-start gap-6 max-w-[980px] mx-auto">
                <div onClick={onLogoClick} className="transition-all hover:scale-105 cursor-pointer">
                    <img src="https://fcrodina.com/i/logo.png" alt="ФК Родина" className="h-32" />
                </div>
                <div>
                    <HeaderTitle className="text-5xl font-bold text-sky-900">{clubTitle}</HeaderTitle>
                    {timeline}
                </div>
            </div>
        </header>
    );
};
import { useEffect, useState } from "react";
import { RadioGroup } from "../../../components/RadioGroup";
import { Video } from "../../../components/Video";
import { MediaType } from "../../../types/common";
import { RodinaHistory } from "./Histrory";
import { Records } from "./records";
import { getHashParam, updateHashParams } from "../../../utils";
import styled from "styled-components";
import { ClubPage } from "./types";
import { getClubPageTitle } from "./utils";
import { CoachesPage } from "./coaches";

export const HeadingTitle = styled.div`
    font-family: 'Bebas Neue', sans-serif;
    font-size: 36px;
    font-weight: 500;
    color: #133D5E;
    width: 100%;
    border-bottom: 1px solid #ddd;
    margin-top: 12px;
    margin-bottom: 12px;
`;

const RodinaAboutClubPage = () => {
    const [currentPage, setCurrentPage] = useState<ClubPage>(ClubPage.History);
    const onPageChange = (key: ClubPage) => {
        setCurrentPage(key);
        updateHashParams({ clubPage: key });
    }

    useEffect(() => {
        const page = getHashParam('clubPage');
        if (page && page !== currentPage) {
            setCurrentPage(page as ClubPage);
        }
    }, [currentPage]);


    return (
        <div className="text-xl pb-8">
            <div>
                <p>
                    Футбольный клуб "Родина" - часть большого международного футбольного проекта, цель
                    которого - поиск и развитие молодых талантливых футболистов.
                </p>
                <p>
                    Создан на базе <a href="https://fcrodina.com/academy/">Академии "Родина"</a> и
                    лицензирован для выступления в Первенстве России по футболу среди команд клубов ПФЛ
                    (сейчас Вторая Лига) в 2019 году. В этом же сезоне команда дебютировала и в Кубке страны.
                </p>
                <p>
                    Профессиональный клуб в рамках своих долгосрочных целей ориентирован в первую очередь на
                    выпускников своей <a href="https://fcrodina.com/academy/">Академии</a>, для которых
                    открываются новые возможности продолжать развиваться и совершенствовать свое мастерство.
                </p>
            </div>
            <Video
                type={MediaType.MP4}
                src="https://fcrodina.com/uploads/matchday/videos/AmongUs.mp4"
                title="СредиСвоих"
                autoplay={false}
                cover="https://static.tildacdn.com/tild3934-3733-4136-b732-333133333665/image.png"
                controls
            />
            <div className="text-center p-4">
                <RadioGroup
                    options={[{
                        label: 'История',
                        key: ClubPage.History
                    },
                    {
                        label: 'Рекорды',
                        key: ClubPage.Records
                    },
                    {
                        label: 'Тренеры',
                        key: ClubPage.Coaches
                    }]}
                    value={currentPage}
                    onChange={onPageChange}
                    defaultValue={currentPage}
                    size="large"
                />
            </div>
            <HeadingTitle>
                {getClubPageTitle(currentPage)}
            </HeadingTitle>
            <div className="pt-4">
                {currentPage === ClubPage.History && <RodinaHistory />}
                {currentPage === ClubPage.Records && <Records />}
                {currentPage === ClubPage.Coaches && <CoachesPage />}
            </div>
        </div>
    );
}

export default RodinaAboutClubPage;
import { FC, ReactNode, useEffect, useState } from "react";
import { Container } from "./styled";
import { TimelineElement } from "./TimelineElement";

const getItemKey = (index: number, key?: string) => {
    if (key) {
        return key;
    }
    return `item${index}`;
};

export interface TimelineAccordionItem {
    key?: string;
    title: string;
    shortDescription: ReactNode;
    description: ReactNode;
    icon?: ReactNode;
    imageUrl?: string;
    extra?: ReactNode;
}

interface TimelineAccordionProps {
    name?: string;
    items: TimelineAccordionItem[];
    activeIndex?: number | null;
}

export const TimeLineAccordion: FC<TimelineAccordionProps> = ({ name, items, activeIndex = null }) => {
    const [selected, setSelected] = useState<number | null>(activeIndex);

    useEffect(() => {
        setSelected(activeIndex);
    }, [activeIndex]);

    useEffect(() => {
        const haskKey = globalThis.location.hash.slice(1);
        const index = items.findIndex((item, index) => getItemKey(index, item.key) === haskKey);
        //console.log('items', items);
        //console.log('index', index);
        //console.log('hashKey', haskKey);
        if (index !== -1) {
            setSelected(index);
        }
    }, [items]);

    const onSelect = (index: number | null) => {
        setSelected(index);
        if (index !== null) {
            globalThis.location.hash = getItemKey(index, items[index].key);
        } else {
            globalThis.location.hash = '';
        }
    }

    return (
        <Container>
            {name && <h1>{name}</h1>}
            {items.map((item, index) => (
                <TimelineElement
                    key={getItemKey(index, item.key)}
                    title={item.title}
                    shortDescription={item.shortDescription}
                    description={item.description}
                    icon={item.icon}
                    isLast={index === items.length - 1}
                    selected={selected === index}
                    onClick={() => onSelect(selected === index ? null : index)}
                    imageUrl={item.imageUrl}
                    extra={item.extra}
                />
            ))}
        </Container>
    );
}
import {
    BadRule,
    GoodRule,
    RulesGroupBad,
    RulesGroupGood,
    RulesGroups,
    RulesGroupTitleBad,
    RulesGroupTitleGood,
    RulesList,
    SectionTitle,
} from "./styled";

export const RulesTable = () => {
    return (
        <>
            <SectionTitle>
                Если на нескольких примерах, то:
            </SectionTitle>
            <RulesGroups>
                <RulesGroupGood>
                    <RulesGroupTitleGood>
                        Можно 👍
                    </RulesGroupTitleGood>
                    <RulesList>
                        <GoodRule>
                            Кричать что есть силы
                        </GoodRule>
                        <GoodRule>
                            Вскакивать с мест во время опасных моментов
                        </GoodRule>
                        <GoodRule>
                            Праздновать гол от души
                        </GoodRule>
                        <GoodRule>
                            Применять разрешенные средства поддержки
                        </GoodRule>
                        <GoodRule>
                            Принимать пищу на трибунах во время игры
                        </GoodRule>
                        <GoodRule>
                            Свободно перемещаться в рамках своего сектора
                        </GoodRule>
                        <GoodRule>
                            Общаться, делиться хорошим настроением
                        </GoodRule>
                    </RulesList>
                </RulesGroupGood>
                <RulesGroupBad>
                    <RulesGroupTitleBad>
                        Нельзя 👎
                    </RulesGroupTitleBad>
                    <RulesList>
                        <BadRule>
                            Оскорблять и призывать к насилию
                        </BadRule>
                        <BadRule>
                            Мешать просмотру другим зрителям
                        </BadRule>
                        <BadRule>
                            Портить имущество стадиона
                        </BadRule>
                        <BadRule>
                            Использовать запрещенную символику, зажигать фаеры и другую пиротехнику, бросать предметы на поле
                        </BadRule>
                        <BadRule>
                            Проносить на стадион еду и напитки, а также другие запрещенные к проносу на стадион предметы
                        </BadRule>
                        <BadRule>
                            Занимать чужие места на трибунах, переходить на другой сектор и уж тем более выбегать на поле
                        </BadRule>
                        <BadRule>
                            Вести пропаганду и незаконную торговлю
                        </BadRule>
                    </RulesList>
                </RulesGroupBad>
            </RulesGroups>
        </>
    );
}

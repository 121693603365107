import { FC } from 'react';
import { VideoFrame } from './styled';
import { MediaType } from '../../types/common';

interface VideoProps {
    type?: MediaType;
    title: string;
    src: string;
    cover?: string;
    autoplay?: boolean;
    controls?: boolean;
}

export const Video: FC<VideoProps> = ({
    src,
    cover,
    title = 'Родина-ТВ',
    type = 'youtube',
    autoplay = true,
    controls = false,
}) => {
    let url = '';

    let additionalParams = '';

    if (autoplay) {
        additionalParams += '&autoplay=1';
    }

    if (type === MediaType.MP4) {
        if (!src.endsWith('.mp4')) {
            throw new Error('MP4 video should have .mp4 extension');
        }
        return (
            <video width="100%" height="auto" className='aspect-video bg-slate-950 rounded' autoPlay={autoplay} muted={autoplay} loop poster={cover} controls={controls}>
                <source src={src} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        );
    } else if (type === MediaType.VK) {
        const splittedSrc = src?.split('/video');
        const videoIds = splittedSrc?.[1]?.split('_');
        if (!videoIds?.length || videoIds.length < 2) {
            console.error('Неправильный формат url', src);
            return null;
        }
        url = `https://vk.com/video_ext.php?oid=${videoIds[0]}&id=${videoIds[1]}${additionalParams}`;
    } else if (type === MediaType.YOUTUBE) {
        let videoId = src.split('https://www.youtube.com/embed/')[1];
        if (!videoId) {
            videoId = src.split('v=')[1];
        }
        url = `https://www.youtube.com/embed/${videoId}?playlist=${videoId}&loop=1&mute=1&rel=0${additionalParams}`;
    } else if (type === MediaType.RT) {
        let videoId: string = src.split('https://rutube.ru/video/')?.[1];
        if (!videoId) {
            videoId = src.split('https://rutube.ru/play/embed/')?.[1];
        }
        url = `https://rutube.ru/play/embed/${videoId}?skinColor=133D5E${additionalParams}`;
    }

    //&controls=0&disablekb=1&fs=0&showinfo=0
    return (
        <VideoFrame
            width="100%"
            height="auto"
            className='aspect-video bg-black rounded'
            src={url}
            title={title}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
        ></VideoFrame>
    );
}
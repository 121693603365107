import { ClubPage } from "./types";

export const getClubPageTitle = (page: ClubPage) => {
    switch (page) {
        case ClubPage.Records:
            return 'Рекорды';
        case ClubPage.History:
            return 'История';
        case ClubPage.Coaches:
            return 'Тренеры';
    }
}

import styled from "styled-components";
import { FCRodinaButton } from "../../../../components/Button";

export const ExtraContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 240px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    z-index: 1;
    gap: 12px;
    opacity: 0.2;
    transition: all 0.3s;
    border-radius: 4px;
    &:hover {
        background: rgba(0, 0, 0, 0.4);
        opacity: 1;
    }
`;

export const Icon = styled.img`
    width: 24px;
    height: 24px;
    object-fit: contain;
`;

export const ActivityButtons = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    margin-top: 20px;
    margin-bottom: 20px;
    @media (max-width: 500px) {
        flex-direction: column;
    }
`;

export const ActivityButton = styled.button`
    font-family: 'Bebas Neue', sans-serif;
    margin-right: 12px;
    &:last-child {
        margin-right: 0;
    }
    @media (max-width: 500px) {
        width: 100%;
    }
`;

export const BottomButtons = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 32px;
    font-family: 'Open Sans', sans-serif;
`;

export const BottomButton = styled(FCRodinaButton)`
    font-family: 'Bebas Neue', sans-serif;
    padding: 20px 60px;
`;

export const DescriptionContent = styled.div`
    box-sizing: border-box;
`;

export const Description = styled.div`
    min-height: 180px;
`;

export const KidsApplicationFormContainer = styled.div`
    margin: 12px auto;
`;
import { RulesDescription, RulesTitle } from "./styled";
import { RulesTable } from "./RulesTable";

export const Rules = () => {
    return (
        <>
            <RulesTitle id="rules">
                Правила поведения зрителей при проведении официальных соревнований
            </RulesTitle>
            <RulesDescription>
                Спорт – это, прежде всего эмоции. И часто так бывает, что они начинают «кипеть».
            </RulesDescription>
            <RulesDescription>
                А так как зрителей тут много собирается, давайте следовать правилам, предписанным <a href="https://rg.ru/2013/12/19/zriteli-dok.html">Постановлением Российской Федерации № 1156</a> от 16 декабря 2013.
            </RulesDescription>
            <RulesDescription>
                По <a href="https://rg.ru/2013/12/19/zriteli-dok.html">этой ссылке</a> полный текст, но если резюмировать, то все эти правила сводятся к знакомым с детства понятиям о плохом и хорошем и соответствуют общепринятому здравому смыслу.
            </RulesDescription>
            <RulesDescription>
                Образно говоря «выпускать пар» можно, но так, чтоб он не «ошпарил» никого вокруг.
            </RulesDescription>
            <RulesTable />
            <RulesDescription>
                Вот еще раз <a href="https://rg.ru/2013/12/19/zriteli-dok.html">ссылка</a> на полный список правил. И хотя может показаться, что их очень много, соблюдать эти порядки, если не замышлять ничего дурного, не составит труда.
            </RulesDescription>
        </>
    );
}
import { FC, useEffect, useState } from "react";
import { Button } from "../../components/ui-kit/RoundButton";

const TOP_PLAYERS = [
    {
        id: '4',
        name: 'Артём Максименко',
        profileLink: '/team/players/11311/',
        imageUrl: 'https://fcrodina.com/uploads/mvp/2fb96a59cbf82d4f9fc4b7e675014f5e.jpg'
    },
    {
        id: '3',
        name: 'Йорди Рейна',
        profileLink: '/team/players/10820/',
        imageUrl: 'https://fcrodina.com/uploads/images/250806a52d8dbd312be38fd222844eeb.jpg'
    },
    {
        id: '2',
        name: 'Магомедхабиб Абдусаламов',
        profileLink: '/news/1266-priz-iz-ruk-bolelschika!/ ',
        imageUrl: 'https://fcrodina.com/uploads/images/0a6330a6daa856503ac37c20ede75812.jpg'
    },
    {
        id: '1',
        name: 'Йорди Рейна',
        profileLink: '/news/otkrili-novogodnie-prazdniki!/',
        imageUrl: 'https://fcrodina.com/uploads/images/reyna.jpg'
    },
];

interface FanMVPListWidgetProps {
    maxCount?: number;
    showAllUrl?: string;
}

export const FanMVPListWidget: FC<FanMVPListWidgetProps> = ({
    maxCount,
    showAllUrl,
}) => {
    const [players, setPlayers] = useState([]);

    useEffect(() => {
        if (maxCount && maxCount < TOP_PLAYERS.length) {
            setPlayers(TOP_PLAYERS.slice(-maxCount));
        } else {
            setPlayers(TOP_PLAYERS);
        }
    }, []);

    return (
        <div className="w-full">
            <div className="flex flex-row justify-center items-center gap-4 flex-wrap">
                {players.map((p, index) => {
                    return (
                        <a href={p.profileLink} title="К профилю игрока" key={p.id} target="_blank" rel="noreferrer" className="max-w-1/3 w-96">
                            <img src={p.imageUrl} alt={p.name} className="transition-all w-full opacity-95 rounded hover:scale-105 hover:opacity-100 hover:-translate-y-4" />
                        </a>
                    )
                })}
            </div>
            {showAllUrl && (
                <div className="text-center pt-4" style={{ fontFamily: 'Futura' }}>
                    <Button title="Посмотреть всех" href={showAllUrl} className="border-1 border-solid border-white mx-auto !bg-sky-950 !border-1 !rounded-full !font-bold italic text-3xl shadow !text-white hover:!bg-sky-900" />
                </div>
            )}
        </div>
    )
}

import { FC, ReactNode, useState } from "react";
import { PlayerDto } from "../../../types/player";
import { NavigationButton, NavigationPlayer, NavigationPlayerName, NavigationPlayerPhoto } from "./styled";

interface PlayerNavigationButtonProps {
    direction: 'prev' | 'next';
    playerData: Partial<PlayerDto>;
    icon?: ReactNode;
}

export const PlayerNavigationButton: FC<PlayerNavigationButtonProps> = ({
    direction,
    playerData,
    icon,
}) => {
    const [show, setShow] = useState(false);

    if (!playerData?.url) {
        return null;
    }

    return (
        <NavigationButton
            onMouseOver={() => setShow(true)}
            onMouseOut={() => setShow(false)}
            href={playerData?.url}
            key={playerData?.id}
        >
            {icon}
            <NavigationPlayer $direction={direction} $show={show}>
                <NavigationPlayerPhoto src={playerData?.photo} />
                <NavigationPlayerName>
                    {playerData?.number || ''} {playerData?.name}
                </NavigationPlayerName>
            </NavigationPlayer>
        </NavigationButton>
    );
}
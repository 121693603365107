import styled from "styled-components";
import { Form as AntForm } from "antd";

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`;

export const FormContainer = styled.div`
    max-width: 800px;
    width: 100%;
`;

export const Form = styled(AntForm)`
    width: 100%;
`;

export const Header = styled.div`
    display: flex;
    padding: 20px;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
`;

export const Title = styled.h2`
    width: 100%;
    font-size: 24px;
    font-weight: 600;
    color: #133D5E;
    margin: 0 24px;
    text-align: center;
`;

export const BackButtonContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-self: flex-start;
    color: #133D5E;
`;

export const PointPreview = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #133D5E;
    border-radius: 50%;
    min-width: 50px;
    min-height: 50px;
    width: 50px;
    height: 50px;
    margin-top: 12px;
`;
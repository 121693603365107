import { FC, useEffect, useState } from "react";
import { getMatchdayActivities } from "../../../../api/transport/matchdayTransport";
import { TimeLineAccordion, TimelineAccordionItem } from "../../../../components/TimelineAccordion";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { BottomButtons, Description, DescriptionContent, ExtraContainer, Icon } from "./styled";
import { MatchdayActivityDto } from "../../../../types/matchday";
import { Loader } from "../../../../components/Loader";
import { ItemActivityButtons } from "./ItemActivityButtons";
import { KidsApplicationForm } from "./KidsApplicationForm";
import { getTicketsConfig } from "../../../../api/transport/configurationTransport";
import { BuyTicketButton } from "../../../../components/tickets/BuyTicketButton";

interface MatchdayActivitiesWidgetProps {
    edit?: {
        onEdit: (id: string) => void;
        onDelete?: (id: string, name?: string) => void;
    },
    buyButton?: boolean;
}

export const MatchdayActivitiesWidget: FC<MatchdayActivitiesWidgetProps> = ({
    edit,
    buyButton = false,
}) => {
    const [activities, setActivities] = useState<TimelineAccordionItem[]>([]);
    const [loading, setLoading] = useState(false);
    const [ticketsUrl, setTicketsUrl] = useState("");

    useEffect(() => {
        getTicketsConfig().then((response) => {
            setTicketsUrl(response.rodinaTicketsUrl);
        });
    }, []);

    useEffect(() => {
        setLoading(true);

        getMatchdayActivities().then((data: MatchdayActivityDto[]) => {
            const items: TimelineAccordionItem[] = [];

            data.forEach((item) => {
                if (!edit && !item.active) {
                    return;
                }

                let descriptionExtra = null;

                //TODO: подумать, как это сделать через настройку в админке, может быть через дополнительные поля в конкурсе
                /*if (item.uuid === '95f2fc2e-c60e-4eb0-96ba-5f34528f1767') {
                    descriptionExtra = <GuessTheScoreExtra buyTicketUrl={ticketsUrl} />;
                } else*/
                if (item.uuid === '21c5305c-7539-4c41-b3cf-65741be334e7') {
                    descriptionExtra = <KidsApplicationForm buyTicketUrl={ticketsUrl} />;
                } else {
                    descriptionExtra = <ItemActivityButtons buyTicketUrl={ticketsUrl} />;
                }

                const description =
                    (
                        <Description>
                            <DescriptionContent dangerouslySetInnerHTML={{ __html: item.description }} />
                            {descriptionExtra}
                        </Description>
                    );

                const res: TimelineAccordionItem = {
                    title: item.name + (item.active ? '' : ' (выключено)'),
                    shortDescription: item.shortDescription,
                    description: description,
                    imageUrl: item.imageUrl,
                    icon: <Icon src={item.iconUrl} alt={item.name} />,
                }
                if (edit?.onEdit || edit?.onDelete) {
                    res.extra = (
                        <ExtraContainer>
                            {edit.onEdit && <Button onClick={() => edit.onEdit(item.id.toString())} icon={<EditOutlined />} />}
                            {edit.onDelete && <Button danger onClick={() => edit?.onDelete!(item.id.toString(), item.name)} icon={<DeleteOutlined />} />}
                        </ExtraContainer>
                    );
                }
                items.push(res);
            });

            setActivities(items);
        }).finally(() => {
            setLoading(false);
        });
    }, [ticketsUrl]);

    if (loading) {
        return <Loader />;
    }

    if (!activities?.length) {
        return null;
    }

    return (
        <>
            <TimeLineAccordion items={activities} activeIndex={activities?.length === 1 ? 0 : null} />
            {buyButton && <BottomButtons>
                <BuyTicketButton
                    url={ticketsUrl}
                    size="3xl"
                    title="Хочу билет"
                    className="text-4xl font-medium py-5 px-16 min-w-[200px]"
                />
                <div className="text-lg opacity-95">твой билет здесь</div>
            </BottomButtons>}
        </>
    );
}

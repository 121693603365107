import { useConfiguration } from "../hooks/useConfiguration";
import { AcademyPlayersPage } from "../pages/club/academy-players/page";
import { Club100Page } from "../pages/club/club100/page";
import CoachesPage from "../pages/club/coaches/page";
import { ExPlayersPage } from "../pages/club/ex-players/page";
import { PlayersPage } from "../pages/club/players/page";
import { PlayerProfilePage } from "../pages/player/page";
import { Configuration, TeamContext } from "../types/common";
import { getPathParameter } from "../utils";

export const TeamPlayersPageApp = () => {
    const config: Configuration = useConfiguration();
    if (config.teamName === undefined) {
        throw new Error('teamName is required');
    }

    if (config.teamName === TeamContext.ACADEMY) {
        return <AcademyPlayersPage />;
    }
    return (
        <PlayersPage teamName={config.teamName} />
    );
}

export const ExPlayersPageApp = () => {
    const config: Configuration = useConfiguration();
    return (
        <ExPlayersPage teamName={config.teamName} />
    );
}

export const PlayerProfilePageApp = () => {
    const config: Configuration = useConfiguration();
    const searchParams = new URLSearchParams(window.location.search);
    const playerId = searchParams.get('gamer') || getPathParameter(window.location.pathname);

    return (
        <PlayerProfilePage
            playerId={playerId}
            teamContext={config.teamName}
            tournamentId={config.tournamentId}
            seasonId={config.seasonId}
            teamId={config.teamId}
        />
    );
}

export const Club100PageApp = () => {
    return (
        <Club100Page />
    );
}

export const CoachesPageApp = () => {
    return (
        <CoachesPage />
    );
}

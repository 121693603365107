import { FC } from "react";
import { Flex, message } from "antd";
import { MailOutlined, PhoneOutlined, WhatsAppOutlined } from "@ant-design/icons";
import { Page, PageContent, PageHead, PageHeadImage, PageHeadTitle } from "../../../components/layout";
import {
    Contacts,
    ContactsIcon,
    ContactsItem,
    DescriptionContainer,
    DescriptionText,
    DescriptionTitle,
    Discount,
    DiscountContainer,
    HeadAdditionalInfo,
    HeadTitleContainer,
    Parameter,
    ParameterIcon,
    ParametersContainer,
    PromoCode
} from "./styled";
import { Consent } from "./Consent";
import { Rules } from "./Rules";
import { getStaticUrl } from "../../../utils";
import { useConfiguration } from "../../../hooks/useConfiguration";
import { TeamContext } from "../../../types/common";

export const StadiumPage: FC = () => {
    const configuration = useConfiguration();

    const parameters = {
        category: '3А',
        name: "Стадион ФК «Родина»",
        address: "г. Москва, ул. 3-я Гражданская, 47",
        capacity: "2825",
        parkingCapacity: "35",
        promoCode: "#ТОПИЗАРОДИНУ",
        discountSize: "10%",
        phone: "+7 495 963 81 97",
        email: "stadion@fcrodina.com",
    }

    const copyPromoCode = () => {
        navigator?.clipboard?.writeText(parameters.promoCode).then(() => {
            message.success('Промокод скопирован в буфер обмена');
        });
    }

    return (
        <Page>
            <PageHead>
                <PageHeadImage
                    src={getStaticUrl('/static/images/stadium/header.jpg')}
                    alt={parameters.name}
                />
                <div className="box-border absolute top-0 left-0 right-0 bottom-0 text-center py-4 h-full w-full flex flex-col justify-between">
                    <PageHeadTitle>{parameters.name}</PageHeadTitle>
                    <HeadAdditionalInfo>
                        <div>
                            Классификация {parameters.category}
                        </div>
                        <div>
                            Адрес: {parameters.address}
                        </div>
                    </HeadAdditionalInfo>
                </div>
            </PageHead>
            <PageContent className="!p-4">
                <DescriptionContainer>
                    <ParametersContainer>
                        <Parameter>
                            <ParameterIcon src={getStaticUrl('/static/images/stadium/capacity.svg')} />
                            <div>{parameters.capacity} мест</div>
                        </Parameter>
                        <Parameter>
                            <ParameterIcon src={getStaticUrl('/static/images/stadium/parking.svg')} />
                            <div>{parameters.parkingCapacity} машиномест</div>
                        </Parameter>
                        <Parameter>
                            <ParameterIcon src={getStaticUrl('/static/images/stadium/field.svg')} />
                            <div>Искуственный газон с подогревом</div>
                        </Parameter>
                        <Parameter>
                            <ParameterIcon src={getStaticUrl('/static/images/stadium/fitting-rooms.svg')} />
                            <div>Раздевалки с душевыми</div>
                        </Parameter>
                    </ParametersContainer>
                    {configuration.teamName !== TeamContext.ACADEMY && <>
                        <DescriptionTitle>
                            АРЕНДА
                        </DescriptionTitle>
                        <Contacts>
                            <ContactsItem href="tel:+74959638197">
                                <ContactsIcon><PhoneOutlined /></ContactsIcon>
                                {parameters.phone}
                            </ContactsItem>
                            <ContactsItem href={`mailto:${parameters.email}`}>
                                <ContactsIcon>
                                    <MailOutlined />
                                </ContactsIcon>
                                {parameters.email}
                            </ContactsItem>
                            <ContactsItem href="https://wa.me/79959227492">
                                <ContactsIcon $color="#28D146">
                                    <WhatsAppOutlined />
                                </ContactsIcon>
                                ЧАТ С МЕНЕДЖЕРОМ
                            </ContactsItem>
                        </Contacts>

                        <DiscountContainer>
                            <Discount>
                                Скидка {parameters.discountSize}
                            </Discount>
                            <DescriptionText>по промокоду <PromoCode onClick={copyPromoCode}>{parameters.promoCode}</PromoCode></DescriptionText>
                        </DiscountContainer>
                    </>}
                </DescriptionContainer>
                <hr />
                <Rules />
                <Consent />
            </PageContent>
        </Page>
    )
}
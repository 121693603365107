import { Button, Checkbox, ConfigProvider, Image, Result, Space, Timeline, Tooltip } from "antd";
import { IframeHTMLAttributes, useCallback, useEffect, useRef, useState } from "react";
import { FCRodinaButton } from "../../../components/Button";
import {
    ConstructorFrame,
    Container,
    DescriptionContent,
    DescriptionTitle,
    OrderButtonWrapper,
    OrderButton,
    Rules
} from "./styled";
import { TDesignConfig, TDesignTemplate } from "../../../types/t-design";
import { Carousel } from "../../../components/Carousel";
import { getHashParams } from "../../../utils";
import { CloseOutlined } from "@ant-design/icons";
import useResetIframe from "../../../hooks/useResetIframe";
import { getTDesignConfig } from "../../../api/transport/configurationTransport";

const APP_ID = '6162';

export const MyTDesignPage = () => {
    const [loading, setLoading] = useState(false);
    const [config, setConfig] = useState<TDesignConfig>(null);
    const [isResult, setResult] = useState(false);
    const [consent, setConsent] = useState(false);
    const [templatesList, setTemplatesList] = useState(0);
    const [templates, setTemplates] = useState<TDesignTemplate[]>([]);
    const [selectedTemplate, setSelectedTemplate] = useState<number>(0);
    const [frameKey, resetFrame] = useResetIframe();
    const frameRef = useRef<HTMLIFrameElement>(null);
    const designerAppId = APP_ID;
    const onMessage = async (event: MessageEvent) => {
        if (!event.data) return;
        let parsed = null;
        try {
            parsed = JSON.parse(event.data)
        } catch (err) {
            return;
        }
        if (parsed?.type !== 'cosuvOrder' && parsed?.type !== 'cosuvOrderEdited') {
            return;
        }

        setLoading(true);

        let url = `https://shop.fcrodina.com/my-t-design?sign=${parsed.sign}&id=${parsed?.id}`;

        if (parsed?.vars?.readable?.size) {
            url += `&size=${parsed.vars.readable.size}`;
        }

        window.location.assign(url);// + "#order:"+ encodeURIComponent("Футболка") +"=3490");
    }

    useEffect(() => {
        getTDesignConfig().then(config => {
            setConfig(config);
            setTemplates(config.templates);
        })
        window.addEventListener("message", onMessage);

        if (window.location.hash === '#success') {
            setResult(true);
        }

        //setTemplates(TEMPLATES);

        const { template, designerSelection } = getHashParams(['template', 'designerSelection']);

        if (template) {
            setTemplatesList(1);
            selectTemplate(parseInt(template));
        }

        if (designerSelection) {
            setTemplatesList(2);
            selectTemplate(parseInt(designerSelection));
        }

        return () => {
            window.removeEventListener("message", onMessage);
        };

    }, []);

    useEffect(() => {
        if (!config) {
            return;
        }
        const templatesToSet = templatesList !== 2 ? config?.templates : config?.designerSelection;

        if (templatesList > 0) {
            setTemplates(templatesToSet);
        } else if (selectedTemplate) {
            selectTemplate(0);
            return;
        }

        if (templatesToSet.find((template) => template.id === selectedTemplate)) {
            selectTemplate(selectedTemplate);
        }
    }, [templatesList]);

    const selectTemplate = useCallback((templateId: number) => {
        /*if (selectedTemplate === templateId) {
            return;
        }*/

        setSelectedTemplate(templateId);

        if (!templateId) {
            if (selectedTemplate) {
                resetFrame();
            }
            return;
        }

        const buttons = templatesList === 2 ? 0 : 1;

        const params = `cosuv-api;compos=${templateId};btn-image=${buttons};btn-figure=${buttons};btn-text=${buttons};`;

        frameRef.current?.contentWindow?.postMessage(params, '*');
    }, [templatesList]);

    if (isResult) {
        return (
            <Result
                status="success"
                title="Ваш заказ успешно оформлен"
                subTitle="Ваш заказ успешно оформлен. Ожидайте звонка менеджера для уточнения деталей."
                extra={[
                    <>
                        <FCRodinaButton size="large" onClick={() => window.location.replace('/')}>
                            На главную
                        </FCRodinaButton>
                    </>
                ]}
            />
        );
    }

    return (
        <Container id="top">
            <Space className="px-2">
                <Tooltip title="Выбери шаблон и дополни его своими идеями">
                    <Button
                        type={templatesList === 1 ? "primary" : "default"}
                        onClick={() => setTemplatesList(templatesList === 1 ? 0 : 1)}
                        size="large"
                    >
                        Шаблоны
                    </Button>
                </Tooltip>
                <Tooltip title="Закажи один из принтов выбранных нашим дизайнером">
                    <Button
                        type={templatesList === 2 ? "primary" : "default"}
                        onClick={() => setTemplatesList(templatesList === 2 ? 0 : 2)}
                        size="large"
                    >
                        Родные принты
                    </Button>
                </Tooltip>
                <Tooltip title="Убери все добавленные элементы и начни творческий процесс с нуля">
                    <Button
                        onClick={() => {
                            setSelectedTemplate(0);
                            resetFrame();
                        }}
                        type="link"
                        danger
                        icon={<CloseOutlined />}
                    >
                        Сбросить
                    </Button>
                </Tooltip>
                {/*<Button onClick={() => {
                    frameRef.current?.contentWindow?.postMessage("cosuv-api;order-edit=dB62mSDSM9y3vHd", '*');
                }} >Редактировать</Button>*/}
            </Space>
            {!!templatesList && !!templates?.length && <div className="mt-3">
                <Carousel elementWidth={132} buttonsClassName="scale-75 right-0 top-0">
                    {templates.map((template) => {
                        console.log('TEMPLATE ID:', template.id);
                        let templateClass = "border-box mx-2 max-w-32 min-w-32 min-h-[165px] rounded border border-solid border-gray-200 cursor-pointer overflow-hidden shadow-sky-950 hover:border-sky-950 hover:shadow-md";
                        if (selectedTemplate === template.id) {
                            templateClass += " border-sky-800";
                        }
                        return (
                            <div
                                className={templateClass}
                                key={template.id}
                                onClick={() => {
                                    selectTemplate(template.id);
                                }}
                            >
                                <Image
                                    loading="lazy"
                                    src={template.previewUrl}
                                    alt={`Шаблон ${template.id}`}
                                    preview={false}
                                    className="block"
                                />
                            </div>
                        )
                    })}
                </Carousel>
            </div>}

            <ConstructorFrame
                key={frameKey}
                id="constructor"
                src={`https://cosuv.ru/app/${designerAppId}`}
                allowFullScreen
                ref={frameRef}
            ></ConstructorFrame>
            <OrderButtonWrapper>
                <Checkbox checked={consent} onChange={(e) => setConsent(
                    e.target.checked
                )}>
                    Я согласен с условиями предоставления услуги и <a href="/static/docs/PrivacyPolicy.docx" target="_blank">политикой конфиденциальности</a>
                </Checkbox>
                <OrderButton
                    disabled={!consent}
                    size="large"
                    onClick={() => {
                        frameRef.current?.contentWindow?.postMessage({
                            type: "submitOrder",
                        }, '*');
                    }}
                >
                    Заказать
                </OrderButton>
            </OrderButtonWrapper>
            <DescriptionTitle>
                Описание и условия
            </DescriptionTitle>
            <DescriptionContent>
                <h3>Как получить футболку</h3>
                <Rules>
                    <ConfigProvider
                        theme={{
                            components: {
                                Timeline: {
                                    /* here is your component tokens */
                                    colorText: '#133d5e',
                                },
                            },
                        }}
                    >
                        <Timeline
                            style={{ marginBottom: '-28px' }}
                            items={[
                                {
                                    color: '#133d5e',
                                    children: <>С помощью <a href="#top">конструктора выше</a> создать свой собственный дизайн или использовать готовые <FCRodinaButton tooltip="Скоро будет доступно" size="small" onClick={() => { setTemplatesList(2); window.scrollTo({ top: 0, behavior: 'smooth' }) }}>Родные принты</FCRodinaButton></>,
                                },
                                {
                                    color: '#133d5e',
                                    children: '⁠Заказать и оплатить футболку. Принимается только оплата банковской картой. Срок производства футболки 4 рабочих дня.',
                                },
                                {
                                    color: '#133d5e',
                                    children: 'Дождаться сообщения на указанную вами почту о готовности заказа. На неё же придет промо-код на оформление бесплатного билета на домашний матч ФК "Родина"',
                                },
                                {
                                    color: '#133d5e',
                                    children: '⁠Прийти на ближайший домашний матч ФК "Родина" и получить футболку в точке продаж атрибутики в матчдэй зоне',
                                },
                            ]}
                        />
                    </ConfigProvider>
                    После оформления заказа, на почту придет промо-код для оформления бесплатного билета.
                </Rules>
                <h3>Характеристики футболки</h3>
                Футболка для индивидуального дизайна из уникального трикотажного полотна неотличимого по тактильным ощущениям и внешнему виду от 100% хлопка.
                <ul>
                    <li>Цвет: белый</li>
                    <li>⁠Состав: 50% хлопок, 50% полиэстер</li>
                    <li>Крой: классический</li>
                </ul>
                Официальная продукция ФК Родина
            </DescriptionContent>
        </Container >
    );
}
import { FC } from "react";
import { FeedbackDto, FeedbackViewType } from "../../../../types/matchday"
import { FeedbackItem } from "./FeedbackItem";
import { TgPostFeedbackItem } from "./TgPostFeedbackItem";
import { ScreenshotFeedbackItem } from "./ScreenshotFeedbackItem";

interface ItemsListProps {
    items: FeedbackDto[];
}

export const ItemsList: FC<ItemsListProps> = ({
    items,
}) => {
    return items.map((feedback) => {
        if (!feedback) {
            return null;
        }

        if (feedback.viewType === FeedbackViewType.TgWidget) {
            return <TgPostFeedbackItem postId={feedback.text} />
        }

        if (feedback.viewType === FeedbackViewType.Image) {
            return <ScreenshotFeedbackItem imageUrl={feedback.imageUrl} linkUrl={feedback.linkUrl} />
        }

        return (
            <FeedbackItem
                key={feedback.id}
                imageUrl={feedback.imageUrl}
                title={feedback.name}
                text={feedback.text}
                linkUrl={feedback.linkUrl}
            />
        );
    });
}
import { BACKEND_URL } from "..";
import { ContestDto } from "../../types/contests";
import { FeedbackDto, MatchdayActivityDto, MatchdayConfig } from "../../types/matchday";
import { getStaticUrl } from "../../utils";

export const getMatchdayActivities = async () => {
    const response = await fetch(BACKEND_URL + '/matchday/activities', {
        method: "GET",
    });

    const data = await response.json();
    if (response.status !== 200) {
        console.error(data);
        return [];
    }

    const resultData = data.map((item: MatchdayActivityDto) => {
        return ({
            ...item,
            imageUrl: getStaticUrl(item.imageUrl),
            iconUrl: getStaticUrl(item.iconUrl),
        });
    });

    return resultData;
}

export const getContestById = async (id: number) => {
    const response = await fetch(BACKEND_URL + '/matchday/activities/activity/' + id, {
        method: "GET",
    });

    const data = await response.json();
    if (response.status !== 200) {
        console.error(data);
        return null;
    }

    return {
        ...data,
        imageUrl: getStaticUrl(data.imageUrl),
    } as ContestDto;
}

export const getMatchdayFeedback = async (page?: number): Promise<FeedbackDto[]> => {
    const response = await fetch(BACKEND_URL + '/matchday/feedback', {
        method: "GET",
    });

    const data = await response.json();
    if (response.status !== 200) {
        console.error(data);
        return [];
    }

    const resultData = data.map((item: any) => {
        return ({
            ...item,
            imageUrl: getStaticUrl(item.imageUrl),
        });
    });

    return resultData;
}

export const submitKidsApplication = async (values: any) => {
    const response = await fetch(BACKEND_URL + '/matchday/kids-application', {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(values)
    });

    const data = await response.json();
    if (response.status !== 200) {
        console.error(data);
        return null;
    }

    return data;
}

export const getMatchdayConfig = async (): Promise<MatchdayConfig | null> => {
    const response = await fetch(BACKEND_URL + '/matchday', {
        method: "GET",
    });

    const data = await response.json();
    if (response.status !== 200) {
        console.error(data);
        return null;
    }

    return data;
}

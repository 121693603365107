import { BACKEND_URL } from "..";
import { ContestDto } from "../../types/contests";
import { getStaticUrl } from "../../utils";

export const getContestsList = async () => {
    const response = await fetch(BACKEND_URL + '/contests', {
        method: "GET",
    });

    const data = await response.json();
    if (response.status !== 200) {
        console.error(data);
        return [];
    }

    const contests = data.map((item: ContestDto) => {
        return ({
            ...item,
            imageUrl: getStaticUrl(item.imageUrl),
            iconUrl: getStaticUrl(item.iconUrl),
        });
    });

    return contests;
}

export const getContestById = async (id: number) => {
    const response = await fetch(BACKEND_URL + '/contests/contest/' + id, {
        method: "GET",
    });

    const data = await response.json();
    if (response.status !== 200) {
        console.error(data);
        return null;
    }
    return ({
        ...data,
        imageUrl: getStaticUrl(data.imageUrl),
        iconUrl: getStaticUrl(data.iconUrl),
    });
}
import { Container } from "./styled";
import { ContestsListWidget } from "../../../widgets/my/contests/ContestsList";

const Contests = () => {
    return (
        <Container>
            <ContestsListWidget />
        </Container>
    );
};

export default Contests;
import { EntityType, TeamContext } from "../types/common";
import { News } from "../types/news";

export const BASE_URL = process.env.REACT_APP_PHP_BASE_URL || process.env.NEXT_PUBLIC_PHP_BASE_URL;

export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || process.env.NEXT_PUBLIC_BACKEND_URL;
const GET_PLAYER_NEWS_URL = `${BACKEND_URL}/news/search`

interface GetNewsParams {
    playerId: string;
}
export const getPlayerNews = async ({ playerId }: GetNewsParams) => {
    const response = await fetch(GET_PLAYER_NEWS_URL, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            entityType: EntityType.PLAYER,
            entityId: playerId
        })
    });
    const data = await response.json();
    return data as News[];
}

export const buildApiUrl = (url: string, context?: TeamContext) => {
    let ctx = context?.trim();
    if (ctx) {
        return `/${ctx}${url}`;
    }
    return url;
}
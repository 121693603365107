import { CalendarEventItem } from "../stats";

export type PlayersStats = Record<StatParams, number>;

export interface PlayerDto {
    id: number;
    birthDate: string;
    birthPlace: string;
    prevClub: string;
    nextClub: string;
    name: string;
    number: number;
    photo: string;
    url: string;
    bio: string;
    position: string;
    positionGroup: PlayerPositionGroup;
    stats: Partial<PlayerStatsDto>;
    prev?: Partial<PlayerDto>;
    next?: Partial<PlayerDto>;
    debutMatch?: CalendarEventItem;
}


export enum StatParams {
    MATCHES = 'matches',
    GOALS = 'goals',
    ASSISTS = 'assists',
    YELLOW_CARDS = 'yellowCards',
    RED_CARDS = 'redCards',
    CLEAN_SHEETS = 'cleanSheets',
    MINUTES_PLAYED = 'minutesPlayed',
    PENALTIES = 'penalties',
    PENALTIES_MISSED = 'penaltiesMissed',
    PENALTIES_SAVED = 'penaltiesSaved',
    MATCHES_SUBSTITUTED_OUT = 'matchesSubstOut',
    MATCHES_SUBSTITUTED_IN = 'matchesSubstdIn',
    MATCHES_IN_START = 'matchesInStart',
    FULL_MATCHES = 'fullMatches',
}

export interface PlayerStatsDto extends Partial<PlayersStats> {
    id?: number;
    matches: number;
    goals: number;
}

export interface PlayerShortDto {
    id: number;
    name: string;
    number: number;
    position: string;
    positionGroup: PlayerPositionGroup;
    photo: string;
    url: string;
    stats: Partial<PlayerStatsDto>;
    birthDate?: string;
}

export enum PlayersFilterBy {
    Position = 'position',
    Matches = 'matches',
    Goals = 'goals',
}

export enum PlayerPositionGroup {
    Goalkeeper = 'goalkeeper',
    Defender = 'defender',
    Midfielder = 'midfielder',
    Forward = 'forward',
}

export interface PlayersStatsTableItem extends PlayerStatsDto {
    name: string;
    photo: string;
    url: string;
}

import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 32px;
    box-sizing: border-box;
`;

export const Title = styled.div`
    font-family: 'Bebas Neue', sans-serif;
    font-size: 24px;
    font-weight: 500;
    margin: 16px 0;
    text-align: center;
    color: #133D5E;
`;

export const Header = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 16px;
`;

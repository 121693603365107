import { useEffect, useState } from "react";
import { TournamentTableDto } from "../../../types/stats";
import { getTournamentTable } from "../../../api/transport/statsTransport";
import { TeamContext } from "../../../types/common";
import { TournamentTableMini } from "../../../components/TournamentTable/TableMini";
import { Loader } from "../../../components/Loader";

interface TournamentTableMiniWidgetProps {
    tournamentId: number;
    seasonId: number;
    teamId?: number;
    topTeams?: number;
    bottomTeams?: number;
    teamName?: TeamContext;
}

export const TournamentTableMiniWidget: React.FC<TournamentTableMiniWidgetProps> = ({
    tournamentId,
    seasonId,
    teamId,
    topTeams,
    bottomTeams,
    teamName
}) => {
    const [tournamentTable, setTournamentTable] = useState<TournamentTableDto>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getTournamentTable(tournamentId, seasonId, teamName).then((data) => {
            let res = [];

            let topCount = topTeams || 3;
            let bottomCount = bottomTeams || 2;

            if (topCount + bottomCount >= data.length - 1) {
                res = [...data];
            } else {

                const teamIndex = data.findIndex((item) => item.teamId === teamId);

                if (teamIndex < 0) {
                    return;
                }

                let needInsertion = false;

                if (teamIndex < topCount - 1 || teamIndex >= data.length - bottomCount) {
                    bottomCount++;
                } else if (teamIndex === topCount - 1) {
                    topCount++;
                } else {
                    needInsertion = true;
                }

                for (let i = 0; i < topCount; i++) {
                    res.push(data[i]);
                }

                if (needInsertion) {
                    res.push(data[teamIndex]);
                }

                for (let i = data.length - bottomCount; i < data.length; i++) {
                    res.push(data[i]);
                }
            }

            setTournamentTable(res);
            setLoading(false);
        }).catch(() => {
            setTournamentTable([]);
            setLoading(false);
        });
    }, [bottomTeams, seasonId, teamId, teamName, topTeams, tournamentId]);

    if (loading) {
        return <Loader size="small" />;
    }

    return (
        <TournamentTableMini data={tournamentTable} />
    );
}


import styled from "styled-components";

interface IconProps {
    $size?: number;
}
export const Icon = styled.img<IconProps>`
    width: 20px;
    height: 20px;
    ${({ $size }) => $size && `
        width: ${$size}px;
        height: ${$size}px;
    `}
    object-fit: contain;
`;

import { useEffect } from "react";
import { FCRodinaButton } from "../../../components/Button";
import { Icon, IconType } from "../../../components/Icon";
import { ShoppingCartOutlined } from "@ant-design/icons";

export const FeedbackForm = () => {
    useEffect(() => {
        const container = document.getElementById("suggestion");

        const hash = globalThis.location.hash.slice(1);
        if (hash) {
            const anchorComment = document.getElementById(hash);
            if (anchorComment) {
                setTimeout(() => {
                    anchorComment.scrollIntoView({ behavior: "smooth" });
                }, 0);
            }
        }

        if (!container) {
            return;
        }



        const script = document.createElement("script");
        script.src = "https://cdn-ru.bitrix24.ru/b31529494/crm/form/loader_6.js?" + (Date.now() / 180000 | 0);
        script.async = true;
        script.dataset.b24Form = "inline/6/eutk0w";
        script.dataset.skipMoving = "true";

        container.innerHTML = "";
        container.appendChild(script);

        //document.body.appendChild(script);

        return () => {
            container.removeChild(script);
            //document.body.removeChild(script); // Cleanup on unmount
        };
    }, []);

    const urlParams = new URLSearchParams(globalThis.location.search);
    const result = urlParams?.get("result");

    if (result === "success") {
        return (
            <div id="result" className="rounded-3xl bg-white p-8 py-16 w-full min-h-64 flex flex-col items-center justify-center text-sky-950 gap-8">
                <h2 className="text-4xl font-bold text-center">Спасибо!</h2>
                <p className="text-center text-3xl">Увидимся на матче</p>
                <div className="flex flex-row gap-8">
                    <FCRodinaButton icon={<Icon type={IconType.Ticket} size={20} />} size="large" onClick={() => globalThis.location.href = '/ticket-id9/'}>Билеты</FCRodinaButton>
                    <FCRodinaButton icon={<ShoppingCartOutlined />} size="large" onClick={() => globalThis.location.href = 'https://shop.fcrodina.com'}>Магазин</FCRodinaButton>
                </div>
            </div>
        );
    } else if (result === "failure") {
        return (
            <div id="result" className="rounded-3xl bg-white p-8 w-full min-h-64 flex flex-col items-center justify-center text-sky-950 gap-8">
                <h2 className="text-3xl font-bold text-center">Отправка не удалась!</h2>
                <FCRodinaButton
                    size="large"
                    onClick={() => {
                        globalThis.location.replace(globalThis.location.href.split('?')[0] + '#suggestion');
                    }}>Попробуй ещё раз</FCRodinaButton>
            </div>
        );
    }

    return <div id="suggestion" className="rounded-3xl overflow-auto bg-white"></div>; // A container for the form
};

export default FeedbackForm;

import { FC } from "react";
import { SiteMapItem } from "../../../types/configuration";

interface LinkBlockProps {
    title: string;
    links: SiteMapItem[];
    headLink?: string;
}

export const LinkBlock: FC<LinkBlockProps> = ({ title, links, headLink }) => {
    let header = null;
    if (headLink) {
        header = (
            <a href={headLink} className="text-white no-underline">
                <h2 className="text-white uppercase">{title}</h2>
            </a>
        )
    } else {
        header = <h2 className="text-white uppercase">{title}</h2>
    }
    return (
        <div className="flex flex-col gap-4 text-white max-w-48">
            {header}
            {links.map((link) => (
                <a href={link.url} key={link.url} className="text-gray-300 hover:text-white no-underline">{link.title}</a>
            ))}
        </div>
    );
}

import { SearchOutlined } from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";

interface SearchProps {
    dark?: boolean;
}
export const Search: React.FC<SearchProps> = ({
    dark = true,
}) => {
    const [search, setSearch] = useState('');
    const searchParams = new URLSearchParams(window.location.search);
    const searchQuery = searchParams.get('q');
    const searchInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (searchQuery) {
            setSearch(searchQuery);
        }
    }, [searchQuery]);

    const handleSearch = (e: React.FormEvent) => {
        e.preventDefault();
        const searchQuery = search.trim();
        if (searchQuery) {
            window.location.assign(`/search/?q=${searchQuery}`);
        }
    }

    let formClassNames = "flex flex-row gap-2 w-[170px] box-border relative text-xl";
    let inputClassNames = "box-border !border-0 px-3 py-1 pr-9 rounded min-h-12 w-full focus:shadow-md focus:ring-1 focus:outline-none"

    if (dark) {
        formClassNames += " text-slate-300";
        inputClassNames += " bg-slate-700 !text-slate-300 focus:ring-sky-900";
    } else {
        formClassNames += " text-slate-900";
        inputClassNames += " transition-all !border-b border-gray-300 rounded-none rounded-t focus:rounded border-solid text-xl bg-transparent focus:bg-slate-100 hover:bg-slate-200 !text-slate-950";
    }

    return (
        <form
            onSubmit={handleSearch}
            className={formClassNames}
        >
            <input
                ref={searchInputRef}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Искать"
                className={inputClassNames}
            />
            <SearchOutlined onClick={(e) => handleSearch(e)} className="absolute right-3 top-0 bottom-0 hover:scale-105"/>
        </form>
    );
}
import { useConfiguration } from "../hooks/useConfiguration";
import { CalendarPage } from "../pages/stats/calendar/page";
import { PlayersStatsPage } from "../pages/stats/player/page";
import { SummaryTablesPage } from "../pages/stats/summary-tables/page";
import { TournamentTablePage } from "../pages/stats/table/page";
import { Configuration } from "../types/common";
import { SummaryTableWidget } from "../widgets/stats/tournament-table/SummaryTableWidget";
import { TournamentTableWidget } from "../widgets/stats/tournament-table/TournamentTableWidget";

export const TournamentTableApp = () => {
    const config: Configuration = useConfiguration();
    return (
        <TournamentTableWidget
            tournamentId={config.tournamentId}
            seasonId={config.seasonId}
            teamName={config.teamName}
        />
    );
}

export const SummaryTableApp = () => {
    const config: Configuration = useConfiguration();
    return (
        <SummaryTableWidget
            summaryTableId={config.summaryTableId}
        />
    );
}

export const CalendarPageApp = () => {
    const config: Configuration = useConfiguration();
    return (
        <CalendarPage
            teamContext={config.teamName}
            tournamentId={config.tournamentId}
            seasonId={config.seasonId}
            teamId={config.teamId}
        />
    );
}

export const TournamentTablePageApp = () => {
    const config: Configuration = useConfiguration();
    return (
        <TournamentTablePage
            teamContext={config.teamName}
            tournamentId={config.tournamentId}
            seasonId={config.seasonId}
            teamId={config.teamId}
        />
    );
}

export const PlayersStatsPageApp = () => {
    const config: Configuration = useConfiguration();
    return (
        <PlayersStatsPage
            teamContext={config.teamName}
            teamId={config.teamId}
        />
    );
}

export const SummaryTablesPageApp = () => {
    return (
        <SummaryTablesPage />
    );
}
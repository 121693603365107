import { useEffect, useState } from "react";
import { StoryScreen } from "../../../components/stories/StoryScreen";
import { getTicketsStoryImage } from "../../../api/transport/configurationTransport";

export const TicketsStoryWidget = () => {
    const [image, setImage] = useState<string | null>(null);
    useEffect(() => {
        getTicketsStoryImage().then((data) => {
            setImage(data);
        });
    },[]);

    return (
        <StoryScreen
            action={() => window.open("/ticket-id4/")}
            image={image}
        />
    );
}

import React from "react";

export const convertTextToReactLines = (text: string, hashBase?: string) => {
    if (!text) {
        return null;
    }

    return text.split('\n').map((line: string, index: number) => (
        <React.Fragment key={index}>
            {replaceHashtags(line, hashBase)}
            <br />
        </React.Fragment>
    ));
};

export const replaceHashtags = (text: string, hashBase: string = '/hashtag/') => {
    const parts = text.split(/(#\w+)/g);

    return (
        <>
            {parts.map((part, index) =>
                part.startsWith('#') ? (
                    <a key={index} href={`${hashBase}${part.slice(1)}`}>
                        {part}
                    </a>
                ) : (
                    linkify(part)
                    //part
                )
            )}
        </>
    );
};

function linkify(text: string) {
    if (!text) {
        return text;
    }
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    const chunks = text.split(urlRegex);

    console.log("To linkify: ", text, chunks);

    return chunks.map((part: string, index) => {
        // If the part matches the URL regex, return an <a> element
        if (part.match(urlRegex) && part.length && part !== 'https://') {
            // Ensure the URL has a proper protocol
            const href = part.startsWith('http') ? part : `https://${part}`;
            return (
                <a key={index} href={href} target="_blank" rel="noopener noreferrer">
                    {part}
                </a>
            );
        }
        // Otherwise, return the plain text
        return part;
    });
}

export const extractTextFromHtml = (html: string): string => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
}

export const extractFirstParagraphFromHtml = (html: string): string => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    let firstParagraph = doc.querySelector('p');
    let textContent = firstParagraph?.textContent ?? '';

    if (!textContent) {
        firstParagraph = doc.querySelector('div');
        textContent = firstParagraph?.textContent ?? '';
    }

    return textContent;
}

export const extractParagraphsFromHtml = (html: string, divsCount: number = 1) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');

    let divs = doc.querySelectorAll('body > div');

    if (!divs.length) {
        divs = doc.querySelectorAll('body > p');
    }

    let notEmptyDivsCount = 0;

    const res = document.createElement('div');

    divs.forEach((div) => {
        if (notEmptyDivsCount >= divsCount) {
            return;
        }

        res.appendChild(div);

        const textContent = div?.textContent?.trim();

        if (textContent) {
            notEmptyDivsCount++;
        }
    });

    return res.outerHTML;
}

export const getWordEnding = (number: number, endings: [string, string, string]): string => {
    const cases = [2, 0, 1, 1, 1, 2];
    const mod100 = number % 100;
    const mod10 = number % 10;

    if (mod100 > 4 && mod100 < 20) {
        return endings[2];
    } else {
        return endings[(mod10 < 5) ? cases[mod10] : cases[5]];
    }
}

export const getFormattedDate = (date: Date): string => {
    return date.toLocaleDateString('ru-RU', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });
}

export const getFormattedDateNumeric = (date: Date): string => {
    return date.toLocaleDateString('ru-RU', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
    });
}
import { ConfigProvider } from 'antd';
import React, { FC, ReactElement } from 'react';
import ReactDOM from 'react-dom/client';

export const ReactAppRenderer = (App: FC, target: string): void | ReactElement => {
    let element = document.getElementById(target);
    if (!element) {
        return;
    }

    const reactApp = ReactDOM.createRoot(element);
    reactApp.render(
        <React.StrictMode>
            <ConfigProvider
                theme={{
                    components: {
                        Button: {
                            borderRadius: 2,
                            borderRadiusLG: 2,
                            defaultBorderColor: '#3d5769',
                            defaultHoverBorderColor: '#0f243c',
                            colorPrimary: '#0f243c',
                            colorPrimaryHover: '#3d5769',
                            defaultHoverColor: '#3d5769',
                            defaultActiveColor: '#3d5769',
                            colorPrimaryActive: '#3d5769',
                        },
                    },
                }}
            >
                <App />
            </ConfigProvider>
        </React.StrictMode>
    );
}
import { FC, useState } from "react"
import { TeamContext } from "../../../types/common";
import { StatsPage } from "../page";
import { MatchListWidget } from "../../../widgets/stats/match-list/MatchListWidget";
import { StatsType, TournamentIds } from "../../../types/stats";

interface CalendarPageProps {
    teamContext?: TeamContext;
    seasonId?: number;
    tournamentId?: number;
    teamId?: number;
}

export const CalendarPage: FC<CalendarPageProps> = ({
    teamContext,
    teamId,
}) => {
    const [tournamentIds, setTournamentIds] = useState<TournamentIds>();
    return (
        <StatsPage
            teamContext={teamContext}
            pageType={StatsType.CALENDAR}
            onTournamentChange={(tournamentUuid, seasonUuid, legacyTournament, legacySeason) => setTournamentIds({
                tournamentUuid, seasonUuid, legacyTournament, legacySeason
            })}
        >
            {!!tournamentIds?.legacyTournament && !!tournamentIds?.legacySeason && <MatchListWidget
                teamName={teamContext}
                seasonId={tournamentIds.legacySeason}
                tournamentId={tournamentIds.legacyTournament}
                teamId={teamId}
            />}
        </StatsPage>
    );
}
import React, { FC } from "react";
import styled from "styled-components";

export interface BottomMenuItemProps {
    title: string;
    icon: React.ReactNode;
    href: string;
    target?: string;
}

const Wrapper = styled.span`
    font-family: 'Bebas Neue', sans-serif;
}`;

export const BottomMenuItem: FC<BottomMenuItemProps> = ({ title, href, icon, target = '_self' }) => {
    return (
        <a
            href={href}
            target={target}
            className="p-3 pb-4 w-full h-full flex flex-col gap-1 items-center text-white transition-all hover:no-underline hover:scale-105"
        >
            <span>{icon}</span>
            <Wrapper className="text-md">{title}</Wrapper>
        </a>
    );
}
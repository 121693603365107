import { useEffect, useState } from "react";
import { MatchListDto } from "../../../types/stats";
import { getMatchList } from "../../../api/transport/statsTransport";
import { TeamContext } from "../../../types/common";
import { MatchListMini } from "../../../components/MatchListTable/MatchListMini";
import { Loader } from "../../../components/Loader";

interface MatchListMiniProps {
    tournamentId: number;
    seasonId: number;
    teamId?: number;
    prevMatches?: number;
    totalMatches?: number;
    teamName?: TeamContext;
}

export const MatchListMiniWidget: React.FC<MatchListMiniProps> = ({
    tournamentId,
    seasonId,
    teamId,
    prevMatches,
    totalMatches,
    teamName,
}) => {
    const [mathcList, setMatchList] = useState<MatchListDto>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getMatchList(tournamentId, seasonId, teamId, teamName).then((data) => {
            let sliceStart = 0;
            let sliceEnd = data.length;

            if (sliceEnd > totalMatches) {
                const nearestMatchIndex = data.findIndex(
                    (item) => (new Date(item.date)).getTime() > Date.now());
                if (nearestMatchIndex === -1) {
                    sliceStart = Math.max(sliceEnd - totalMatches, 0);
                } else {
                    sliceStart = Math.max(nearestMatchIndex - prevMatches, 0);
                    sliceEnd = Math.min(sliceStart + totalMatches, sliceEnd);
                    if (sliceEnd - sliceStart < totalMatches) {
                        sliceStart = Math.max(sliceEnd - totalMatches, 0);
                    }
                }
            }

            const matchesToShow = data.slice(sliceStart, sliceEnd);
            setMatchList(matchesToShow);
            setLoading(false);
        }).catch(() => {
            setMatchList([]);
            setLoading(false);
        });
    }, [seasonId, tournamentId, teamId, totalMatches, prevMatches, teamName]);

    if (loading) {
        return <Loader size="small"/>;
    }

    return (
        <MatchListMini data={mathcList} />
    )
}


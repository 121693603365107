import SmiNewsPage from "../pages/news/Smi/page";
import { SocialFeedPage } from "../pages/news/SocialFeed/page";

export const SmiPageApp = () => {
    return (
       <SmiNewsPage />
    );
}

export const SocialFeedApp = () => {
    return <SocialFeedPage />;
}

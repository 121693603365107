import { FC } from "react"
import { Top } from "../../layout/desktop/Top"
import { useConfiguration } from "../../hooks/useConfiguration";
import { TeamHeader } from "../../layout/desktop/TeamHeader";
import Menu from "../../layout/legacy/menu";

export const LegacyDesktopHeader: FC = () => {
    const config = useConfiguration();
    return (
        <>
            <Top />
            <TeamHeader />
            <Menu teamContext={config.teamName} />
        </>
    );
}
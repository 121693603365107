import { TournamentTableDto } from "../../types/stats";
import { TableMiniContainer, Title } from "./styled";
import { FCRodinaTable } from "../Table";
import { TableTeamTitle } from "../Table/TeamTitle";
import { getStaticUrl } from "../../utils";

interface TournamentTableProps {
    data: TournamentTableDto;
    columns?: string[];
    title?: string;
    noLogos?: boolean;
}

export const TournamentTableMini: React.FC<TournamentTableProps> = ({
    data,
    columns = ['matchesPlayed', 'points'],
    title,
    noLogos,
}) => {
    const dataColumns = [
        { title: 'Место', dataIndex: 'position', key: 'position' },
        { title: 'Команда', dataIndex: 'teamName', key: 'teamName', left: true },
    ];

    columns.forEach((column) => {
        if (column === 'matchesPlayed') {
            dataColumns.push({ title: 'И', dataIndex: 'matchesPlayed', key: 'matchesPlayed' });
        } else if (column === 'points') {
            dataColumns.push({ title: 'О', dataIndex: 'points', key: 'points' });
        }
    });

    const dataItems = data?.map((item) => {
        return dataColumns.map((column) => {
            const value = item[column.dataIndex];
            if (column.dataIndex === 'teamName') {
                return {
                    key: column.key,
                    value: (
                        <TableTeamTitle logoUrl={!noLogos ? getStaticUrl(item.teamLogo) : ''} name={value}/>
                    )
                }
            }
            return {
                key: column.key,
                value
            }
        });
    });

    return (
        <TableMiniContainer>
            {title && <Title>{title}</Title>}
            <FCRodinaTable
                columns={dataColumns}
                data={dataItems}
            />
        </TableMiniContainer>
    );
}


import { BACKEND_URL, buildApiUrl } from "..";
import { MenuItemDto } from "../../components/Menu/types";
import { TeamContext } from "../../types/common";
import { LeagueBannerDto, SiteMapDto } from "../../types/configuration";
import { FanAppPageConfig } from "../../types/configuration/fan-app";
import { PartnersGroupsDto } from "../../types/configuration/partners";
import { TicketsConfigDto } from "../../types/configuration/tickets";
import { SocialLinks } from "../../types/social";
import { TDesignConfig } from "../../types/t-design";
import { getStaticUrl } from "../../utils";

export const getTicketsBanner = async (): Promise<string> => {
    const response = await fetch(BACKEND_URL + '/tickets/banner', {
        method: "GET",
    });

    const data = await response.json();
    if (response.status !== 200) {
        console.error(data);
        return '';
    }
    return getStaticUrl(data?.url || '');
}

export const getTicketsStoryImage = async (): Promise<string> => {
    const response = await fetch(BACKEND_URL + '/tickets', {
        method: "GET",
    });

    const data = await response.json();
    if (response.status !== 200) {
        console.error(data);
        return '';
    }

    return getStaticUrl(data?.storiesImageUrl || '');
}

export const getTicketsConfig = async (): Promise<TicketsConfigDto> => {
    const response = await fetch(BACKEND_URL + '/tickets', {
        method: "GET",
    });

    const data = await response.json();
    if (response.status !== 200) {
        console.error(data);
        return Promise.reject(data);
    }

    return data;
}

export const getLeagueBanner = async (context?: TeamContext): Promise<LeagueBannerDto> => {
    const response = await fetch(BACKEND_URL + buildApiUrl('/config/league-banner', context), {
        method: "GET",
    });

    const data = await response.json();
    if (response.status !== 200) {
        console.error(data);
        return {
            url: '',
        };
    }

    const res = data as LeagueBannerDto;
    res.url = getStaticUrl(res.url);

    return res;
}

export const getPartners = async (context?: TeamContext): Promise<PartnersGroupsDto> => {
    const response = await fetch(BACKEND_URL + buildApiUrl('/config/partners', context), {
        method: "GET",
    });

    const data = await response.json();
    if (response.status !== 200) {
        console.error(data);
        return {
            club: [],
            league: [],
        };
    }
    return ({
        club: data.club.map((item: any) => ({
            ...item,
            logo: getStaticUrl(item.logo),
            footerLogo: getStaticUrl(item.footerLogo),
        })),
        league: data.league.map((item: any) => ({
            ...item,
            logo: getStaticUrl(item.logo),
            footerLogo: getStaticUrl(item.footerLogo),
        })),
    });
}

export const getSocialLinks = async (context?: TeamContext): Promise<SocialLinks> => {
    const response = await fetch(BACKEND_URL + buildApiUrl('/config/social', context), {
        method: "GET",
    });

    const data = await response.json();
    if (response.status !== 200) {
        console.error(data);
        return Promise.reject(data);
    }
    return data;
}

export const getMenu = async (context?: TeamContext): Promise<MenuItemDto[]> => {
    const response = await fetch(BACKEND_URL + buildApiUrl('/config/menu', context), {
        method: "GET",
    });

    const data = await response.json();
    if (response.status !== 200) {
        console.error(data);
        return [];
    }
    return data?.main;
}

export const getFanAppPageConfig = async (): Promise<FanAppPageConfig | null> => {
    const response = await fetch(BACKEND_URL + '/config/fanAppPage', {
        method: "GET",
    });

    const data = await response.json();
    if (response.status !== 200) {
        console.error(data);
        return null;
    }

    const res = JSON.parse(data.value) as FanAppPageConfig;

    return res;
}

export const getSiteMap = async (): Promise<Record<TeamContext, SiteMapDto>> => {
    const response = await fetch(BACKEND_URL + '/config/sitemap', {
        method: "GET",
    });

    if (!response.ok) {
        throw new Error('Sitemap not loaded');
    }

    const data = await response.json();

    const res = JSON.parse(data.value) as Record<TeamContext, SiteMapDto>;

    return res;
}

export const getTDesignConfig = async (): Promise<TDesignConfig> => {
    const response = await fetch(BACKEND_URL + '/config/tDesign', {
        method: "GET",
    });

    if (!response.ok) {
        throw new Error('TDesign config not loaded');
    }

    const data = await response.json();

    const res = JSON.parse(data.value) as TDesignConfig;

    return res;
}

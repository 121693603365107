import { PageContent, PageHead, PageHeadImage } from "../../../components/layout";

export const AcademyEducationPage = () => {
    return (
        <>
            <PageHead>
                <PageHeadImage
                    src="https://academy.fcrodina.com/news/184f681280e2a801af2ebc92a106eb3b.jpg"
                    alt="Сведения об образовательной организации"
                />
            </PageHead>
            <PageContent>
                <h1>Сведения об образовательной организации</h1>
                <h2>Образование</h2>
                <div style={{ padding: '8px 20px' }}>
                    <h3>1. Реализуемые образовательные программы</h3>
                    <p style={{ whiteSpace: 'pre', padding: '4px 20px' }}>
                        {
`Дополнительная образовательная программа спортивной подготовки по виду спорта «футбол» АНО "Футбольная клуб "Родина"

    -  Форма обучения: очная;

    -  Срок обучения: 9 лет;

    -  Язык обучения: русский;
                `}
                    </p>

                    <h3>2. Образовательные программы</h3>
                    <p style={{ padding: '4px 20px' }}>
                        Дополнительная образовательная программа спортивной подготовки по виду спорта «футбол»
                        АНО "Футбольная клуб "Родина"  предназначена для организации образовательной
                        деятельности по спортивной подготовке «футбол» с учетом совокупности минимальных
                        требований к спортивной подготовке, определенных федеральным стандартом спортивной
                        подготовки по виду спорта «футбол», утвержденным приказом Минспорта России 16.11.2022 г.
                        № 1000 (далее - ФССП).
                    </p>
                    <p style={{ padding: '0 20px' }}>
                        <h3>
                            <a
                                href="https://academy.fcrodina.com/uploads/images/docs/education.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Полная информация о программе
                            </a>
                        </h3>
                    </p>
                </div>
            </PageContent>
        </>
    );
};
import { EditContest } from "../admin/contests/EditContest";
import { SubmitButtons } from "../admin/components/SubmitButtons";
import { ContestsList } from "../admin/contests";
import { EditMatchdayActivity } from "../admin/matchday/EditActivity";
import { MatchdayAdmin } from "../admin/matchday";
import { AdminHeader } from "../admin/components/Header";

export const EditContestApp = () => {
    const formData: Record<string, unknown> = window.FCRC_PHP_FORM_DATA as Record<string, unknown>;
    return (
        <EditContest initialValues={formData}/>
    );
}

export const ContestListApp = () => {
    return (
        <ContestsList />
    );
}

export const SubmitButtonApp = () => {
    return (
        <SubmitButtons />
    );
}

export const EditMatchdayActivityApp = () => {
    const formData: Record<string, unknown> = window.FCRC_PHP_FORM_DATA as Record<string, unknown>;
    return (
        <EditMatchdayActivity initialValues={formData}/>
    );
}

export const MatchdayAdminApp = () => {
    return (
        <MatchdayAdmin />
    );
}

export const AdminHeaderApp = () => {
    return (
        <AdminHeader />
    );
}
import { FC, useEffect, useState } from "react";
import { Collapse } from "../../Collapse";
import { BioContainer, Container, Content, Summary, Title } from "./styled";
import { extractParagraphsFromHtml } from "../../../utils";

interface PersonBioProps {
    title?: string;
    content?: string;
}

export const PersonBio: FC<PersonBioProps> = ({
    title = 'Биография',
    content
 }) => {
    const [summary, setSummary] = useState<string>('');
    const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

     useEffect(() => {
        if (content?.length) {
            setSummary(extractParagraphsFromHtml(content, 2));

        }
    }, [content]);

    if (!content?.length) {
        return null;
    }

    return (
        <Container>
            <Title>{title}</Title>
            <Content>
                {isCollapsed && summary && <Summary
                    dangerouslySetInnerHTML={{ __html: summary }}
                />}
                <Collapse height={0} onStateChange={(open)=>setIsCollapsed(!open)}>
                    <BioContainer
                        dangerouslySetInnerHTML={{ __html: content }}
                    >
                    </BioContainer>
                </Collapse>
            </Content>
        </Container>
    );
}

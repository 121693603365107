import { FC, useEffect, useState } from "react";
import { PageContent } from "../../../components/layout";
import { getAdministrative } from "../../../api/transport/staffTransport";
import { PersonCard, PersonList } from "../../../components/PersonCard";
import { StaffDto } from "../../../types/staff";
import { Loader } from "../../../components/Loader";
import { getFormattedDateNumeric, getStaticUrl } from "../../../utils";
import { useConfiguration } from "../../../hooks/useConfiguration";

interface AdministrativePageProps {}

const AdministrativePage: FC<AdministrativePageProps> = ({}) => {
    const [loading, setLoading] = useState(true);
    const [staff, setStaff] = useState<StaffDto[]>([]);
    const config = useConfiguration();

    useEffect(() => {
        setLoading(true);
        getAdministrative(config?.teamName)
            .then((data) => {
                setStaff(data);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [config?.teamName]);

    if (loading) {
        return <Loader />;
    }

    return (
        <PageContent>
            <PersonList>
                {staff.map(manager => (
                    <PersonCard
                        key={manager.uuid}
                        name={manager.fullName}
                        title={manager.title}
                        photo={getStaticUrl(manager.photoUrl)}
                        birthDate={manager.birthDate ? getFormattedDateNumeric(new Date(manager.birthDate)) : ''}
                    />
                ))}
            </PersonList>
        </PageContent>
    );
}

export default AdministrativePage;
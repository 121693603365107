import { FC, ReactNode } from "react";

interface ContactItemProps {
    icon?: ReactNode;
    text: ReactNode;
}

export const ContactItem: FC<ContactItemProps> = ({
    icon,
    text,
}) => {
    return (
        <div className="flex flex-row gap-2 items-center">
            {icon && <div className="w-9 text-center">
                {icon}
            </div>}
            <div className="text-lg">{text}</div>
        </div>
    )
}

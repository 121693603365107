import { FC } from "react";
import { FormattedTextContent } from "./styled";

interface FormattedTextProps {
    text: string;
}

export const FormattedText: FC<FormattedTextProps> = ({ text }) => {
    return <FormattedTextContent dangerouslySetInnerHTML={{ __html: text }} />;
}

import { FC, useCallback, useEffect, useState } from "react";
import {
    Wrapper,
    Container,
    Popup,
    BgImage,
    CloseButton,
    Content,
    ButtonContainer,
    SubmitButton,
    //SubscribeButton
} from "./styled"
import { CloseOutlined } from "@ant-design/icons";

interface StoryScreenProps {
    action?: () => void;
    url?: string;
    image?: string;
}

export const StoryScreen: FC<StoryScreenProps> = ({ action, image }) => {
    const [isPopupOpen, setIsPopupOpen] = useState(true);
    const isMobile = /android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent || '');

    const closeWindow = useCallback(() => {
        if (!isMobile) {
            setIsPopupOpen(false);
        } else {
            window.location.assign(window.location.href);
        }
    }, [isMobile]);

    useEffect(() => {
        if (isMobile) {
            const mainContent = document.getElementsByClassName('alld')?.[0];
            if (isPopupOpen) {
                mainContent.setAttribute('style', 'display: none');
            }
            else {
                mainContent.removeAttribute('style');
            }
        }
    }, [isMobile, isPopupOpen]);

    const onClick = useCallback(() => {
        if (action) {
            action();
        }
        closeWindow();
    }, [action, closeWindow]);

    if (!isPopupOpen || !image) {
        return null;
    }

    return (
        <Wrapper onClick={(e) => closeWindow()}>
            <Container>
                <Popup onClick={(e) => e.stopPropagation()}>
                    <CloseButton onClick={closeWindow}>
                        <CloseOutlined />
                    </CloseButton>
                    <BgImage src={image} />
                    <Content>
                        <ButtonContainer>
                            <SubmitButton onClick={onClick} size="large">
                                <span className="mt-2 font-bold">
                                    Хочу билет
                                </span>
                            </SubmitButton>
                            {/*<SubscribeButton onClick={() => window.open("https://vk.com/fc_rodina?w=app5748831_-191701576", "_blank")}>
                                Подписаться
                            </SubscribeButton>*/}
                        </ButtonContainer>
                    </Content>
                </Popup>
            </Container>
        </Wrapper>
    )
}
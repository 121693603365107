import { TeamContext } from "../common";

export type StatsFilter = {
    tournamentId: number;
    seasonId: number;
    teamId?: number;
    teamName?: TeamContext;
    summaryTableId?: number;
    tournamentUuid?: string;
    seasonUuid?: string;
};

export enum StatsType {
    TOURNAMENT_TABLE = 'tournamentTable',
    CALENDAR = 'calendar',
    PLAYER = 'player',
    SUMMARY_TABLE = 'summaryTable',
}

export * from './coaches';
export * from './matches';
export * from './player';
export * from './records';
export * from './summaryTables';
export * from './tables';
export * from './tournaments';
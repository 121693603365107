import styled from "styled-components";

export const PlayerPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    margin-bottom: 24px;
    width: 100%;
    min-height: 500px;
`;

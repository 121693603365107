import { FC, Fragment } from "react";
import { TeamContext } from "../../../types/common";

const SITE_ROOT = process.env.REACT_APP_SITE_ROOT_URL;

interface ClubLinksProps {
    teamName?: TeamContext;
}

export const ClubLinks: FC<ClubLinksProps> = ({
    teamName
}) => {
    const linkItems = [];

    if (teamName !== TeamContext.RODINA) {
        linkItems.push({
            href: '/rodina/',
            label: 'Первая команда'
        });
    }

    if (teamName !== TeamContext.RODINA_2) {
        linkItems.push({
            href: '/rodina-2/',
            label: 'Родина-2'
        });
    }

    if (teamName !== TeamContext.RODINA_3) {
        linkItems.push({
            href: '/rodina-3/',
            label: 'Родина-М'
        });
    }

    if (teamName !== TeamContext.RODINA_M) {
        linkItems.push({
            href: '/rodina-m/',
            label: 'Молодёжка'
        });
    }

    if (teamName !== TeamContext.ACADEMY) {
        linkItems.push({
            href: '/academy/',
            label: 'Академия'
        });
    }

    return (
        <div className="flex justify-center items-center space-x-1">
            {linkItems.map((item, index) => (
                <Fragment key={index}>
                    <a href={SITE_ROOT + item.href} className="transition-all px-3 py-1 pb-2 rounded text-white text-xl hover:bg-sky-950 no-underline hover:no-underline">{item.label}</a>
                    {index < linkItems.length - 1 && <span className="text-white pb-2 text-xl">·</span>}
                </Fragment>
            ))}
        </div>
    );
}
import { FC } from "react";
import { getMediaTypeByUrl, getStaticUrl } from "../../utils";
import { MediaType } from "../../types/common";
import { Video } from "../Video";

interface LauncherPageVideoProps {
    url?: string;
    bannerUrl?: string;
}

export const LauncherPageVideo: FC<LauncherPageVideoProps> = ({
    url,
    bannerUrl,
}) => {
    if (!url) {
        return null;
    }

    const mediaType = getMediaTypeByUrl(url);

    if (mediaType === MediaType.UNKNOWN) {
        if (!bannerUrl) {
            return null;
        }

        return (
            <a href={url} target="_blank" rel="noreferrer">
                <img
                    src={getStaticUrl(bannerUrl)}
                    alt="Баннер"
                    className="object-cover rounded w-full aspect-video" />
            </a>
        );
    }
    return (
        <Video src={url} type={mediaType} title="Трансляция" autoplay={false} />
    );
}

export default LauncherPageVideo;
import { FC, useEffect, useState } from "react";
import { getPlayersStats } from "../../../api/transport/statsTransport";
import { TeamContext } from "../../../types/common";
import { Loader } from "../../../components/Loader";
import { PlayersStatsTable } from "../../../components/player/PlayersStatsTable";
import { PlayersStatsTableItem } from "../../../types/player";
import { Empty } from "antd";
import { getPlayerInfos } from "../../../api/transport/playersTransport";

interface PlayersStatsWidgetProps {
    teamId?: number;
    teamContext?: TeamContext;
    tournamentUuid?: string;
    seasonUuid?: string;
}

export const PlayersStatsWidget: FC<PlayersStatsWidgetProps> = ({ tournamentUuid, seasonUuid, teamId, teamContext }) => {
    const [playersStats, setPlayersStats] = useState<PlayersStatsTableItem[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getPlayersStats(tournamentUuid, seasonUuid, teamId, teamContext).then(async (data) => {
            const players = await getPlayerInfos(data.map((playerStats) => playerStats.id), teamContext);
            const stats: PlayersStatsTableItem[] = [];
            data.forEach((playerStats) => {
                const player = players.find((p) => p.stats.id === playerStats.id);
                if (!player) {
                    return;
                }
                stats.push({
                    ...playerStats,
                    name: player?.name,
                    photo: player?.photo,
                    url: player?.url,
                });
            });
            setPlayersStats(stats);
        }).catch((error) => {
            setPlayersStats([]);
        }).finally(() => {
            setLoading(false);
        });
    }, [teamId, teamContext, tournamentUuid, seasonUuid]);

    if (loading) {
        return <Loader />;
    }

    if (!playersStats.length) {
        return <Empty description={false} />;
    }

    return (
        <PlayersStatsTable data={playersStats} />
    );
}


import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 500px;
    padding-bottom: 64px;
    width: 100%;
    box-sizing: border-box;
`;

export const ActivitiesContainer = styled.div`
    padding: 20px 0;
    width: 100%;
    box-sizing: border-box;
`;

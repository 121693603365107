import { useEffect, useState } from "react";
import { getStatCoaches } from "../../../../api/transport/statsTransport";
import { StatCoachDto } from "../../../../types/stats";
import { CoachCard } from "./CoachCard";
import { Loader } from "../../../../components/Loader";
import styled from "styled-components";

const Wrapper = styled.div`
    font-family: 'Futura', sans-serif;
`;

export const CoachesPage = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [coaches, setCoaches] = useState<StatCoachDto[]>([]);

    useEffect(() => {
        setLoading(true);
        getStatCoaches().then((data) => {
            setCoaches(data);
        }).catch((e) => {
            console.error(e);
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    if (loading) {
        return (
            <div className="text-center w-full min-h-100">
                <Loader />
            </div>
        )
    }

    return (
        <Wrapper>
            <div className="grid grid-cols-1 gap-8 max-w-5xl mx-auto">
                {coaches?.map(c => {
                    return <CoachCard key={c.id} {...c} />;
                })}
            </div>
        </Wrapper>
    );
};

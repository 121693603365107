import styled from 'styled-components';

export const VacancyContainer = styled.div`
    font-family: 'Roboto';
    width: 100%;
    min-height: 500px;
`;

export const VacancyContent = styled.div`
    position: relative;
    //max-width: 860px;
    margin: 0 auto;
`;

interface VacancyShareContainerProps {
    $sharing?: boolean;
}

export const VacancyShareContainer = styled.div<VacancyShareContainerProps>`
    position: absolute;
    right: -54px;
    top: -40px;
    scale: ${props => props.$sharing ? '0.65' : '0.25'};
    transition: all 0.5s;
    opacity: ${props => props.$sharing ? 1 : 0};
`;

export const ResponsibilitiesHeader = styled.h2`
    font-weight: 500;
    margin-top: 24px;
    color: #fff;
    background-color: #133D5E;
    padding: 4px 6px;
`;

export const SecondaryHeader = styled.h2`
    font-weight: 500;
    margin-top: 24px;
    color: #555;
    background-color: #AFD7F0;
    padding: 4px 6px;
`;

export const SectionContent = styled.p`
    padding: 0 12px;
    font-size: 16px;
    text-align: justify;
`;

export const ApplicationFormContainer = styled.div`
    font-family: 'Roboto';
    border: 1px solid #eee;
    padding: 24px;
    border-radius: 4px;
`;

export const FormattedTextContent = styled.div`
    ul {
        margin: 0;
        padding-left: 2.95em;
    }
    p {
        margin: 0;
    }

    font-size: 16px;
    text-align: justify;
`;


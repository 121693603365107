import styled from "styled-components";

export const TableMiniContainer = styled.div`
    width: 100%;
    @media (min-width: 768px) {
        max-width: 440px;
    }
`;

export const Info = styled.div`
    font-family: 'Roboto', sans-serif;
    padding: 6px 8px;
    background-color: #f5f5f5;
    font-size: 13px;
    margin-bottom: 10px;
    border-radius: 4px;
`;

import { Flex, Form, Input, message, Result, Upload } from "antd"
import { Button } from "../../../components/ui-kit/Button"
import { ApplicationFormContainer } from "./styled"
import { FC, useState } from "react";
import { createApplication } from "../../../api/transport/jobsTransport";
import { UploadOutlined } from "@ant-design/icons";
import { getUrlByContext } from "../../../utils";
import { useTeamContext } from "../../../hooks/useConfiguration";

interface ApplicationFormValues {
    name: string;
    contactEmail: string;
    contactPhone: string;
    cvUrl: string;
    cv: any;
    cvFile: File;
    coverLetter: string;
}

interface ApplicationFormProps {
    vacancyUuid: string;
}

export const ApplicationForm: FC<ApplicationFormProps> = ({
    vacancyUuid,
}) => {
    const [form] = Form.useForm<ApplicationFormValues>();
    const [formSent, setFormSent] = useState<boolean>(false);

    const handleFileChange = (info: any) => {
        const file = info?.fileList[0]?.originFileObj || null;
        form.setFieldsValue({ cvFile: file });
      };

    const onFinish = async (values: ApplicationFormValues) => {
        const formData = new FormData();

        formData.append('name', values.name);
        formData.append('contactEmail', values.contactEmail);
        formData.append('contactPhone', values.contactPhone);
        formData.append('cvUrl', values.cvUrl || '');
        formData.append('cv', values.cvFile);
        formData.append('coverLetter', values.coverLetter || '');
        formData.append('vacancyUuid', vacancyUuid);

        createApplication(vacancyUuid, formData).then((response) => {
            setFormSent(true);
        }).catch((error) => {
            message.error('Произошла ошибка при отправке отклика, попробуйте позже');
        });
    }

    const teamContext = useTeamContext();

    if (formSent) {
        return (
            <ApplicationFormContainer>
                <Result
                    status="success"
                    title="Cпасибо за ваш отклик!"
                    subTitle="Мы рассмотрим его в ближайшее время и свяжемся с вами."
                    extra={[
                        <Button size="large" onClick={() => window.open(getUrlByContext('/jobs/', teamContext), '_self')}>
                            Вернуться к списку вакансий
                        </Button>
                    ]}
                />
            </ApplicationFormContainer>
        );
    }

    return (
        <ApplicationFormContainer>
            <Flex vertical gap={20} align="center">
                <h1>Отклик на вакансию</h1>
                <div style={{ width: '100%' }}>
                    <Form
                        wrapperCol={{ span: 12 }}
                        labelCol={{ span: 6 }}
                        onFinish={onFinish}
                        form={form}
                        size="large"
                    >
                        <Form.Item
                            label="Ваше имя"
                            name="name"
                            rules={[{ required: true, message: 'Пожалуйста, введите ваше имя' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Ваш email"
                            name="contactEmail"
                            rules={[
                                { required: true, message: 'Пожалуйста, введите ваш email' },
                                { type: 'email', message: 'Пожалуйста, введите корректный email' }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Ваш телефон"
                            name="contactPhone"
                            rules={[
                                { required: true, message: 'Пожалуйста, введите ваш телефон' },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Ссылка на резюме"
                            name="cvUrl"
                            rules={[
                                {
                                    validator: async (rule, value) => {
                                        if (!value && !form.getFieldValue('cvFile')) {
                                            throw new Error('Пожалуйста, введите ссылку на ваше резюме или загрузите его');
                                        }
                                    }
                                },
                                {
                                    type: 'url',
                                    message: 'Пожалуйста, введите корректную ссылку'
                                }
                            ]}
                            help="Пожалуйста, укажите ссылку на ваше резюме или загрузите файл"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label=" "
                            colon={false}
                            name="cvFile"
                            rules={[{
                                validator: async (rule, value) => {
                                    if (!value && !form.getFieldValue('cvUrl')) {
                                        throw new Error('Пожалуйста, введите ссылку на ваше резюме или загрузите его');
                                    }
                                }
                            }]}
                            valuePropName="file"
                            getValueFromEvent={(e) => e.fileList[0]?.originFileObj || null}
                            help="Поддерживаемые форматы: pdf, doc, docx, rtf, txt, odt"
                        >
                            <Upload
                                beforeUpload={() => false}
                                onChange={handleFileChange}
                                accept=".pdf,.doc,.docx,.rtf,.txt,.odt"
                                maxCount={1}
                            >
                                <Button icon={<UploadOutlined />}>Загрузить резюме</Button>
                            </Upload>
                        </Form.Item>
                        <Form.Item
                            label="Сопроводительное письмо"
                            name="coverLetter"
                        >
                            <Input.TextArea />
                        </Form.Item>
                    </Form>
                </div>
                <Button size="large" onClick={() => form.submit()}>Отправить</Button>
            </Flex>
        </ApplicationFormContainer>
    )
}
import { useConfiguration } from "../hooks/useConfiguration";
import { HomePage } from "../pages/home";
import { Configuration, TeamContext } from "../types/common";
import { CalendarCarouselWidget } from "../widgets/mainScreen/CalendarCarousel";
import { LeagueBannerWidget } from "../widgets/mainScreen/LeagueBanner";
import { MainScreenStatsWigdet } from "../widgets/mainScreen/MainScreenStats";
import { TicketsStoryWidget } from "../widgets/mainScreen/TicketsStory";

export const LeagueBannerApp = () => {
    const config: Configuration = useConfiguration();
    return (
        <LeagueBannerWidget teamName={config.teamName} />
    );
}

export const MainScreenStatsApp = () => {
    const configuration: Configuration = useConfiguration();
    let statsFilters = configuration.mainScreenConfig?.filters;
    if (!statsFilters?.length) {
        statsFilters = [{
            tournamentId: configuration.tournamentId || 178,
            seasonId: configuration.seasonId || 18,
            teamId: configuration.teamId,
        }]
    }

    return (
        <MainScreenStatsWigdet
            statsFilters={statsFilters}
            topTeams={configuration.mainScreenConfig?.table.topTeams || 3}
            bottomTeams={configuration.mainScreenConfig?.table?.bottomTeams || 2}
            prevMatches={configuration.mainScreenConfig?.matches?.prevMatches || 2}
            teamName={configuration.teamName}
        />
    );
}

export const CalendarCarouselApp = () => {
    const config: Configuration = useConfiguration();
    let seasonId = config.seasonId;
    if (config.teamName === TeamContext.RODINA) {
        seasonId = 18;
    }
    return (
        <CalendarCarouselWidget
            seasonId={seasonId}
            teamId={config.teamId}
            teamName={config.teamName}
        />
    );
}

export const HomePageApp = () => {
    return (
        <HomePage />
    );
}

export const StoryPopupApp = () => {
    return <TicketsStoryWidget />;
}

export const MainPageApp = () => {
    return null;
}
import { PlayerPositionGroup, PlayersFilterBy, PlayerShortDto } from "../../../types/player";

export const getGroupName = (group: PlayerPositionGroup) => {
    switch (group) {
        case PlayerPositionGroup.Goalkeeper:
            return 'Вратари';
        case PlayerPositionGroup.Defender:
            return 'Защитники';
        case PlayerPositionGroup.Midfielder:
            return 'Полузащитники';
        case PlayerPositionGroup.Forward:
            return 'Нападающие';
    }
}

export const GROUP_ORDER = [
    PlayerPositionGroup.Goalkeeper,
    PlayerPositionGroup.Defender,
    PlayerPositionGroup.Midfielder,
    PlayerPositionGroup.Forward,
];

export const splitByPositionGroup = (players: PlayerShortDto[]) => {
    const groups = new Map<PlayerPositionGroup, PlayerShortDto[]>();

    players.forEach(player => {
        const group = groups.get(player.positionGroup) || [];
        group.push(player);
        if (!groups.has(player.positionGroup)) {
            groups.set(player.positionGroup, group);
        }
    });

    return groups;
}

export const sortBy = {
    [PlayersFilterBy.Position]: (a: PlayerShortDto, b: PlayerShortDto) => a.number - b.number,
    [PlayersFilterBy.Matches]: (a: PlayerShortDto, b: PlayerShortDto) => b.stats.matches - a.stats.matches,
    [PlayersFilterBy.Goals]: (a: PlayerShortDto, b: PlayerShortDto) => b.stats.goals - a.stats.goals,
}

export const applySort = (filter: PlayersFilterBy) => {
    let sortByOrder = [];
    if (filter === PlayersFilterBy.Position) {
        sortByOrder = [PlayersFilterBy.Position, PlayersFilterBy.Matches, PlayersFilterBy.Goals];
    } else if (filter === PlayersFilterBy.Matches) {
        sortByOrder = [PlayersFilterBy.Matches, PlayersFilterBy.Goals, PlayersFilterBy.Position];
    } else {
        sortByOrder = [PlayersFilterBy.Goals, PlayersFilterBy.Matches, PlayersFilterBy.Position];
    }
    return (a: PlayerShortDto, b: PlayerShortDto) => {
        let res = 0;
        for (let i = 0; i < sortByOrder.length; i++) {
            if (sortByOrder[i] !== PlayersFilterBy.Position && (!a.stats || !b.stats)) {
                res = 0;
            } else {
                res = sortBy[sortByOrder[i]](a, b);
            }
            if (res !== 0) {
                break;
            }
        }

        return res;
    }
}
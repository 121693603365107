import { RulesDescription, SectionTitle } from "./styled";

export const Consent = () => {
    return (
        <>
            <SectionTitle>
                И ещё кое-что...
            </SectionTitle>
            <RulesDescription>
                Посещая спортивное мероприятие  участник мероприятия дает Обществу с ограниченной ответственностью "ФК РОДИНА" (ОГРН 5157746096350), далее "Правообладатель", согласие на использование своих изображений, полученных в результате фото и видео съемки во время проведения спортивного мероприятия, в рекламных целях.
            </RulesDescription>
            <RulesDescription>
                Изображения участника мероприятия могут быть использованы бессрочно в печатной рекламе, рекламе в интернете, наружной рекламе на территории Российской Федерации (в том числе, но не ограничиваясь: на сайте Правообладателя, а также на иных профессиональных сайтах в качестве иллюстраций профессиональной деятельности, а также на стендах учреждения, в буклетах, альбомах, видеоматериалах).
            </RulesDescription>
            <RulesDescription>
                Правообладатель имеет право обнародовать и в дальнейшем использовать изображения полностью или фрагментарно: воспроизводить, распространять путем размещения оригиналов изображений или их экземпляров, осуществлять публичный показ, импортировать оригиналы или экземпляры изображений в целях распространения, перерабатывать, доводить до всеобщего сведения.
            </RulesDescription>
            <RulesDescription>
                Правообладатель вправе передавать все указанные права на изображения полностью или частично любым третьим лицам.
            </RulesDescription>
            <RulesDescription>
                Данное согласие является бессрочным.
            </RulesDescription>
        </>
    );
}

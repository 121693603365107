import { Icon } from "./styled";
import { FCRodinaButton as Button } from "../Button";
import wa from "./whatsapp.png";
import { Dropdown, MenuProps, Tooltip } from "antd";

interface ContactUsButtonProps {
    children: React.ReactNode;
    phoneNumber: string;
}

export const ContactUsButton: React.FC<ContactUsButtonProps> = ({ children, phoneNumber }) => {
    const menuItems: MenuProps['items'] = [
        {
            key: 'reg',
            title: 'Хочу на просмотр',
            label: (
                <Button className="w-full transition-all hover:bg-sky-900 min-h-12">
                    Хочу на просмотр в Академию «Родина»
                </Button>
            ),
            onClick: () => window.open(`https://academy.fcrodina.com/reg/`, '_self')
        },
        {
            key: 'whatsapp',
            title: 'Вопрос от болельщика ФК «Родина»',
            label: (
                <Button className="w-full transition-all hover:bg-sky-900 min-h-12">
                    Вопрос от болельщика ФК «Родина»
                </Button>
            ),
            onClick: () => window.open(`https://wa.me/${phoneNumber}`, '_blank', "popup=1,width=320,height=260")
        }
    ];
    return (
        <Dropdown menu={{ items: menuItems }} trigger={['click', 'hover']}>
            <Tooltip title="Обратная связь для болельщиков ФК «Родина»">
                <Button tooltip="Обратная связь для болельщиков ФК «Родина»" className="transition-all hover:bg-sky-900">
                    <Icon src={wa} />
                    {children}
                </Button>
            </Tooltip>
        </Dropdown>
    );
}

import { FC } from "react";
import { PlayerPositionGroup, PlayerShortDto } from "../../../types/player";
import { PlayerCard } from "../PlayerCard";
import { StatsInfo, StatsInfoItem, StatsInfoItemIcon, StatsInfoItemText } from "./styled";
import { Tooltip } from "antd";
import { SkinOutlined } from "@ant-design/icons";
import { getStaticUrl } from "../../../utils";

interface PlayerElementProps {
    player: PlayerShortDto;
    showTitle?: boolean;
    showBirthDate?: boolean;
    noStats?: boolean;
}

export const PlayerElement: FC<PlayerElementProps> = ({
    player,
    showTitle,
    showBirthDate,
    noStats,
}) => {
    const isGk = player?.positionGroup === PlayerPositionGroup.Goalkeeper;
    return (
        <PlayerCard
            key={player.id}
            name={player.name}
            number={player.number ? player.number.toString() : ''}
            photo={player.photo}
            link={player.url}
            title={showTitle ? player.position : ''}
            birthDate={showBirthDate ? player.birthDate : undefined}
        >
            {!noStats && player.stats && <StatsInfo>
                <Tooltip title="Сыгранные матчи">
                    <StatsInfoItem>
                        <SkinOutlined />
                        <StatsInfoItemText>
                            {player.stats?.matches || 0}
                        </StatsInfoItemText>
                    </StatsInfoItem>
                </Tooltip>
                {isGk && (
                    <Tooltip title="Сухие матчи">
                        <StatsInfoItem>
                            <StatsInfoItemIcon src={getStaticUrl('/static/images/icons/cleanSheets.svg')} />
                            <StatsInfoItemText>
                                {player.stats?.cleanSheets || 0}
                            </StatsInfoItemText>
                        </StatsInfoItem>
                    </Tooltip>
                )}
                {!isGk && <Tooltip title="Забитые мячи">
                    <StatsInfoItem>
                        <StatsInfoItemIcon src={getStaticUrl('/static/images/icons/goals.svg')} />
                        <StatsInfoItemText>
                            {player.stats?.goals || 0}
                        </StatsInfoItemText>
                    </StatsInfoItem>
                </Tooltip>}
            </StatsInfo>}
        </PlayerCard>
    );
}
import { useEffect, useState } from "react";
import { TournamentTable } from "../../../types/stats";
import { getTournamentTables } from "../../../api/transport/statsTransport";
import { TeamContext } from "../../../types/common";
import { TournamentTable as TournamentTableView } from "../../../components/TournamentTable";
import { Loader } from "../../../components/Loader";
import { getUrlByContext } from "../../../utils";

interface TournamentTableProps {
    tournamentId: number;
    seasonId: number;
    teamName?: TeamContext;
}

export const TournamentTableWidget: React.FC<TournamentTableProps> = ({tournamentId, seasonId, teamName}) => {
    const [tournamentTables, setTournamentTables] = useState<TournamentTable[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getTournamentTables(tournamentId, teamName).then((data) => {
            if (!data.length) {
                return Promise.reject();
            }

            setTournamentTables(data.reverse());
            setLoading(false);
        }).catch(() => {
            setTournamentTables([]);
            //setLoading(false);
            window.location.replace(getUrlByContext('/games/' + window.location.search, teamName));
        });
    }, [seasonId, teamName, tournamentId]);

    if (loading) {
        return <Loader />;
    }

    return tournamentTables.map((tournamentTable, index) => (
        <TournamentTableView
            key={index}
            data={tournamentTable.table}
            title={tournamentTable.title}
            extra={tournamentTable.extra}
        />
    ));
}


export const MapPinIcon = () => (
    <svg className="w-9 h-9 text-sky-950 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.8 13.938h-.011a7 7 0 1 0-11.464.144h-.016l.14.171c.1.127.2.251.3.371L12 21l5.13-6.248c.194-.209.374-.429.54-.659l.13-.155Z" />
    </svg>
);

export const MapFrame = () => (
    <iframe
        src="https://yandex.ru/map-widget/v1/?um=constructor%3A3e15c9d7cdd5baf76bf60bfd089201af22fba7e60a6ceddf16b895f48f311c15&amp;source=constructor"
        width="100%"
        height={256}
        frameBorder="0"
        title="ФК «Родина» на карте"
        className="rounded border border-solid border-gray-400 overflow-hidden"
    ></iframe>
);

import { BACKEND_URL, buildApiUrl } from "..";
import { TeamContext } from "../../types/common";
import { PlayerDto, PlayerShortDto } from "../../types/player";
import { getStaticUrl, getUrlByContext } from "../../utils";

export const getPlayers = async (context?: TeamContext, searchParams: string = '') => {
    const response = await fetch(
        BACKEND_URL + '/legacy' + buildApiUrl('/players' + searchParams, context)
    );

    const data = await response.json();
    if (response.status !== 200) {
        console.error(data);
        return [];
    }
    return data.map((player: PlayerShortDto) => ({
        ...player,
        photo: getStaticUrl(player.photo),
        url: getUrlByContext(player.url, context),
    })) as PlayerShortDto[];
}

export const getExPlayers = async (context?: TeamContext) => {
    const response = await fetch(
        BACKEND_URL + '/legacy' + buildApiUrl('/players/ex-players', context)
    );

    const data = await response.json();
    if (response.status !== 200) {
        console.error(data);
        return [];
    }
    return data.map((player: PlayerShortDto) => ({
        ...player,
        photo: getStaticUrl(player.photo),
        number: 0,
    })) as PlayerShortDto[];
}

export const getPlayerInfo = async (playerId: string, context?: TeamContext) => {
    const response = await fetch(
        BACKEND_URL + '/legacy' + buildApiUrl(`/players/${playerId}`, context)
    );
    const data = await response.json();
    const res = {
        ...data,
        photo: getStaticUrl(data.photo),
        birthDate: data.birthDate.split('.').reverse().join('-'),
    } as PlayerDto;

    if (res.prev) {
        res.prev.photo = getStaticUrl(res.prev.photo);
        if (res.prev.url) {
            res.prev.url = getUrlByContext(res.prev.url, context);
        }
    }

    if (res.next) {
        res.next.photo = getStaticUrl(res.next.photo);
        if (res.next.url) {
            res.next.url = getUrlByContext(res.next.url, context);
        }
    }

    return res;
}

export const getPlayerInfos = async (ids: number[], context?: TeamContext): Promise<PlayerDto[]> => {
    const response = await fetch(
        BACKEND_URL + '/legacy' + buildApiUrl("/players/search", context),
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                gamerIds: [ids],
            }),
        }
    );
    const data = await response.json();
    return data.filter((player: PlayerDto | null) => player != null).map((player: PlayerDto) => {
        const res = {
            ...player,
            photo: getStaticUrl(player?.photo || '/i/noava.jpg'),
            birthDate: player.birthDate?.split('.').reverse().join('-'),
            url: getUrlByContext(player.url, context),
        } as PlayerDto;

        if (res.prev) {
            res.prev.photo = getStaticUrl(res.prev.photo);
            if (res.prev.url) {
                res.prev.url = getUrlByContext(res.prev.url, context);
            }
        }

        if (res.next) {
            res.next.photo = getStaticUrl(res.next.photo);
            if (res.next.url) {
                res.next.url = getUrlByContext(res.next.url, context);
            }
        }

        return res;
    });
}

export const getClub100Players = async (context: TeamContext = TeamContext.RODINA): Promise<{
    members: PlayerShortDto[],
    candidates: PlayerShortDto[],
}> => {
    const response = await fetch(
        BACKEND_URL + '/legacy' + buildApiUrl('/players/club-100', context)
    );

    const data = await response.json();
    if (response.status !== 200) {
        console.error(data);
        throw new Error('Failed to get club100 players');
    }

    return data;
}
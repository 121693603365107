import { FC, useEffect, useState } from "react";
import type { MenuProps as AntMenuProps } from 'antd';
import { Menu as AntMenu, ConfigProvider } from 'antd';
import { getQticketsUrl, getStaticUrl, getUrlByContext } from "../../../utils";
import { MenuItemDto } from "../../../components/Menu/types";
import { getMenu } from "../../../api/transport/configurationTransport";
import { TeamContext } from "../../../types/common";
import { BuyTicketButton } from "../../../components/tickets/BuyTicketButton";
import { ItemLink } from "./ItemLink";
import { Search } from "../../desktop/Top/Search";

type MenuItem = Required<AntMenuProps>['items'][number];

interface MenuProps {
    teamContext: TeamContext;
    standalone?: boolean;
}

const Menu: FC<MenuProps> = ({
    teamContext,
    standalone = false,
}) => {
    const [menuItems, setMenuItems] = useState<MenuItemDto[]>([]);
    const [current, setCurrent] = useState('');
    const [scroll, setScroll] = useState(0);
    const [loading, setLoading] = useState(true);

    const onScroll = () => {
        setScroll(document.documentElement.scrollTop);
    }

    useEffect(() => {
        setLoading(true);
        getMenu(teamContext).then((data) => {
            setMenuItems(data);
            if (window.qTickets) {
                setTimeout(() => window.qTickets.init(), 1000);
            }
        }).finally(() => {
            setLoading(false);
        });

        window.addEventListener('scroll', onScroll);

        return () => {
            window.removeEventListener('scroll', onScroll);
        }
    }, [teamContext]);
    //const router = useRouter();

    const onClick: AntMenuProps['onClick'] = (e) => {
        console.log('click ', e);

        const item = menuItems.find((item) => item.key === e.key);

        if (e.key.startsWith('external:')) {
            return;
        }
        //router.push(`/${teamContext}/${e.key.replace(':', '/')}`);
        setCurrent(e.key);
    };

    const currentLocation = typeof window !== 'undefined' ? window.location.pathname : '';

    const items = [];

    let currentKey = '';

    menuItems.forEach((item) => {
        if (item.key === 'shop') {
            return;
        }

        if (item.key === 'tickets') {
            items.push({
                key: item.key,
                icon: teamContext !== TeamContext.ACADEMY ? <img src={getStaticUrl("/static/images/icons/ticket.svg")} className="h-7" alt="Билеты"></img> : null,
                label: (
                    <a
                        className="text-white hover:no-underline "
                        href={getQticketsUrl(item.url)}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Билеты
                    </a>
                    /*<BuyTicketButton
                        icon
                        url="https://fcrodina.com/ticket-id9/"
                        size="sm"
                        color="green"
                        title="Купить билет"
                    />*/
                ),
            });
            return;
        }

        if (item.key === 'view') {
            items.push({
                key: item.key,
                label: (
                    <a
                        className="bg-slate-900 pb-2 pt-3 px-3 rounded-sm !text-white hover:bg-slate-800 hover:no-underline"
                        href={item.url}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {item.title}
                    </a>
                ),
            });
            return;
        }

        if (item.url && currentLocation.includes(getUrlByContext(item.url, teamContext))) {
            //setCurrent(item.key);
            currentKey = item.key;
        }

        let children = [];

        item.children?.forEach((child) => {
            if (!children) {
                children = [];
            }

            if (currentLocation.includes(child.url)) {
                //setCurrent(item.key);
                currentKey = item.key;
            }

            children.push({
                key: child.key,
                label: (
                    <ItemLink
                        to={getUrlByContext(child.url, teamContext, true)}
                        standalone={standalone}
                    >
                        {child.title}
                    </ItemLink>
                ),
            })
        });

        if (item.url) {
            items.push({
                key: item.key,
                label: (
                    <ItemLink
                        className="text-slate-900"
                        to={getUrlByContext(item.url, teamContext, true)}
                        standalone={standalone}
                    >
                        {item.title}
                    </ItemLink>
                ),
                children,
            });
        } else {
            items.push({
                key: item.key,
                label: item.title,
                children,
            });
        }
    });

    if (menuItems.find((item) => item.key === 'shop')) {
        items.push({
            key: 'shop',
            label: <a className="text-slate-900 hover:no-underline" href="https://shop.fcrodina.com">Магазин</a>,
            extra: <img src={getStaticUrl("/static/images/icons/shop.svg")} className="h-7 mb-1 -ml-2" alt="Магазин" ></img>
        });
    }

    items.push({
        key: 'search',
        label: (<div>
            <Search dark={false}/>
        </div>),
        disabled: true,
    })

    const scrollAchieved = scroll > 200;

    return (
        <>
            <nav className={`transition-all duration-500 ${!scrollAchieved ? "sticky bg-white/95" : "fixed bg-white shadow-md"} top-0 left-0 right-0 z-[777] flex justify-center w-full`}>
                {loading && <div className="min-h-[60px]" />}
                <ConfigProvider
                    theme={{
                        components: {
                            Menu: {
                                fontSize: 16,
                                groupTitleFontSize: 15,
                            },
                        },
                    }}
                >
                    <AntMenu
                        onClick={onClick}
                        //selectedKeys={[current]}
                        selectedKeys={[currentKey]}
                        mode="horizontal"
                        items={items}
                        style={{ fontFamily: 'Bebas Neue, sans-serif' }}
                        className={`!text-slate-950 !bg-transparent !pt-2 !text-3xl uppercase !leading-[3.5rem] w-full text-center justify-center`}
                    />
                </ConfigProvider>
            </nav>
            {scrollAchieved && <div className="!min-h-[50px]" />}
        </>
    )
}

export default Menu;

import styled from "styled-components";

export const PersonProfileCardContainer = styled.div`
    position: relative;
    font-family: 'Bebas Neue', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 560px;
    background: radial-gradient(circle, #7A787D, #373737);
    box-sizing: border-box;
    overflow: hidden;
    color: #fff;
`;

export const PlayerBgNumber = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
    font-size: 700px;
    font-weight: 500;
    padding: 0 24px;
    color: #000;
    opacity: 0.1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 40px;
    box-sizing: border-box;
    z-index: 0;
    transform: scaleX(1.25);
`;

export const PlayerBgNumberDigit = styled.div`
    padding-top: 4px;
    vertical-align: bottom;
    min-width: 250px;
`;

export const PersonPhoto = styled.img`
    height: 95%;
    object-fit: contain;
    pointer-events: none;
    z-index: 1;
    border-radius: 16px 16px 0 0;
    opacity: 0.95;
`;

export const ParametersContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 40px;
    position: absolute;
    top: 40px;
    right: 32px;
    font-family: 'Bebas Neue', sans-serif;
    color: #fff;
`;

export const Parameter = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    z-index: 2;
`;

export const ParameterName = styled.div`
    text-align: center;
    font-weight: 700;
    font-size: 21px;
`;

export const ParameterValue = styled.div`
    font-size: 50px;
    font-weight: 200;
    text-align: center;
`;

export const PlayerNumber = styled.div`
    position: absolute;
    left: 32px;
    top: 24px;
    font-size: 97px;
    text-align: right;
    min-width: 80px;
`;

export const PlayerName = styled.div`
    position: absolute;
    left: 120px;
    top: 40px;
    font-size: 32px;
    font-weight: 200;
    width: 120px;
`;

export const PlayerPosition = styled.div`
    position: absolute;
    left: 150px;
    top: 120px;
    font-size: 26px;
    text-shadow: 2px 1px 3px rgba(0, 0, 0, 0.5);
    z-index: 2;
`;

export const ControlsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    bottom: 45%;
    left: 24px;
    right: 24px;
    font-size: 40px;
    font-weight: 200;
`;

interface NavigationPlayerProps {
    $direction?: "next" | "prev";
    $show?: boolean;
}
export const NavigationPlayer = styled.div<NavigationPlayerProps>`
    display: flex;
    flex-direction: ${({ $direction }) => $direction === "next" ? "row-reverse" : "row"};
    justify-content: ${({ $direction }) => $direction === "next" ? "flex-end" : "flex-start"};
    align-items: center;
    cursor: pointer;
    z-index: 2;
    transition: opacity 0.7s;
    opacity: ${({ $show }) => $show ? 1 : 0};
    position: absolute;
    top: -12px;
    ${({ $direction }) => $direction === "next" ? "right: 0;" : "left: 0;"}
   // pointer-events: none;
`;

export const NavigationPlayerPhoto = styled.img`
    width: 60px;
    height: 60px;
    object-fit: cover;
    object-position: top center;
    background: radial-gradient(circle, #7A787D, #373737);
    z-index: 1;
    border-radius: 50%;
    border: 4px solid #fff;
`;

export const NavigationPlayerName = styled.div`
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    color: #000;
    background: #fff;
    padding: 4px 8px 0px;
    margin: 0 -2px;
`;

export const NavigationButton = styled.a`
    opacity: 0.4;
    cursor: pointer;
    transition: opacity 0.2s;
    color: #fff;
    &:hover {
        opacity: 1;
    }
`;
import { FC } from "react";
import { PageContent } from "../../../components/layout";
import { MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { SocialLinksList } from "../../../widgets/social/SocialLinks";
import { useConfiguration } from "../../../hooks/useConfiguration";
import { Configuration, TeamContext } from "../../../types/common";
import { ContactItem } from "./ContactItem";
import { AccreditationForm } from "./AccreditationForm";
import { MapPinIcon } from "./Map";
import { ContactUsWidget } from "./ContactUs";

export const ContactsPage: FC = () => {
    const config: Configuration = useConfiguration();
    const socialLinksTeam = config.teamName === TeamContext.RODINA_M || config.teamName === TeamContext.ACADEMY ? TeamContext.ACADEMY : TeamContext.RODINA;
    const hasAccreditation = config.teamName === TeamContext.ACADEMY;

    const phoneNumber = config.teamName === TeamContext.ACADEMY ? '+7 (495) 963-81-97' : '+7 (499) 918 00 35';

    return (
        <PageContent>
            <h1 className="text-4xl">Контакты</h1>
            <hr className="border-1 border-t border-solid border-sky-200 my-2" />
            <h3 className="text-2xl">Офис футбольного клуба «Родина»</h3>
            <div className="flex flex-col gap-4 items-start">
                <ContactItem
                    icon={<PhoneOutlined className="text-3xl text-sky-950" />}
                    text={`Телефон: ${phoneNumber}`}
                />
                <ContactItem
                    icon={<a href="mailto:info@fcrodina.com"><MailOutlined className="transition-all text-sky-950 text-3xl hover:scale-105" /></a>}
                    text={<>Электронная почта: <a href="mailto:info@fcrodina.com">info@fcrodina.com</a></>}
                />
                <ContactItem
                    icon={<MapPinIcon />}
                    text="Россия, 107258, г. Москва, ул. 3-я Гражданская, 47"
                />
            </div>
            <div className="flex flex-col sm:flex-row gap-2 items-start sm:items-center">
                <div className="w-1/2 mr-10">
                    <hr className="border-0 border-t border-solid border-gray-200 my-4" />
                    <div className="flex flex-row gap-2 items-center text-lg">
                        Мы в соцсетях: <SocialLinksList className="!gap-3 ml-3" iconClassName="!h-9 !w-9" teamContext={socialLinksTeam} />
                    </div>
                    <hr className="border-0 border-t border-solid border-gray-200 my-4" />
                </div>
                <ContactUsWidget />
            </div>
            {hasAccreditation && <div className="pb-6">
                <h3 className="text-2xl">Аккредитация СМИ на матчи ЮФЛ</h3>
                <ContactItem
                    icon={<a title="Контактный e-mail" href="mailto:polyakov@fcrodina.com"><MailOutlined className="transition-all text-sky-950 text-3xl hover:scale-105" /></a>}
                    text={<>Контактный e-mail: <a href="mailto:polyakov@fcrodina.com">polyakov@fcrodina.com</a></>}
                />
                {hasAccreditation && <AccreditationForm />}
            </div>}
            <h3 className="text-2xl">Расположение на Яндекс.карте</h3>
            <div id="YMapsID" className="relative h-72 rounded border border-solid border-gray-400 overflow-hidden" />
            <div className="pb-6" />
        </PageContent>
    );
}
import { FC } from "react";
import {
    PartnerContainer,
    //PartnerDescription,
    PartnerInfo,
    PartnerLink,
    PartnerLogo,
    PartnerName,
} from "./styled";


interface PartnerElementProps {
    logo: string;
    name: string;
    link: string;
    description: string;
}
export const PartnerElement: FC<PartnerElementProps> = ({
    logo,
    name,
    link,
    description,
}) => {
    return (
        <PartnerContainer>
            <PartnerLogo src={logo} />
            <PartnerInfo>
                <PartnerName>{name}</PartnerName>
                {link && <PartnerLink href={link}>Сайт {name}</PartnerLink>}
                {/*<PartnerDescription>{description}</PartnerDescription>*/}
            </PartnerInfo>
        </PartnerContainer>
    );
}
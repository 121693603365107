import { MouseEventHandler } from "react";
import {
    PersonCardContainer,
    PersonCardImage,
    PersonInfoContainer,
    PersonName,
    PersonTitle,
    Divider,
    PersonList,
    PersonListTitle,
    PersonBirthDate,
    PersonBirthDateText,
} from "./styled";
import { BirthDateIcon } from "../Icon";

interface PersonCardProps {
    name: string;
    title: string;
    number?: string;
    photo?: string;
    onClick?: MouseEventHandler<HTMLAnchorElement>;
    children?: React.ReactNode;
    link?: string;
    birthDate?: string;
}

const PersonCard: React.FC<PersonCardProps> = ({
    name,
    title,
    number,
    photo,
    onClick,
    children,
    link,
    birthDate,
}) => {

    const clickHandler = (e: React.MouseEvent<HTMLAnchorElement>) => {
        if (!link && !onClick) {
            e.preventDefault();
        } else if (onClick) {
            onClick(e);
        }
    }

    let nameTextSize = 28;
    if (name && name.length > 20) {
        nameTextSize = 22;
    }

    return (
        <PersonCardContainer onClick={clickHandler} href={link} $noLink={!link}>
            {photo && <PersonCardImage src={photo} />}
            <PersonInfoContainer>
                {title && <PersonTitle>{title}</PersonTitle>}
                {number && <PersonTitle>{number}</PersonTitle>}
                <Divider />
                <PersonName style={{ fontSize: nameTextSize }}>{name}</PersonName>
                {!!birthDate && <PersonBirthDate>
                    <BirthDateIcon />
                    <PersonBirthDateText>
                        {birthDate}
                    </PersonBirthDateText>
                </PersonBirthDate>}
            </PersonInfoContainer>
            {children}
        </PersonCardContainer>
    )
}

export { PersonCard, PersonList, PersonListTitle };
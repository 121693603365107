import React from "react";
import { CandidateCardContent, MatchesText, NameText, Photo, PhotoContainer, TextsContainer } from "./styled"
import { getWordEnding } from "../../../utils";


interface CandidateCardProps {
    photo: string;
    name: string;
    number: string;
    matches: number;
    link?: string;
}

export const CandidateCard: React.FC<CandidateCardProps> = ({
    photo,
    name,
    number,
    matches,
    link,
}: CandidateCardProps) => {
    const matchesText = getWordEnding(matches, ['матч', 'матча', 'матчей']);
    return (
        <CandidateCardContent href={link}>
            <TextsContainer>
                <NameText>{name}</NameText>
                <MatchesText>{matches} {matchesText} сыграно за «Родину»</MatchesText>
            </TextsContainer>
            <PhotoContainer>
                <Photo src={photo} alt={name} />
            </PhotoContainer>
        </CandidateCardContent>
    );
}
import { BACKEND_URL, buildApiUrl } from "..";
import { TeamContext } from "../../types/common";
import { PlayerStatsDto, StatParams } from "../../types/player";
import { CalendarDto, MatchListDto, PlayerMatchListDto, StatCoachDto, StatsRecord, StatsTournamentSelectorsDto, TournamentStageDto, TournamentTable, TournamentTableDto } from "../../types/stats";
import { SummaryTableDto, SummaryTableSelectorDto } from "../../types/stats/summaryTables";

export const getTournamentTable = async (tournamentId: number, seasonId: number, context?: TeamContext) => {
    const response = await fetch(BACKEND_URL + buildApiUrl('/stats/tournament-table', context), {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            tournamentId,
            seasonId
        })
    });

    const data = await response.json();
    if (response.status !== 200) {
        console.error(data);
        return [];
    }
    return data as TournamentTableDto;
}

export const getTournamentTables = async (tournamentId: number, context?: TeamContext) => {
    const response = await fetch(BACKEND_URL + buildApiUrl('/stats/tournament-tables', context), {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            tournamentId
        })
    });

    const data = await response.json();
    if (response.status !== 200) {
        console.error(data);
        return [];
    }
    return data as TournamentTable[];
}

export const getMatchList = async (tournamentId: number, seasonId: number, teamId: number, context?: TeamContext) => {
    const response = await fetch(BACKEND_URL + buildApiUrl('/stats/match-list', context), {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            tournamentId,
            seasonId,
            teamId,
        })
    });

    const data = await response.json();
    if (response.status !== 200) {
        console.error(data);
        return [];
    }
    return data as MatchListDto;
}

export const getCalendar = async (seasonId: number, teamId: number, context?: TeamContext) => {
    const response = await fetch(BACKEND_URL + buildApiUrl('/stats/calendar', context), {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            seasonId,
            teamId,
        })
    });

    const data = await response.json();
    if (response.status !== 200) {
        console.error(data);
        return [];
    }
    return data as CalendarDto;
}

export const getSummaryTable = async (summaryTableId: number): Promise<SummaryTableDto> => {
    const response = await fetch(BACKEND_URL + '/stats/summary-tables/table/' + summaryTableId);

    const data = await response.json();
    if (response.status !== 200) {
        console.error(data);
        throw new Error('Failed to get summary table');
    }
    return data as SummaryTableDto;
}

export const getSummaryTableByTournament = async (tournamentId: number) => {
    const response = await fetch(BACKEND_URL + '/stats/summary-tables/table-by-tournament/' + tournamentId);

    const data = await response.json();
    if (response.status !== 200) {
        console.error(data);
        throw new Error('Failed to get summary table');
    }
    return data as SummaryTableDto;
}

export const getSummaryTablesSelector = async (): Promise<SummaryTableSelectorDto> => {
    const response = await fetch(BACKEND_URL + '/stats/summary-tables/selectors');

    const data = await response.json();
    if (response.status !== 200) {
        console.error(data);
        throw new Error('Failed to get summary table selectors');
    }
    return data as SummaryTableSelectorDto;
}

export const getSelectors = async (context?: TeamContext) => {
    const response = await fetch(
        BACKEND_URL + buildApiUrl('/stats/selectors',
            context
        ));

    const data = await response.json();
    if (response.status !== 200) {
        console.error(data);
        throw new Error('Failed to get selectors');
    }
    return data as StatsTournamentSelectorsDto;
}

export const getPlayerSelectors = async (playerId: string, context?: TeamContext) => {
    const response = await fetch(
        BACKEND_URL + buildApiUrl('/stats/selectors/player/' + playerId,
            context
        ));

    const data = await response.json();
    if (response.status !== 200) {
        console.error(data);
        throw new Error('Failed to get selectors');
    }
    return data as StatsTournamentSelectorsDto;
}

export const getTournamentStages = async (tournamentUuid: string) => {
    const response = await fetch(
        BACKEND_URL + '/tournaments/' + tournamentUuid + '/stages',
    );

    const data = await response.json();
    if (response.status !== 200) {
        console.error(data);
        throw new Error('Failed to get tournament stages');
    }
    return data as TournamentStageDto[];
}

export const getPlayerStatsById = async (playerId: string, tournamentUuid?: string, seasonUuid?: string, context?: TeamContext) => {
    const response = await fetch(
        BACKEND_URL + buildApiUrl('/stats/player/' + playerId, context), {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            tournamentUuid,
            seasonUuid,
        }),
    });

    const data = await response.json();
    if (response.status !== 200) {
        console.error(data);
        throw new Error('Failed to get player stats');
    }
    return data;
}

export const getPlayersStats = async (tournamentUuid: string, seasonUuid: string, teamId: number, context?: TeamContext): Promise<PlayerStatsDto[]> => {
    const response = await fetch(
        BACKEND_URL + buildApiUrl('/stats/players', context), {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            tournamentUuid,
            seasonUuid,
            teamId,
        }),
    });

    const data = await response.json();
    if (response.status !== 200) {
        console.error(data);
        throw new Error('Failed to get players stats');
    }

    return data;
}

export const getPlayersStatsByLegacyIds = async (tournamentId: number, seasonId: number, teamId: number, context?: TeamContext): Promise<PlayerStatsDto[]> => {
    const response = await fetch(
        BACKEND_URL + buildApiUrl('/stats/players', context), {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            tournamentId,
            seasonId,
            teamId,
        }),
    });

    const data = await response.json();
    if (response.status !== 200) {
        console.error(data);
        throw new Error('Failed to get players stats');
    }

    return data;
}

export const getPlayerMatchStatsById = async (playerId: string, tournamentUuid: string, seasonUuid: string, teamId: number, context?: TeamContext, filterBy?: StatParams) => {
    const response = await fetch(BACKEND_URL + buildApiUrl(`/stats/player/${playerId}/matches`, context), {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            tournamentUuid,
            seasonUuid,
            teamId,
            filterBy,
        })
    });

    const data = await response.json();
    if (response.status !== 200) {
        console.error(data);
        return [];
    }
    return data as PlayerMatchListDto;
}

export const getClubRecords = async () => {
    const response = await fetch(BACKEND_URL + '/stats/records');

    const data = await response.json();
    if (response.status !== 200) {
        console.error(data);
        return [];
    }
    return data as StatsRecord[];
}

export const getStatCoaches = async (): Promise<StatCoachDto[]> => {
    const response = await fetch(BACKEND_URL + '/stats/coaches');
    const data = await response.json();

    if (response.status !== 200) {
        console.error(data);
        throw new Error('Failed to get coaches');
    }

    return data;
}

export const getTournamentTableImage = async (tournamentUuid: string): Promise<string> => {
    const response = await fetch(BACKEND_URL + '/config/tournamentTableImages', {
        method: "GET",
    });

    const data = await response.json();
    if (response.status !== 200) {
        console.error(data);
        return null;
    }

    const res = JSON.parse(data.value) as Record<string, string>;

    return res[tournamentUuid];
}
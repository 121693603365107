import { FC } from "react";
import { TeamLogo, TeamNameContainer, TextWrapper } from "./styled";

interface TableTeamTitleProps {
    logoUrl?: string;
    name: string;
}

export const TableTeamTitle: FC<TableTeamTitleProps> = ({logoUrl, name}) => {
    return (
        <TeamNameContainer>
            {logoUrl && <TeamLogo src={logoUrl} />}
            <TextWrapper>{name}</TextWrapper>
        </TeamNameContainer>
    );
}
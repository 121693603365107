import { FC, useEffect, useState } from "react"
import { LeagueBanner } from "../../../components/LeagueBanner"
import { getLeagueBanner } from "../../../api/transport/configurationTransport";
import { TeamContext } from "../../../types/common";
import { getUrlByContext } from "../../../utils";

interface LeagueBannerWidgetProps {
    teamName?: TeamContext;
}

export const LeagueBannerWidget: FC<LeagueBannerWidgetProps> = ({ teamName }) => {
    const [bannerSrc, setBannerSrc] = useState<string>('');
    const [bannerLink, setBannerLink] = useState<string>('');

    useEffect(() => {
        getLeagueBanner(teamName).then((data) => {
            setBannerSrc(data.url);
            if (data.linkUrl) {
                setBannerLink(data.linkUrl);
            } else if (teamName === TeamContext.RODINA_M) {
                setBannerLink('/youth/games/tables/');
            } else {
                setBannerLink(getUrlByContext('/tables/', teamName));
            }
        });
    }, [teamName]);

    if (!bannerSrc) {
        return null;
    }

    const title = teamName === TeamContext.ACADEMY ? 'На сайт ЮФЛ' : 'Перейти к турнирной таблице';
    let leagueName = '';

    if (teamName === TeamContext.RODINA_M) {
        leagueName = 'МФЛ';
    } else if (teamName === TeamContext.ACADEMY) {
        leagueName = 'ЮФЛ';
    } else if (teamName === TeamContext.RODINA) {
        leagueName = 'ФНЛ';
    } else if (teamName === TeamContext.RODINA_2) {
        leagueName = 'ФНЛ 2А';
    } else if (teamName === TeamContext.RODINA_3) {
        leagueName = 'ФНЛ 2Б';
    }

    return (
        <LeagueBanner
            bannerSrc={bannerSrc}
            bannerUrl={bannerLink}
            leagueName={leagueName}
            title={title}
        />
    )
}
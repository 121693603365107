import { PlayerShortDto } from "../../../types/player";
import { FC, useEffect, useState } from "react";
import { Loader } from "../../../components/Loader";
import { getClub100Players } from "../../../api/transport/playersTransport";
import { CandidateCard } from "./CandidateCard";
import {
    CandidatesList,
    CandidateListTitle,
    Container,
    LeftSideContent,
    Logo,
    MemberListShowAll,
    MembersList,
    MemeberElement,
    MemeberListContainer,
    YudaImage
} from "./styled";
import yooda from '../../../assets/club100/yooda.png';

interface Club100PageProps { }

export const Club100Page: FC<Club100PageProps> = () => {
    const [loading, setLoading] = useState(true);
    const [members, setMembers] = useState<PlayerShortDto[]>([]);
    const [candidates, setCandidates] = useState<PlayerShortDto[]>([]);

    useEffect(() => {
        getClub100Players()
            .then(async ({
                members,
                candidates
            }) => {
                setCandidates(candidates);
                setMembers(members);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);



    let content = null;

    if (loading) {
        content = <Loader color="white" />;
    } else {
        content = (
            <>
                <LeftSideContent>
                    <Logo />
                    <MemeberListContainer>
                        <MembersList>
                            {members?.map((m, i) => (
                                <MemeberElement>
                                    <div>{i + 1}. {m.name}</div>
                                    <div>{m?.stats?.matches || 100}</div>
                                </MemeberElement>
                            ))}
                        </MembersList>
                        {members.length > 3 && <MemberListShowAll>смотреть полный список</MemberListShowAll>}
                    </MemeberListContainer>
                    <YudaImage src={yooda} />
                </LeftSideContent>
                <CandidatesList>
                    <CandidateListTitle>Ждём в клубе!</CandidateListTitle>
                    {candidates.map((c) => (
                        <CandidateCard
                            key={c.id}
                            photo={c.photo}
                            name={c.name}
                            number=""
                            matches={c.stats?.matches || 0}
                            link={c.url}
                        />
                    ))}
                </CandidatesList>
            </>
        )
    }

    return (
        <Container>
            {content}
        </Container>
    );
};
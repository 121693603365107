import { useEffect, useState } from "react";
import { TimeLineAccordion, TimelineAccordionItem } from "../../../components/TimelineAccordion";
import styled from "styled-components";
import { Loader } from "../../../components/Loader";

export const TextIcon = styled.div`
    font-family: 'Bebas Neue', sans-serif;
    font-size: 28px;
    font-weight: 500;
    color: #fff;
    text-align: center;
    padding-top: 4px;
`;

const timelineItems = [
    {
        key: 'club2015',
        icon: '2015',
        title: 'ОСНОВАНИЕ',
        imageUrl: 'https://fcrodina.com/static/images/timeline/4.jpg',
        shortDescription: 'Начиналось всё с сотрудничества с частной футбольной секцией "Школа Мяча"',
        description: (
            <>
                <p>
                    Начиналось всё с сотрудничества с частной футбольной секцией "Школа Мяча", и именно под этим названием наша <a href="https://fcrodina.com/academy">Академия</a> провела свой самый первый сезон в Чемпионате Москвы.
                </p>
                <p>
                    Стартовали с самой низшей на тот момент Третьей Лиги. Пройдет всего четыре года, и <a href="https://fcrodina.com/academy/">Академия</a> доберется до элитного дивизиона - Клубной Лиги, а <a href="https://academy.fcrodina.com/academy/team/?type=2&year=5">команда 2004 г.р.</a> установит уникальное достижение, выиграв на этом пути каждую из предшествующих высшей ступени лигу. Пять игроков из первого набора дойдут до выпуска и будут активно привлекаться к матчам <a href="https://fcrodina.com/rodina-m/">молодежной команды</a>.
                </p>
            </>
        ),
    },
    {
        key: 'club2016',
        icon: '2016',
        title: '3-й дивизион',
        imageUrl: 'https://fcrodina.com/static/images/timeline/1.jpg',
        shortDescription: 'На следующий год клуб обрел название "Родина", и к Академии добавилась взрослая команда',
        description: (
            <>
                <p>
                    На следующий год клуб обрел название "Родина", и к <a href="https://fcrodina.com/academy/">Академии</a> добавилась взрослая команда,
                    которая попробовала свои силы в турнире <a href="https://fcrodina.com/games/tables/?sezon=4&turnir=2">КФК</a> наряду
                    с такими соперниками как "Велес", молодежки "Строгино", "Химок", "Локомотива" и "Чертаново".
                </p>
                <p>
                    Опыт можно назвать разведкой боем. Закончив тот сезон на <a href="https://fcrodina.com/games/tables/?sezon=4&turnir=2">шестом месте</a>, в клубе было принято решение
                    сконцентрироваться в соответствии со стратегией развития на <a href="https://fcrodina.com/academy/">Академии</a> - вывести ее в Клубную Лигу,
                    чтобы выпускники получили необходимую конкурентную среду для дальнейшего перехода в
                    профессиональный футбол.
                </p>
            </>
        ),
    },
    {
        key: 'club2019',
        icon: '2019',
        title: 'Дебют в ПФЛ',
        imageUrl: 'https://fcrodina.com/static/images/timeline/2.png',
        shortDescription: 'Заявились в ПФЛ и дали выпускникам Академии ориентир для дальнейшего продолжения',
        description: (
            <>
                <p>
                    Обеспечив существенный задел в борьбе за выход в Клубную Лигу, пришла пора дать выпускникам
                    &nbsp;<a href="https://fcrodina.com/academy/">Академии</a> ориентир для дальнейшего продолжения
                    карьеры. Заявка в ПФЛ была одобрена всего за несколько недель до старта сезона 2019-20 гг.
                    Едва успев укомплектовать состав, команда отправляется в Санкт-Петербург на матч 1-го тура
                    против «Ленинградца», имея в заявке всего 11 полевых игроков.
                </p>
                <p>
                    Свой первый матч на профессиональном уровне родные завершили в ничью <a href="https://fcrodina.com/games/?game=5130">2:2</a>.
                    Дубль на счету капитана <a href="https://fcrodina.com/team/players/6045/?team=15">Султана Джамилова</a>,
                    &nbsp;<a href="https://fcrodina.com/news/na-povishenie/">перешедшего</a> по окончании сезона в
                    калининградскую «Балтику». Результативным пасом отметился выпускник Академии на тот момент 16-летний нападающий
                    &nbsp;<a href="https://fcrodina.com/team/players/6042/">Магомедхабиб Абдусаламов</a>.
                </p>
                <p>
                    Первую половину сезона «Родина» закончила на 8-м месте, имея в активе 6 побед, в том
                    числе и гостевую над лидером турнирной таблицы «Велесом», который впоследствии был
                    признан победителем турнира и в следующем сезоне шагнул на дивизион выше – в ФНЛ.
                </p>
                <p>
                    В период зимнего межсезонья команда Дениса Лактионова провела насыщенный сбор на Кипре,
                    во время которого приняла участие в двух коммерческих турнирах: <a href="https://fcrodina.com/games/?sezon=8&turnir=130">Korantina Homes Cup</a>
                    &nbsp;и <a href="https://fcrodina.com/games/?sezon=8&seasonId=9970c1d1-f9b3-42a6-b38b-cc68b63d9a27&turnir=131">Кубок ФНЛ</a>.
                </p>
                <p>
                    К сожалению, «собрать урожай» в официальных матчах второй половины того сезона не
                    представилось возможным. Первенство завершили
                    &nbsp;<a href="https://fcrodina.com/news/sezon-okonchen-zhdem-novogo/">досрочно</a>, и «Родина»
                    осталась на <a href="https://fcrodina.com/games/tables/?sezon=8&turnir=128">8-м месте</a>.
                </p>
            </>
        ),
    },
    {
        key: 'club2021',
        icon: '2021',
        title: 'Родной стадион',
        imageUrl: 'https://fcrodina.com/static/images/timeline/3.jpg',
        shortDescription: 'Шесть лет скитаний по арендным полям закончились летом 2021 года. Наконец-то, обрели свой',
        description: (
            <>
                <p>
                    Шесть лет скитаний по арендным полям закончились летом 2021 года. Клуб, наконец-то,
                    обрел свой собственный дом в лице <a href="http://fcrodina.local/stadium/">стадиона "Спартаковец"</a>.
                    Домашние стены действительно помогают. "Родина" не проиграла тут ни разу: две ничьи и двенадцать побед,
                    в том числе <a href="https://fcrodina.com/games/?game=5103">одна</a> еще в сезоне 2019-20, когда гостей здесь принимал еще "Велес".
                </p>
            </>
        ),
    },
    {
        key: 'club2022',
        icon: '2022',
        title: 'Мы в ФНЛ',
        imageUrl: 'https://fcrodina.com/static/images/timeline/5.jpg',
        shortDescription: 'Перед стартом третьего сезона команды во втором дивизионе, который летом 2021 года был',
        description: (
            <>
                <p>
                    Перед стартом третьего сезона команды во втором дивизионе, который летом 2021 года был
                    переименован из ПФЛ в ФНЛ-2, руководством клуба была поставлена цель выиграть турнир и
                    подняться лигой выше. Под эти требования был приглашен на пост главного тренера Денис
                    Бояринцев, уже имевший к тому времени успешный опыт решения такой задачи. В тандем к нему
                    на должность старшего тренера был назначен Михаил Белов, известный в первую очередь умением
                    развивать молодых игроков.
                </p>
                <p>
                    Точечные усиления в межсезонье, при этом, не ограничились исключительно приобретениями
                    на перспективу. Одним из ключевых трансферов
                    стал <a href="https://fcrodina.com/games/stat/?gamer=7783&sezon=12&turnir=146">Павел Аликин</a>.
                    Многоопытный центральный защитник, долгое время носивший капитанскую повязку в ФК «Уфа»,
                    отыграл весь сезон фактически от звонка до звонка и стал настоящим столпом обороны.
                    Показатель пропущенных голов упал с 1,53 в среднем за игру в предыдущем сезоне до 0,84,
                    что позволило
                    вратарю <a href="https://fcrodina.com/games/stat/?gamer=8267&sezon=12&turnir=146">Даниилу Баринову</a> установить
                    рекорд «Родины» - 4 «сухих» матча подряд.
                </p>
                <p>
                    В атаке, при этом, «Родина» по-прежнему радовала результативностью, одержав за сезон семь крупных
                    побед и выведя сразу трех игроков в топ-10 бомбардиров своей
                    группы: <a href="https://fcrodina.com/games/stat/?gamer=7806&sezon=12&turnir=146">Никиту Голдобина</a> (19 голов,
                    1 место), <a href="https://fcrodina.com/games/stat/?gamer=7742&sezon=12&turnir=146">Владислава Морозова</a> (11 голов,
                    4 место) и <a href="https://fcrodina.com/games/stat/?gamer=7805&sezon=12&turnir=146">Игоря Андреева</a> (10 голов,
                    9 место).
                </p>
                <p>
                    На финальную часть Первенства «Родина» вышла с отрывом в семь очков, добытых по итогам осенней стадии. Сократить его за остававшиеся 12 матчей преследователям не удалось, и после победы в Рязани команда Дениса Бояринцева за два тура до финиша оформила путевку в ФНЛ.
                </p>
                <p>
                    Сам же Денис Константинович за время своего пребывания в «Родине» установил клубный рекорд в виде беспроигрышной серии из 23-х матчей.
                </p>
            </>
        ),
    },
]

export const RodinaHistory = () => {
    const [storyItems, setStoryItems] = useState<TimelineAccordionItem[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);

        Promise.resolve().then(() => {
            const items: TimelineAccordionItem[] = [];

            timelineItems.forEach((item) => {
                const res: TimelineAccordionItem = {
                    key: item.key,
                    title: item.title,
                    shortDescription: item.shortDescription,
                    description: item.description,
                    imageUrl: item.imageUrl,
                    icon: <TextIcon>{item.icon}</TextIcon>,
                }

                items.push(res);
            });

            setStoryItems(items);

        }).finally(() => {
            setLoading(false);
        });
    }, []);

    if (loading) {
        return <Loader />;
    }

    return (
        <div>
            <TimeLineAccordion
                items={storyItems}
                activeIndex={storyItems?.length === 1 ? 0 : null}
            />
        </div>
    )
};

import { FC } from "react";
import { Banner, BannerContainer } from "./styled"

interface LeagueBannerProps {
    bannerSrc: string;
    bannerUrl: string;
    leagueName?: string;
    title?: string;
}
export const LeagueBanner: FC<LeagueBannerProps> = ({
    bannerSrc,
    bannerUrl,
    leagueName = 'Лига',
    title = "Перейти к турнирной таблице",
}) => {
    const linkTarget = bannerUrl.startsWith('http') ? '_blank' : '_self';
    return (
        <BannerContainer>
            <a href={bannerUrl} title={title} target={linkTarget}>
                <Banner src={bannerSrc} alt={leagueName} />
            </a>
        </BannerContainer>
    );
}
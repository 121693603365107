import { FC, useEffect, useState } from "react";
import { Container, Content, Hr, Sider, TableContainer } from "../style";
import { SummaryTableSelector } from "./TableSelector";
import { SummaryTableDto } from "../../../types/stats/summaryTables";
import { getSummaryTable } from "../../../api/transport/statsTransport";
import { Loader } from "../../../components/Loader";
import { TournamentTable } from "../../../components/TournamentTable";

export const SummaryTablesPage: FC = () => {
    const [selectedTableId, setSelectedTableId] = useState<number>();
    const [summaryTable, setSummaryTable] = useState<SummaryTableDto>();
    const [loading, setLoading] = useState(true);
    const onTableSelect = (summaryTableId: number) => {
        setSelectedTableId(summaryTableId);
        if (summaryTableId !== selectedTableId) {
            window.history.pushState({}, '', `?shows=${summaryTableId}`);
        }
    }

    useEffect(() => {
        setLoading(true);
        if (!selectedTableId) {
            return;
        }
        getSummaryTable(selectedTableId).then((data) => {
            setSummaryTable(data);
        }).finally(() => {
            setLoading(false)
        });
    }, [selectedTableId]);

    return (
        <Container>
            <SummaryTableSelector onSelect={onTableSelect} />
            <Hr />
            <Content>
                <TableContainer>
                    <div className="relative min-h-96">
                        {loading && (
                            <div className="absolute top-0 bottom-0 left-0 right-0 z-50 bg-white bg-opacity-75">
                                <Loader />
                            </div>
                        )}
                        <TournamentTable
                            data={summaryTable?.tableData || []}
                        />
                    </div>
                </TableContainer>
                <Sider>
                    <div className="text-xl text-center pb-2">Турниры:</div>
                    <div className="flex flex-col gap-2">
                        {summaryTable?.tournaments.map((t) => (
                            <a href={t.url} key={t.id} className="text-center p-3 hover:bg-gray-100">{t.name}</a>
                        ))}
                    </div>
                </Sider>
            </Content>
        </Container>
    )
}

import { PartnerDto } from "../../types/configuration/partners";
import { PartnerLink } from "./PartnerLink";
import { Container, PartnersGroup } from "./styled";

interface PartnersGroupProp {
    title?: string;
    partners: PartnerDto[];
}

interface PartnersProps {
    groups: Record<string, PartnersGroupProp>;
}

export const Partners: React.FC<PartnersProps> = ({ groups }) => {
    return (
        <Container>
            {Object.keys(groups).map((group) => (
                <>
                    {groups[group].title && <h2 className="text-white text-xl text-center p-2">{groups[group].title}</h2>}
                    <PartnersGroup key={group}>
                        {groups[group].partners.map((partner) => (
                            <PartnerLink
                                key={partner.id}
                                url={partner.url}
                                name={partner.name}
                                logo={partner.footerLogo}
                            />
                        ))}
                    </PartnersGroup>
                </>
            ))}
        </Container>
    );
};

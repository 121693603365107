import { PlayersStatsTableItem } from "../../../types/player";
import { FCRodinaTable as Table } from "../../Table";
import { TablePlayerName } from "../../Table/PlayerName";
import { Info } from "./styled";

interface PlayersStatsTableProps {
    data: PlayersStatsTableItem[];
}

export const PlayersStatsTable: React.FC<PlayersStatsTableProps> = ({ data }) => {
    const dataColumns = [
        { title: 'Игрок', dataIndex: 'name', key: 'name', left: true },
        { title: 'И', dataIndex: 'matches', key: 'matches' },
        { title: 'Г', dataIndex: 'goals', key: 'goals' },
        { title: 'А', dataIndex: 'assists', key: 'assists' },
        { title: 'ЖК', dataIndex: 'yellowCards', key: 'yellowCards' },
        { title: 'КК', dataIndex: 'redCards', key: 'redCards' },
        { title: 'ВЗ', dataIndex: 'matchesSubstIn', key: 'matchesSubstIn' },
        { title: 'БЗ', dataIndex: 'matchesSubstOut', key: 'matchesSubstOut' }
    ];

    const dataItems = data.map((item) => {
        return dataColumns.map((column) => {
            const value = item[column.dataIndex];
            if (column.dataIndex === 'name') {
                return {
                    key: column.key,
                    value: (
                        <TablePlayerName photoUrl={item.photo} name={value} url={item.url}/>
                    )
                }
            }
            return {
                key: column.key,
                value
            }
        });
    });

    return (
        <>
            <Info>
                <b>И</b> - игры, <b>Г</b> - голы, <b>А</b> - ассисты, <b>ЖК</b> - желтые карточки, <b>КК</b> - красные карточки, <b>ВЗ</b> - вышел на замену, <b>БЗ</b> - был заменен
            </Info>
            <Table
                columns={dataColumns}
                data={dataItems}
            />
        </>
    );
}


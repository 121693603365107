import { FC, useEffect, useState } from "react";
import { VacancyDto } from "../../../types/jobs";
import { ApplicationForm } from "./ApplicationForm"
import { VacancyContainer } from "./styled"
import { Vacancy } from "./Vacancy"
import { getVacancyById } from "../../../api/transport/jobsTransport";
import { Loader } from "../../../components/Loader";
import { Result } from "antd";
import { Button } from "../../../components/ui-kit/Button";
import { getUrlByContext } from "../../../utils";
import { useTeamContext } from "../../../hooks/useConfiguration";

interface VacancyPageProps {
    uuid?: string;
}

export const VacancyPage: FC<VacancyPageProps> = ({ uuid }) => {
    const [vacancyData, setVacancyData] = useState<VacancyDto | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const teamContext = useTeamContext();
    useEffect(() => {
        if (!uuid) return;
        setLoading(true);
        getVacancyById(uuid)
            .then((data) => {
                setVacancyData(data);
            }).finally(() => {
                setLoading(false);
            });
    }, [uuid]);

    if (loading) return (
        <VacancyContainer>
            <Loader />
        </VacancyContainer>
    )

    if (!vacancyData) return (
        <VacancyContainer>
            <Result
                status="404"
                title="Вакансия не найдена"
                subTitle={<>Извините, данная вакансия не найдена.<br/>Возможно, она уже не актуальна или удалена. Пожалуйста, ознакомьесь с другими нашими вакансиями.</>}
                extra={[
                    <Button size="large" onClick={() => window.open(getUrlByContext('/jobs/', teamContext), '_self')}>
                        Вернуться к списку вакансий
                    </Button>
                ]}
            />
        </VacancyContainer>
    )

    return (
        <VacancyContainer>
            <Vacancy
                title={vacancyData?.title}
                responsibilities={vacancyData.responsibilitiesText}
                requirements={vacancyData.requirementsText}
                functions={vacancyData.functions}
                additionalRequirements={vacancyData.additionalRequirementsText}
            />
            <ApplicationForm
                vacancyUuid={uuid}
            />
        </VacancyContainer>
    )
}


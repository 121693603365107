
export enum MatchResult {
    WIN = 'win',
    DRAW = 'draw',
    LOSS = 'loss',
    NOT_PLAYED = 'not_played',
}

export enum MatchStatus {
    UPCOMING = 'upcoming',
    IN_PROGRESS = 'in_progress',
    FINISHED = 'finished',
    СANCELED = 'canceled',
}

export interface MatchListItem {
    matchId: number;
    date: string;
    opponentTeamName: string;
    opponentTeamLogo: string;
    isHome: boolean;
    score: string;
    result: MatchResult;
    videoUrl: string;
    protocolUrl?: string;
    status: MatchStatus;
    tickets: string;
    preliminaryDate?: boolean;
    onCamp?: boolean;
}

export type MatchListDto = MatchListItem[];

export interface TeamDataShort {
    teamId: number;
    name: string;
    logo: string;
}

export interface CalendarEventItem {
    id: number;
    name: string;
    url: string;
    date: string;
    homeTeam: TeamDataShort;
    guestTeam: TeamDataShort;
    score: string;
    status: MatchStatus;
    tickets: string;
    matchUrl?: string;
    preliminaryDate?: boolean;
    onCamp?: boolean;
}

export type CalendarDto = CalendarEventItem[];

import reportWebVitals from './reportWebVitals';
import {
    AcademyEducationPageApp,
    AdministrativePageApp,
    CalendarCarouselApp,
    CalendarPageApp,
    Club100PageApp,
    CoachesPageApp,
    ContactsPageApp,
    ContactUsButtonApp,
    CosmosPromoLoginPageApp,
    CosmosPromoPageApp,
    CosmosPromoUploaderPageApp,
    ExPlayersPageApp,
    FreeTicketPromoApp,
    HomePageApp,
    LauncherPageApp,
    LeagueBannerApp,
    LegacyDesktopHeaderApp,
    MainScreenStatsApp,
    ManagementPageApp,
    MyContestsPageApp,
    MyMatchdayLandingActivitiesApp,
    MyMatchdayLandingFeedbackApp,
    MyMatchdayPageApp,
    MyTDesignPageApp,
    PartnersPageApp,
    PlayerProfilePageApp,
    PlayersStatsPageApp,
    AboutClubPageApp,
    SocialFeedApp,
    StadiumPageApp,
    StoryPopupApp,
    TeamPlayersPageApp,
    TestPageApp,
    TournamentTablePageApp,
    VacanciesListPageApp,
    VacancyPageApp,
    MyAppPageApp,
//    MatchCenterPageApp,
    LegacyDesktopFooterApp,
    SummaryTablesPageApp,
//    RootApp,
} from './_apps';
import {
    EditContestApp as AdminEditContestApp,
    AdminHeaderApp,
    ContestListApp,
    EditMatchdayActivityApp,
    MatchdayAdminApp,
    SubmitButtonApp
} from './_apps/admin';
import { ReactAppRenderer } from './utils/reactApp';
import { SmiPageApp } from './_apps/news';
import MatchProgrammePage from './pages/service/match-programme';
import FanMVPListPage from './pages/fan-mvp/page';
import { FanMVPListWidget } from './widgets/fan-mvp';
//import './index.css';

ReactAppRenderer(ContactUsButtonApp, 'fcrc__contactUsButton');
ReactAppRenderer(FreeTicketPromoApp, 'fcrc__freeTicketPromo');
ReactAppRenderer(LauncherPageApp, 'fcrc__launcher');

//Player page
ReactAppRenderer(PlayerProfilePageApp, 'fcrc__player');

//Stats pages
ReactAppRenderer(CalendarPageApp, 'fcrc__calendarPage');
ReactAppRenderer(TournamentTablePageApp, 'fcrc__tournamentTablePage');
ReactAppRenderer(PlayersStatsPageApp, 'fcrc__playersStatsPage');
ReactAppRenderer(SummaryTablesPageApp, 'fcrc__summaryTablesPage');

//Cosmos promo
ReactAppRenderer(CosmosPromoPageApp, 'fcrc__cosmosPromo');
ReactAppRenderer(CosmosPromoUploaderPageApp, 'fcrc__cosmosPromoUploader');
ReactAppRenderer(CosmosPromoLoginPageApp, 'fcrc__cosmosPromoLogin');

//Main screen widgets
ReactAppRenderer(CalendarCarouselApp, 'fcrc__calendarCarousel');
ReactAppRenderer(LeagueBannerApp, 'fcrc__leagueBanner');
ReactAppRenderer(MainScreenStatsApp, 'fcrc__mainScreenStats');
ReactAppRenderer(StoryPopupApp, 'fcrc__stories');

//My Rodina pages
ReactAppRenderer(MyContestsPageApp, 'fcrc__myContests');
ReactAppRenderer(MyMatchdayPageApp, 'fcrc__myMatchday');
ReactAppRenderer(MyMatchdayLandingActivitiesApp, 'fcrc__myMatchdayLandingActivities');
ReactAppRenderer(MyMatchdayLandingFeedbackApp, 'fcrc__myMatchdayLandingFeedback');
ReactAppRenderer(MyTDesignPageApp, 'fcrc__myTDesign');
ReactAppRenderer(MyAppPageApp, 'fcrc__myApp');

//Team pages
ReactAppRenderer(TeamPlayersPageApp, 'fcrc__teamPlayers');
ReactAppRenderer(ExPlayersPageApp, 'fcrc__exPlayers');
ReactAppRenderer(Club100PageApp, 'fcrc__club100');
ReactAppRenderer(CoachesPageApp, 'fcrc__coaches');

//Club pages
ReactAppRenderer(AboutClubPageApp, 'fcrc__aboutClub');
ReactAppRenderer(PartnersPageApp, 'fcrc__pagePartners');
ReactAppRenderer(VacanciesListPageApp, 'fcrc__jobs');
ReactAppRenderer(VacancyPageApp, 'fcrc__vacancy');
ReactAppRenderer(SocialFeedApp, 'fcrc__socialFeed');
ReactAppRenderer(AcademyEducationPageApp, 'fcrc__academyEducation');
ReactAppRenderer(StadiumPageApp, 'fcrc__stadium');
ReactAppRenderer(ContactsPageApp, 'fcrc__contacts');
ReactAppRenderer(ManagementPageApp, 'fcrc__management');
ReactAppRenderer(AdministrativePageApp, 'fcrc__administrative');

//Admin elements
ReactAppRenderer(AdminHeaderApp, 'fcrc__admin__header');
ReactAppRenderer(AdminEditContestApp, 'fcrc__admin__editContests');
ReactAppRenderer(SubmitButtonApp, 'fcrc__submitButton');
ReactAppRenderer(ContestListApp, 'fcrc__admin__contests');
ReactAppRenderer(EditMatchdayActivityApp, 'fcrc__admin__editMatchdayActivity');
ReactAppRenderer(MatchdayAdminApp, 'fcrc__admin__matchday');

ReactAppRenderer(TestPageApp, 'fcrc__testPage');
ReactAppRenderer(HomePageApp, 'fcrc__homePage');

//News
ReactAppRenderer(SmiPageApp, 'fcrc__smi');

//Layout elements
ReactAppRenderer(LegacyDesktopHeaderApp, 'fcrc__headerLegacy');
ReactAppRenderer(LegacyDesktopFooterApp, 'fcrc__footerLegacy');

ReactAppRenderer(MatchProgrammePage, 'fcrc__matchProgrammePage');
ReactAppRenderer(FanMVPListPage, 'fcrc__fanMVPListPage');
ReactAppRenderer(() => <FanMVPListWidget maxCount={3} showAllUrl='/fan-mvp/'/>, 'fcrc__fanMVPListWidget');

//ReactAppRenderer(MatchCenterPageApp, 'fcrc__matchCenter');

//ReactAppRenderer(RootApp, 'root');

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

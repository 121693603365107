import { ContactUsButton } from "../components/ContactUsButton";
import { FreeTicketPromo } from "../pages/FreeTicketPromo";
import { Configuration, TeamContext } from "../types/common";
import { PartnersWidget } from "../widgets/footer/Partners";
import { useConfiguration } from "../hooks/useConfiguration";
import LauncherPage from "../pages/launcher/page";

export const ContactUsButtonApp = () => {
    const config: Configuration = useConfiguration();
    if (config.teamName !== TeamContext.RODINA) {
        return null;
    }
    return (
        <ContactUsButton phoneNumber="+79032801727">
            Появился вопрос - спрашивай :)
        </ContactUsButton>
    );
};

export const FreeTicketPromoApp = () => {
    return (
        <FreeTicketPromo />
    );
};

export const PartnersApp = () => {
    const config: Configuration = useConfiguration();
    return (
        <PartnersWidget teamName={config.teamName} />
    );
}

 export const LauncherPageApp = () => {
    return <LauncherPage />;
}

export const TestPageApp = () => {
    return <div className="min-w-full min-h-96">Test page</div>;
}

export * from './cosmos';
export * from './mainScreen';
export * from './stats';
export * from './team';
export * from './my';
export * from './club';
export * from './layout';
export * from './news';
import { FC } from "react";
import { BottomMenuItem, BottomMenuItemProps } from "./BottomMenuItem";

interface BottomMenuProps {
    items: Omit<BottomMenuItemProps, 'target'>[];
}

export const BottomMenu: FC<BottomMenuProps> = ({
    items
}) => {
    return (
        <footer className="box-border fixed bottom-0 left-0 right-0 bg-sky-950/95 text-white font-bold w-full border-t border-sky-950 border-solid border-t-white z-50">
            <div className="flex justify-around max-w-xl mx-auto pb-safe-bottom">
                {items.map((item, index) => (
                    <BottomMenuItem key={index} {...item} /*target={item.href.startsWith('http') ? '_blank' : '_self'}*/ />
                ))}
            </div>
        </footer>
    );
}
import { TeamContext } from "../common";

export interface PartnerDto {
    id: number;
    name: string;
    shortName: string;
    logo: string;
    footerLogo?: string;
    url: string;
    description?: string;
    sortOrder: number;
    teams?: TeamContext[];
    group?: PartnerGroup;
}

export enum PartnerGroup {
    CLUB = 'club',
    LEAGUE = 'league',
}

export interface PartnersGroupsDto {
    [PartnerGroup.CLUB]: PartnerDto[];
    [PartnerGroup.LEAGUE]: PartnerDto[];
}

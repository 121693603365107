import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    //padding: 20px;
    min-height: 500px;
    padding-top: 32px;
    padding-bottom: 64px;
    width: 100%;
    box-sizing: border-box;
`;

export const PageTitle = styled.h1`
    font-family: 'Bebas Neue', sans-serif;
    margin: 0;
    font-size: 26px;
    font-weight: 600;
    text-align: left;
    margin-bottom: 24px;
    color: #133D5E;
`;
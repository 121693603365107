import styled from 'styled-components';
import { Spin as AntSpin } from 'antd';

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 360px;
    width: 100%;
`;

interface TitleProps {
    $color: string;
}

export const Title = styled.h1<TitleProps>`
    font-family: 'Bebas Neue', sans-serif;
    font-size: 18px;
    font-weight: 600;
    color: ${({ $color }) => $color};
    margin-top: 18px;
`;

interface SpinProps {
    $color: string;
}

export const Spin = styled(AntSpin)<SpinProps>`
    .ant-spin-dot-item {
        background-color: ${({ $color }) => $color};
    }
`

import { FC, ReactNode } from "react";

interface TopPanelProps {
    clubLinks?: ReactNode;
    socialLinks?: ReactNode;
    additionalLinks?: ReactNode;
    rightSideElements?: ReactNode;
}

export const TopPanel: FC<TopPanelProps> = ({
    clubLinks,
    socialLinks,
    additionalLinks,
    rightSideElements,
}) => {
    return (
        <div className="bg-slate-900 w-full text-white flex flex-row justify-center p-2 box-border" style={{ fontFamily: 'Futura, sans-serif' }}>
            <div className="w-full max-w-[1024px] flex flex-row justify-between items-center">
                <div className="flex flex-row items-center gap-2">
                    {clubLinks}
                    {additionalLinks}
                    {socialLinks}
                </div>
                <div>
                    {rightSideElements}
                </div>
            </div>
        </div>
    );
}
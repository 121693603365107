import { FC, ReactNode, useEffect, useState } from "react";
import { TeamContext } from "../../types/common";
import { TournamentSelector } from "../../components/TournamentSelector";
import { StatsTournamentSelectorsDto, StatsType, TournamentStageType } from "../../types/stats";
import { getSelectors, getTournamentStages } from "../../api/transport/statsTransport";
import { Container, Content, Hr, Sider, TableContainer } from "./style";
import { ConfigProvider, Menu } from "antd";
import { CalendarOutlined, SkinOutlined, TableOutlined } from "@ant-design/icons";
import { getUrlByContext } from "../../utils";
import { MenuItemType } from "antd/es/menu/interface";
import { SummaryTableMiniWidget } from "../../widgets/stats/tournament-table/SummaryTableMiniWidget";

interface StatsPageProps {
    teamContext?: TeamContext;
    pageType?: StatsType;
    children?: ReactNode;
    onTournamentChange?: (tournamentUuid: string, seasonUuid: string, legacyTournament?: number, legacySeason?: number) => void;
}

export const StatsPage: FC<StatsPageProps> = ({
    teamContext,
    pageType,
    onTournamentChange,
    children,
}) => {
    const [selectorOptions, setSelectorOptions] = useState<StatsTournamentSelectorsDto>();
    const [menuItems, setMenuItems] = useState<MenuItemType[]>([]);
    const [legacyTournamentId, setLegacyTournamentId] = useState<number>();

    useEffect(() => {
        getSelectors(teamContext).then((data) => {
            setSelectorOptions(data);
        }).catch(() => {
            setSelectorOptions(undefined);
        });
    }, []);

    if (!selectorOptions) {
        return null;
    }

    const searchParams = new URLSearchParams(window.location.search);

    const onTournamentSelect = async (tournamentUuid: string, seasonUuid: string, legacyTournament?: number, legacySeason?: number) => {
        if (onTournamentChange) {
            onTournamentChange(tournamentUuid, seasonUuid, legacyTournament, legacySeason);
            setLegacyTournamentId(legacyTournament);
        }
        if (!tournamentUuid) {
            return;
        }

        const menu = [
            {
                key: StatsType.CALENDAR,
                icon: <CalendarOutlined />,
                label: (
                    <a href={getUrlByContext(`/games/?${searchParams.toString()}`, teamContext)}>
                        Календарь
                    </a>
                ),
            },
        ]

        const stages = await getTournamentStages(tournamentUuid);

        if (stages?.filter(s => s.type === TournamentStageType.REGULAR)?.length) {
            menu.push({
                key: StatsType.TOURNAMENT_TABLE,
                icon: <TableOutlined />,
                label: (
                    <a href={getUrlByContext(`/games/tables/?${searchParams.toString()}`, teamContext)}>
                        Таблица
                    </a>
                ),
            });
        }

        menu.push({
            key: StatsType.PLAYER,
            icon: <SkinOutlined />,
            label: (
                <a href={getUrlByContext(`/games/stat/?${searchParams.toString()}`, teamContext)}>
                    Статистика игроков
                </a>
            ),
        });

        setMenuItems(menu);
    }

    let siderContent = null;
    if (teamContext === TeamContext.ACADEMY && legacyTournamentId) {
        siderContent = (
            <div className="px-2 pb-4">
                <hr className="border-gray-100"/>
                <SummaryTableMiniWidget
                    //summaryTableId={config.summaryTableId}
                    tournamentLegacyId={legacyTournamentId}
                />
            </div>
        );
    }

    return (
        <Container className="pb-4">
            <TournamentSelector
                selectorOptions={selectorOptions}
                onSelect={onTournamentSelect}
            />
            <Hr />
            <Content>
                <TableContainer>
                    {children}
                </TableContainer>
                {!!menuItems?.length && <Sider>
                    <ConfigProvider
                        theme={{
                            components: {
                                Menu: {
                                    /* here is your component tokens */
                                    itemBorderRadius: 2,
                                    itemSelectedBg: '#133D5E',
                                    itemSelectedColor: '#fff',
                                    fontSize: 16,
                                    itemHeight: 46,
                                },
                            },
                        }}
                    >
                        <Menu
                            selectedKeys={[pageType]}
                            items={menuItems}
                        />
                    </ConfigProvider>
                    {siderContent}
                </Sider>}
            </Content>
        </Container>
    )
}
import { ButtonContainer, ButtonContent, IconContainer } from "./styled";

interface FCRodinaButtonProps {
    children: React.ReactNode;
    onClick?: () => void;
    style?: React.CSSProperties;
    className?: string;
    size?: "small" | "medium" | "large" | "xl";
    disabled?: boolean;
    icon?: React.ReactNode;
    tooltip?: string;
}

export const FCRodinaButton: React.FC<FCRodinaButtonProps> = ({
    children,
    onClick,
    style,
    size = "medium",
    disabled = false,
    icon,
    className,
    tooltip,
}) => {
    return (
        <ButtonContainer
            onClick={onClick}
            style={{...style}}
            $size={size}
            disabled={disabled}
            className={className}
            title={tooltip}
        >
            <ButtonContent>
                {icon && <IconContainer>{icon}</IconContainer>}
                {children}
            </ButtonContent>
        </ButtonContainer>
    );
}
import styled from "styled-components";
import { Select as AntSelect } from "antd";

export const Select = styled(AntSelect)`
    .ant-select-selector {
        border-radius: 2px !important;
        font-size: 14px !important;
    }
    .ant-select-item-option-content {
        white-space: normal !important;
    }
`;
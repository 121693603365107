import { FC } from "react";
import { RadioGroup } from "../../../components/RadioGroup";
import { StatParams } from "../../../types/player";

interface StatParamsRadioGroupProps {
    value: string;
    onChange: (value: string) => void;
    params: StatParams[];
}

const PARAMS_NAMES_MAP = {
    [StatParams.MATCHES]: 'Все',
    [StatParams.CLEAN_SHEETS]: 'Сухие',
    [StatParams.GOALS]: 'Голы',
    [StatParams.ASSISTS]: 'Ассисты',
    [StatParams.YELLOW_CARDS]: 'ЖК',
    [StatParams.RED_CARDS]: 'КК',
    [StatParams.MINUTES_PLAYED]: 'Минуты',
    [StatParams.PENALTIES_SAVED]: 'Отбитые пенальти',
};

export const StatParamsRadioGroup: FC<StatParamsRadioGroupProps> = ({ value, onChange, params }) => {
    const options = params.map((param) => {
        return {
            key: param,
            label: PARAMS_NAMES_MAP[param] || param,
        };
    });

    return (
        <RadioGroup
            options={options}
            value={value}
            onChange={onChange}
        />
    );
}

import { Form, Input, InputNumber, Checkbox, message, Result } from "antd";
import { FC, useState } from "react";
import { FCRodinaButton } from "../../../../components/Button";
import { ActivityButton, KidsApplicationFormContainer } from "./styled";
import { submitKidsApplication } from "../../../../api/transport/matchdayTransport";
import { ItemActivityButtons } from "./ItemActivityButtons";

interface KidsApplicationFormProps {
    buyTicketUrl?: string;
}

export const KidsApplicationForm: FC<KidsApplicationFormProps> = ({
    buyTicketUrl,
}) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    //const [errorMessage, setErrorMessage] = useState("");
    /*const [ticketNumber, setTicketNumber] = useState("");
    const [ticketNumberError, setTicketNumberError] = useState(false);
    const [ticketNumberErrorMessage, setTicketNumberErrorMessage] = useState("");
    const [isTicketNumberValid, setIsTicketNumberValid] = useState(false);*/

    const onFinish = async (values: any) => {
        setLoading(true);
        setError(false);
        //setSuccess(false);
        //setErrorMessage("");
        try {
            await submitKidsApplication(values);
            message.success("Ваша заявка принята");
            setSuccess(true);
        } catch (error) {
            setError(true);
            message.error("Ошибка при отправке заявки");
            console.error(error);
            //setErrorMessage(error?.response?.data?.message);
        }
        setLoading(false);
    };

    /*const validateTicketNumber = async (rule: any, value: string) => {
        if (!value) {
            setTicketNumberError(true);
            setTicketNumberErrorMessage("Пожалуйста, введите номер билета");
            setIsTicketNumberValid(false);
            return Promise.reject("Пожалуйста, введите номер билета");
        }
        try {
            const response = await getTicketNumber(value);
            /if (response.status === 200) {
                setTicketNumberError(false);
                setTicketNumberErrorMessage("");
                setIsTicketNumberValid(true);
            }
        } catch (error) {
            setTicketNumberError(true);
            setTicketNumberErrorMessage("Invalid ticket number");
            setIsTicketNumberValid(false);
            return Promise.reject("Invalid ticket number");
        }

        return Promise.resolve();
    };*/

    if (success) {
        return (
            <Result
                title="Ваша заявка принята"
                subTitle={`Мы свяжемся с вами по указанному телефону или email в случае выбора вашей заявки`}
                status="success"
            />
        );
    }

    if (error) {
        return (
            <Result
                status="error"
                title="Ошибка"
                subTitle="К сожалению, при отправке заявки произошла ошибка, попробуйте повторить позднее"
            />
        );
    }

    return (
        <KidsApplicationFormContainer>
            <Form
                form={form}
                name="kidsApplication"
                onFinish={onFinish}
                wrapperCol={{ span: 16 }}
                labelCol={{ span: 6 }}
                size="large"
            >
                <Form.Item
                    label="Имя ребенка"
                    name="childName"
                    rules={[
                        {
                            required: true,
                            message: "Пожалуйста, введите имя ребенка",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Возраст ребёнка"
                    name="age"
                    rules={[
                        {
                            required: true,
                            message: "Пожалуйста, введите возраст",
                        },
                    ]}
                    help="От 6 до 10 лет"
                >
                    <InputNumber min={6} max={10} defaultValue={6} />
                </Form.Item>
                <Form.Item
                    label="Имя родителя"
                    name="parentName"
                    rules={[
                        {
                            required: true,
                            message: "Пожалуйста, введите имя родителя",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Телефон родителя"
                    name="parentPhone"
                    rules={[
                        {
                            required: true,
                            message: "Пожалуйста, введите телефон родителя",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Email родителя"
                    name="parentEmail"
                    rules={[
                        {
                            required: true,
                            message: "Пожалуйста, введите email родителя",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Номер билета"
                    name="ticketNumber"
                    rules={[
                        {
                            required: true,
                            message: "Пожалуйста, введите номер билета",
                        },
                        /*{
                            validator: validateTicketNumber,
                        },*/
                    ]}
                //validateStatus={ticketNumberError ? "error" : ""}
                //help={ticketNumberErrorMessage}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="consent"
                    valuePropName="checked"
                    label=" "
                    colon={false}
                    rules={[
                        {
                            validator: (_, value) =>
                                value
                                    ? Promise.resolve()
                                    : Promise.reject("Пожалуйста, подтвердите согласие"),
                        },
                    ]}
                >
                    <Checkbox>Я даю согласие на обработку персональных данных</Checkbox>
                </Form.Item>
                <ItemActivityButtons buyTicketUrl={buyTicketUrl}>
                    <ActivityButton
                        onClick={() => form.submit()}
                        disabled={loading}
                        className="border-0 cursor-pointer flex flex-row gap-2 w-fit max-h-fit items-center justify-center mb-1 transition-all hover:scale-105 rounded-sm text-white bg-sky-950 hover:bg-sky-900 hover:no-underline hover:text-white hover:shadow py-4 px-8 text-2xl uppercase font-bold"
                    >
                        <span className="pt-1">
                            Отправить анкету
                        </span>
                    </ActivityButton>
                </ItemActivityButtons>
            </Form>
        </KidsApplicationFormContainer>
    );
}

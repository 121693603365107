import { FC } from "react";
import { Partner, PartnerLogo } from "./styled";

interface PartnerLinkProps {
    url: string;
    logo: string;
    name: string;
    className?: string;
}

export const PartnerLink: FC<PartnerLinkProps> = ({
    url,
    logo,
    name,
    className
}) => {
    return (
        <Partner
            $disabled={!url}
            onClick={() => url && window.open(url, '_blank')}
            title={name}
            className={className}
        >
            <PartnerLogo src={logo} />
        </Partner>
    );
}

import { FC, useEffect, useState } from "react";
import { BuyButton, Container } from "./styled";
import { getTicketsConfig } from "../../../api/transport/configurationTransport";
import { Image } from "antd";
import { Icon, IconType } from "../../../components/Icon";
import { BuyTicketButton } from "../../../components/tickets/BuyTicketButton";
import { getStaticUrl } from "../../../utils";

interface TicketBannerWidgetProps {
    buyButton?: boolean;
}
export const TicketBannerWidget: FC<TicketBannerWidgetProps> = ({ buyButton }) => {
    const [imageUrl, setImageUrl] = useState("");
    const [ticketUrl, setTicketUrl] = useState("");
    useEffect(() => {
        getTicketsConfig().then((response) => {
            setImageUrl(getStaticUrl(response.bannerUrl));
            setTicketUrl(response.rodinaTicketsUrl);
        });
    }, []);

    if (!imageUrl) {
        return null;
    }

    return (
        <Container className="hidden sm:block">
            <Image src={imageUrl} alt='Билет' preview={false} />
            {buyButton && (
                <div className="absolute bottom-10 right-10">
                    <BuyTicketButton
                        icon
                        url={ticketUrl}
                        size="3xl"
                        color="green"
                        title="Купить билет"
                    />
                </div>
            )}
        </Container>
    );
}

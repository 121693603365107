import { FC } from "react";
import { Link } from "react-router";

interface ItemLinkProps {
    to: string;
    children: React.ReactNode;
    standalone?: boolean;
    className?: string;
}

export const ItemLink: FC<ItemLinkProps> = ({ to, children, standalone, className = '' }) => {
    if (!standalone) {
        return (
            <a href={to} className={`hover:no-underline ${className}`}>
                {children}
            </a>
        );
    }

    return (
        <Link to={to} className={`hover:no-underline ${className}`}>
            {children}
        </Link>
    );
}
import { FC, useEffect, useState } from "react";
import { CalendarCarousel } from "../../../components/CalendarCarousel";
import { CalendarDto } from "../../../types/stats";
import { getCalendar } from "../../../api/transport/statsTransport";
import { TeamContext } from "../../../types/common";
import { getUrlByContext } from "../../../utils";

interface CalendarCarouselWidgetProps {
    tournamentId?: number;
    seasonId: number;
    teamId?: number;
    teamName?: TeamContext;
}

export const CalendarCarouselWidget:FC<CalendarCarouselWidgetProps> = ({
    tournamentId,
    seasonId,
    teamId,
    teamName,
}) => {
    const [mathcList, setMatchList] = useState<CalendarDto>([]);

    useEffect(() => {
        getCalendar(seasonId, teamId, teamName).then((data) => {
            setMatchList(data);
        });
    }, [seasonId, tournamentId, teamId, teamName]);

    return (
        <CalendarCarousel matches={mathcList} lastItemLink={getUrlByContext('/games/', teamName)} />
    );
}
import styled from "styled-components";
import { Button } from "antd";

export const SubmitButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 20px 0;
`;

export const SubmitButton = styled(Button)``;
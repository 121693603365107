import { FC } from "react";
import { PlayerNameContainer, PlayerPhoto, TextWrapper } from "./styled";

interface TablePlayerNameProps {
    photoUrl: string;
    url?: string;
    name: string;
}

export const TablePlayerName: FC<TablePlayerNameProps> = ({photoUrl, name, url}) => {
    return (
        <PlayerNameContainer onClick={() => !!url && window.open(url, '_self')}>
            <PlayerPhoto src={photoUrl} />
            <TextWrapper>{name}</TextWrapper>
        </PlayerNameContainer>
    );
}
import styled from "styled-components";

export const Container = styled.div`
`;

export const Hr = styled.hr`
    border: none;
    border-top: 1px solid #99c6e1;
    opacity: 0.5;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 20px;
    max-width: 100%;
    gap: 8px;
`;

export const Sider = styled.div`
    width: 220px;
    min-width: 220px;
`;

export const TableContainer = styled.div`
    width: 100%;
`;
import { useConfiguration } from "../hooks/useConfiguration";
import { AcademyEducationPage } from "../pages/club/academy-education";
import AdministrativePage from "../pages/club/administrative/page";
import { ContactsPage } from "../pages/club/contacts/page";
import ManagementPage from "../pages/club/management/page";
import { PartnersPage } from "../pages/club/partners/page";
import RodinaAboutClubPage from "../pages/club/rodina/page";
import { StadiumPage } from "../pages/club/stadium";
import VacanciesListPage from "../pages/jobs/page";
import { VacancyPage } from "../pages/jobs/vacancy/page";
import { Configuration } from "../types/common";
import { getPathParameter } from "../utils";

export const PartnersPageApp = () => {
    const config: Configuration = useConfiguration();
    return (
        <PartnersPage teamName={config?.teamName} />
    );
}

export const VacanciesListPageApp = () => {
    return (
        <VacanciesListPage />
    );
}

export const VacancyPageApp = () => {
    return (
        <VacancyPage uuid={getPathParameter(window.location.pathname)}/>
    );
}

export const AcademyEducationPageApp = () => {
    return (
        <AcademyEducationPage />
    );
}

export const StadiumPageApp = () => {
    return (
        <StadiumPage />
    );
}

export const ContactsPageApp = () => {
    return (
        <ContactsPage />
    );
}

export const ManagementPageApp = () => {
    return (
        <ManagementPage />
    );
}

export const AdministrativePageApp = () => {
    return (
        <AdministrativePage />
    );
}

export const AboutClubPageApp = () => {
    return (
        <RodinaAboutClubPage />
    );
}

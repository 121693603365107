import { FC, ReactNode } from "react";
import {
    ElementWrapper,
    EventDate,
    EventName,
    ResultContainer,
    Score,
    ScoreInner,
    TeamInfo,
    TeamLogo,
    TeamName
} from "./styled";
import { BuyTicketButton } from "../tickets/BuyTicketButton";
import { getStaticUrl } from "../../utils";


interface CalendarEventProps {
    name: string;
    url: string;
    date: string;
    team1: {
        name: string;
        logo: string;
    };
    team2: {
        name: string;
        logo: string;
    };
    score?: ReactNode;
    matchUrl?: string;
    ticketUrl?: string;
}

export const CalendarEvent: FC<CalendarEventProps> = ({
    name,
    url,
    date,
    team1,
    team2,
    score,
    matchUrl,
    ticketUrl,
}) => {
    return (
        <ElementWrapper>
            <EventName onClick={() => window.open(url, '_self')}>{name}</EventName>
            <EventDate>
                {date}
                {ticketUrl && <BuyTicketButton url={ticketUrl} color="green" icon size="sm" className="max-h-5"/>}
            </EventDate>
            <ResultContainer>
                <TeamInfo>
                    <TeamLogo src={getStaticUrl(team1.logo)} />
                    <TeamName>{team1.name}</TeamName>
                </TeamInfo>
                {score && <Score onClick={() => window.open(matchUrl, '_self')}>
                    <ScoreInner>
                        {score}
                    </ScoreInner>
                </Score>}
                {!score && <Score>-</Score>}
                <TeamInfo>
                    <TeamLogo src={getStaticUrl(team2.logo)} />
                    <TeamName>{team2.name}</TeamName>
                </TeamInfo>
            </ResultContainer>
        </ElementWrapper>
    );
}
import { FC, useEffect, useState } from "react";
import { Container, HR, PartnersGroup, PartnersGroupTitle } from "./styled";
import { getPartners } from "../../../api/transport/configurationTransport";
import { PartnersGroupsDto, PartnerGroup } from "../../../types/configuration/partners";
import { Loader } from "../../../components/Loader";
import { PartnerElement } from "./PartnerElement";
import { TeamContext } from "../../../types/common";

const GROUP_NAME = {
    club: '',
    league: '',
};

interface PartnersPageProps {
    teamName?: TeamContext;
}

export const PartnersPage: FC<PartnersPageProps> = ({
    teamName,
}) => {
    const [partners, setPartners] = useState<PartnersGroupsDto>();
    const [partnerGroupsNames, setPartnerGroupNames] = useState<Record<PartnerGroup, string>>(GROUP_NAME)
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getPartners(teamName).then((data) => {
            setPartners(data);
        }).finally(() => {
            setLoading(false);
        });
        if (teamName === TeamContext.ACADEMY) {
            setPartnerGroupNames({
                [PartnerGroup.CLUB]: '',
                [PartnerGroup.LEAGUE]: 'Лига',
            })
        }
    }, [teamName]);

    if (!partners) {
        return null;
    }

    if (loading) {
        return <Loader />;
    }

    return (
        <Container>
            {Object.keys(partners).map((group) => {
                const list = partners[group];

                if (!list.length) {
                    return null;
                }

                const title = partnerGroupsNames?.[group] || '';
                return (
                    <PartnersGroup key={group}>
                        {title && <PartnersGroupTitle>{title}</PartnersGroupTitle>}
                        {list.map((partner) => (
                            <>
                                <PartnerElement
                                    key={partner.name}
                                    logo={partner.logo}
                                    name={partner.name}
                                    link={partner.url}
                                    description={partner.description}
                                />
                                <HR />
                            </>
                        ))}
                    </PartnersGroup>
                )
            }
            )}
        </Container>
    );
}
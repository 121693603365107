import { BACKEND_URL } from "..";
import { LauncherScheduleItemDto } from "../../types/launcher";

export const getLauncherLive = async (): Promise<LauncherScheduleItemDto | null> => {
    const response = await fetch(BACKEND_URL + '/launcher/schedule/live', {
        method: "GET",
        headers: {
            'Content-Type': 'application/json'
        },
    });

    const data = await response.json();

    if (response.status !== 200) {
        console.error(data);
        return null;
    }

    return data;
}
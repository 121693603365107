import { BACKEND_URL } from "..";
import { TeamContext } from "../../types/common";
import { FeatureNewsDto, SmiNewsLinkDto } from "../../types/news";

export const getFeaturedNews = async (teamContext: TeamContext = TeamContext.RODINA): Promise<FeatureNewsDto[]> => {
    const response = await fetch(`${BACKEND_URL}/news/featured/list-by-team/${teamContext}`);
    const data = await response.json();
    return data;
}

export const getSmiNewsLinks = async (): Promise<SmiNewsLinkDto[]> => {
    const response = await fetch(`${BACKEND_URL}/news/smi/list`);
    const data = await response.json();
    return data;
}
import { FC } from "react";
import { Icon as StyledIcon } from "./styled";

interface IconProps {
    type: IconType;
    size?: number;
    color?: string;
}

export enum IconType {
    Ticket = 'ticket',
    Shop = 'shop',
    BirtDate = 'birthDate',
}

export const Icon: FC<IconProps> = ({ type, size = 20, color = 'black' }) => {
    let src = '';
    if (type === IconType.Ticket) {
        src="https://fcrodina.com/i/tickwh.png";
    }

    if (!src) {
        return null;
    }

    return <StyledIcon $size={size} src={src}/>;
};

export const BirthDateIcon = () => (
    <svg fill="currentColor" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
        width="1em" height="1em" viewBox="796 796 200 200" enableBackground="new 796 796 200 200">
        <g>
            <path d="M986.55,972.536h-9.635v-61.1c0-14.9-10.702-27.347-24.824-30.06c-0.758-0.145-1.308-0.808-1.308-1.58v-35.494
		c0-5.074-4.113-9.187-9.188-9.187c-5.073,0-9.188,4.113-9.188,9.187v34.913c0,0.89-0.721,1.61-1.61,1.61h-24.241
		c-0.89,0-1.61-0.721-1.61-1.61v-34.913c0-5.074-4.113-9.187-9.188-9.187c-5.075,0-9.188,4.113-9.188,9.187v34.913
		c0,0.89-0.721,1.61-1.611,1.61h-24.241c-0.89,0-1.611-0.721-1.611-1.61v-34.913c0-5.074-4.113-9.187-9.188-9.187
		s-9.188,4.113-9.188,9.187v35.591c0,0.763-0.536,1.419-1.283,1.575c-13.893,2.892-24.365,15.229-24.365,29.967v61.1h-9.633
		c-4.769,0-8.635,3.865-8.635,8.635c0,4.768,3.866,8.634,8.635,8.634H986.55c4.768,0,8.634-3.866,8.634-8.634
		C995.184,976.401,991.317,972.536,986.55,972.536z M845.694,892.782h100.611c10.285,0,18.654,8.368,18.654,18.654v16.425
		c0,0.89-0.722,1.61-1.611,1.61H828.65c-0.89,0-1.611-0.721-1.611-1.61v-16.425C827.039,901.15,835.408,892.782,845.694,892.782z
		 M827.039,943.038c0-0.89,0.721-1.611,1.611-1.611h134.698c0.89,0,1.611,0.722,1.611,1.611v27.887c0,0.89-0.722,1.611-1.611,1.611
		H828.65c-0.89,0-1.611-0.722-1.611-1.611V943.038z"/>
            <path d="M849.916,830.671c2.423,0,4.611-0.996,6.186-2.598l0,0c0.011-0.011,0.019-0.024,0.031-0.036
		c0.211-0.217,0.408-0.445,0.596-0.684c0,0,0-0.001,0.001-0.002c0.186-0.236,0.358-0.482,0.519-0.735
		c0.009-0.016,0.018-0.031,0.027-0.045c0.146-0.235,0.281-0.475,0.405-0.724c0.013-0.024,0.025-0.05,0.036-0.076
		c0.114-0.234,0.217-0.472,0.31-0.717c0.013-0.037,0.027-0.075,0.041-0.11c0.079-0.219,0.15-0.442,0.212-0.668
		c0.023-0.085,0.045-0.171,0.065-0.257c0.037-0.15,0.072-0.302,0.099-0.455c1.479-7.534-5.868-17.257-8.972-20.077
		c-3.185-2.894-6.353-0.494-4.615,2.905c1.953,3.816,1.557,6.402-0.974,9.376c-0.291,0.28-0.557,0.583-0.807,0.902
		c-0.013,0.014-0.024,0.028-0.038,0.043l0.003,0.004c-1.125,1.462-1.799,3.289-1.799,5.276
		C841.24,826.787,845.124,830.671,849.916,830.671z"/>
            <path d="M895.735,830.671c2.423,0,4.612-0.996,6.187-2.598v0c0.012-0.011,0.021-0.024,0.03-0.036
		c0.211-0.217,0.408-0.445,0.597-0.684c0.001,0,0.001-0.001,0.001-0.002c0.186-0.236,0.358-0.482,0.52-0.735
		c0.008-0.016,0.018-0.031,0.026-0.045c0.146-0.235,0.28-0.475,0.404-0.724c0.013-0.024,0.024-0.05,0.036-0.076
		c0.114-0.234,0.215-0.472,0.309-0.717c0.013-0.037,0.028-0.075,0.042-0.11c0.077-0.219,0.149-0.442,0.211-0.668
		c0.023-0.085,0.045-0.171,0.066-0.257c0.036-0.15,0.07-0.302,0.099-0.455c1.478-7.534-5.869-17.257-8.974-20.077
		c-3.185-2.894-6.354-0.494-4.615,2.905c1.953,3.816,1.558,6.402-0.974,9.376c-0.29,0.28-0.557,0.583-0.806,0.902
		c-0.014,0.014-0.024,0.028-0.039,0.043l0.004,0.004c-1.125,1.462-1.8,3.289-1.8,5.276
		C887.06,826.787,890.943,830.671,895.735,830.671z"/>
            <path d="M941.573,830.671c2.423,0,4.612-0.996,6.188-2.598v0c0.01-0.011,0.019-0.024,0.028-0.036
		c0.212-0.217,0.41-0.445,0.598-0.684c0.002,0,0.002-0.001,0.002-0.002c0.186-0.236,0.357-0.482,0.518-0.735
		c0.009-0.016,0.019-0.031,0.026-0.045c0.146-0.235,0.282-0.475,0.404-0.724c0.014-0.024,0.025-0.05,0.037-0.076
		c0.113-0.234,0.216-0.472,0.309-0.717c0.013-0.037,0.027-0.075,0.041-0.11c0.078-0.219,0.149-0.442,0.212-0.668
		c0.023-0.085,0.046-0.171,0.064-0.257c0.038-0.15,0.072-0.302,0.101-0.455c1.478-7.534-5.869-17.257-8.975-20.077
		c-3.184-2.894-6.352-0.494-4.613,2.905c1.952,3.816,1.558,6.402-0.976,9.376c-0.29,0.28-0.557,0.583-0.807,0.902
		c-0.013,0.014-0.023,0.028-0.039,0.043l0.006,0.004c-1.124,1.462-1.801,3.289-1.801,5.276
		C932.897,826.787,936.781,830.671,941.573,830.671z"/>
        </g>
    </svg>
);
import { Card } from "antd";
import { Button } from "../../components/ui-kit/Button";
import { FC, useCallback } from "react";

interface VacancListItemProps {
    title: string;
    url: string;
    description: string;
}

export const VacancyListItem: FC<VacancListItemProps> = ({
    title,
    url,
    description,
}: VacancListItemProps) => {
    const onSelect = useCallback(() => {
        window.open(url, '_self');
    }, [url]);
    return (
        <div className="flex flex-col gap-4 w-full max-w-2xl p-8 border border-gray-200 border-solid rounded-md box-border cursor-pointer hover:shadow-lg transition-shadow" onClick={onSelect}>
            <div className="text-3xl font-black">{title}</div>
            <div className="text-xl">{description}</div>
            <div className="pt-3 flex justify-end">
                <Button size="large" onClick={onSelect}>
                    Откликнуться
                </Button>
            </div>
        </div>
    );
}
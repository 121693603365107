import { motion } from "framer-motion";
import PageTemplate from "../../components/layout/PageTemplate";
import { FanMVPListWidget } from "../../widgets/fan-mvp";

const FanMVPListPage = () => {
    return (
        <PageTemplate>
            <div className="min-h-96 max-h-screen flex flex-col items-center justify-center gap-8 pt-8 max-w-4xl mx-auto text-center">
                <motion.div
                    initial={{ opacity: 0, y: -50 }} // Начальное состояние (невидимо и смещено вниз)
                    animate={{ opacity: 1, y: 0 }}  // Конечное состояние (видимо и на месте)
                    transition={{ duration: 0.5 }}  // Длительность анимации
                    className="text-7xl font-bold italic text-white"
                >
                    Лучшие игроки <br /> по версии болельщиков
                </motion.div>
            </div>
            <div className="w-full mx-auto text-white text-2xl text-center pb-16">
                <FanMVPListWidget />
            </div>
        </PageTemplate>
    )
}

export default FanMVPListPage;
import { UpCircleOutlined, DownCircleOutlined } from "@ant-design/icons";
import {
    CollapseIcon,
    Container,
    Header,
    LeftSideContainer,
    RightSideContainer,
    Title,
    Image,
    Point,
    Content,
    CollapsingText,
    TextWrapper,
    ExpandButton,
} from "./styled";
import { ReactNode, useCallback, useEffect, useState } from "react";
import useElementHeight from "../../../hooks/useElementHeight";

interface TimelineElementProps {
    title: string;
    shortDescription: ReactNode
    description: ReactNode;
    imageUrl?: string;
    icon?: ReactNode;
    isLast?: boolean;
    selected?: boolean;
    onClick?: () => void;
    extra?: ReactNode;
    key?: string;
}

export const TimelineElement: React.FC<TimelineElementProps> = ({
    title,
    shortDescription,
    description,
    isLast,
    icon,
    imageUrl,
    selected = false,
    onClick,
    extra,
    key,
}) => {
    const [collapsed, setCollapsed] = useState(selected);
    const [collapsedRef, collapsedHeight] = useElementHeight();
    const [expandedRef, expandedHeight] = useElementHeight();
    const [rightSideRef, rightSideHeight] = useElementHeight();

    useEffect(() => {
        setCollapsed(!selected);
    }, [selected]);

    const handleCollapse = useCallback(() => onClick ? onClick() : setCollapsed(!collapsed), [onClick, collapsed]);

    return (
        <Container id={key}>
            {extra}
            <LeftSideContainer $height={rightSideHeight} $collapsed={collapsed}>
                <Image src={imageUrl} alt={title} onClick={handleCollapse}/>
            </LeftSideContainer>
            <RightSideContainer $isLast={isLast} ref={rightSideRef} $collapsed={collapsed}>
                <Header onClick={handleCollapse}>
                    <Point>{icon}</Point>
                    <Title>{title}</Title>
                    <CollapseIcon>
                        {collapsed && <DownCircleOutlined />}
                        {!collapsed && <UpCircleOutlined />}
                    </CollapseIcon>
                </Header>
                <Content $collapsed={collapsed} onClick={() => collapsed && handleCollapse()}>
                    <CollapsingText $collapsed={collapsed} $maxHeight={collapsedHeight} $transitionDuration={collapsed ? 0.1 : 1}>
                        <TextWrapper ref={collapsedRef}>
                            {shortDescription} {collapsed && <ExpandButton />}
                        </TextWrapper>
                    </CollapsingText>
                    <CollapsingText $collapsed={!collapsed} $maxHeight={expandedHeight} $transitionDuration={collapsed ? 0.3 : 0.6}>
                        <TextWrapper ref={expandedRef}>
                            {description}
                        </TextWrapper>
                    </CollapsingText>
                </Content>
            </RightSideContainer>
        </Container>
    );
}
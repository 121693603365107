import { FC, useEffect, useState } from "react"
import { TeamContext } from "../../../types/common";
import { StatsPage } from "../page";
import { StatsType, TournamentIds } from "../../../types/stats";
import { TournamentTableWidget } from "../../../widgets/stats/tournament-table/TournamentTableWidget";
import { Image } from "antd";
import { getTournamentTableImage } from "../../../api/transport/statsTransport";

interface TournamentTablePageProps {
    teamContext?: TeamContext;
    seasonId?: number;
    tournamentId?: number;
    teamId?: number;
}

export const TournamentTablePage: FC<TournamentTablePageProps> = ({
    teamContext,
}) => {
    const [tournamentIds, setTournamentIds] = useState<TournamentIds>();
    const [tournamentTableImage, setTournamentTableImage] = useState<string>('');

    useEffect(() => {
        if (!tournamentIds?.tournamentUuid) {
            setTournamentTableImage('');
            return;
        }

        if (teamContext === TeamContext.ACADEMY) {
            getTournamentTableImage(tournamentIds?.tournamentUuid).then(image => {
                setTournamentTableImage(image || '');
            })
        }

    }, [tournamentIds?.tournamentUuid]);

    return <>
        <StatsPage
            teamContext={teamContext}
            pageType={StatsType.TOURNAMENT_TABLE}
            onTournamentChange={(tournamentUuid, seasonUuid, legacyTournament, legacySeason) => setTournamentIds({
                tournamentUuid, seasonUuid, legacyTournament, legacySeason
            })}
        >
            {tournamentTableImage && (
                <div className="w-full pb-4">
                    <Image src={tournamentTableImage} alt="Итоговая таблица" className="w-full" />
                </div>
            )}
            {tournamentIds?.legacyTournament && tournamentIds?.legacySeason && (
                <TournamentTableWidget
                    teamName={teamContext}
                    seasonId={tournamentIds.legacySeason}
                    tournamentId={tournamentIds.legacyTournament}
                />
            )}
        </StatsPage>
    </>
}
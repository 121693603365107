import { FC } from "react";

interface ButtonProps {
    title?: string;
    onClick?: () => void;
    href?: string;
    disabled?: boolean;
    inverted?: boolean;
    target?: string;
    tooltip?: string;
    className?: string;
}

export const Button: FC<ButtonProps> = ({
    title,
    onClick,
    href,
    disabled,
    inverted,
    target = '_self',
    tooltip = '',
    className = '',
}) => {
    let classNames = 'py-4 px-6 border border-solid border-white cursor-pointer text-xl font-bold rounded-full no-underline uppercase transition-colors duration-200 select-none';
    let linkClassNames = 'block w-fit no-underline hover:no-underline';
    if (disabled) {
        classNames += ' opacity-50 cursor-not-allowed';
        linkClassNames += ' cursor-not-allowed';
        tooltip = 'Скоро будет доступно';
        href = undefined;
        onClick = undefined;
    }

    if (inverted) {
        classNames += " bg-transparent text-white hover:bg-white hover:text-slate-950";
    } else {
        classNames += " bg-white text-slate-950 hover:bg-transparent hover:text-white";
    }

    if (className) {
        classNames += ` ${className}`;
        linkClassNames += ` ${className}`;
    }

    if (href) {
        return (
            <a href={href} className={linkClassNames} target={target} title={tooltip}>
                <div className={classNames} onClick={onClick}>
                    {title}
                </div>
            </a>
        )
    }

    return (
        <div className={classNames} onClick={onClick} title={tooltip}>
            {title}
        </div>
    )
}

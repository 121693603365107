import { FC, useEffect, useState } from "react";
import { PageContent } from "../../../components/layout";
import { getAdministrative, getCoaches } from "../../../api/transport/staffTransport";
import { PersonCard, PersonList } from "../../../components/PersonCard";
import { StaffDto } from "../../../types/staff";
import { Loader } from "../../../components/Loader";
import { getFormattedDateNumeric, getStaticUrl } from "../../../utils";
import { useConfiguration } from "../../../hooks/useConfiguration";
import { TeamContext } from "../../../types/common";

interface CoachesPageProps {}

const CoachesPage: FC<CoachesPageProps> = () => {
    const [loading, setLoading] = useState(true);
    const [staff, setStaff] = useState<StaffDto[]>([]);
    const config = useConfiguration();

    useEffect(() => {
        setLoading(true);
        getCoaches(config?.teamName)
            .then(async (data) => {
                let staffToSet: StaffDto[] = [...data];
                if (config?.teamName === TeamContext.RODINA || config?.teamName === TeamContext.RODINA_2 || config?.teamName === TeamContext.RODINA_3) {
                    const admins = await getAdministrative(config?.teamName);
                    staffToSet = [...data, ...admins];
                    staffToSet.sort((a, b) => a.sortOrder - b.sortOrder);
                }

                setStaff(staffToSet);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [config?.teamName]);

    if (loading) {
        return <Loader />;
    }

    return (
        <PageContent>
            <PersonList>
                {staff.map(staffMember => {
                    let birthDate = staffMember.birthDate ? getFormattedDateNumeric(new Date(staffMember.birthDate)) : '';
                    if (config?.teamName === TeamContext.RODINA || config?.teamName === TeamContext.RODINA_2 || config?.teamName === TeamContext.RODINA_3) {
                        birthDate = '';
                    }
                    return (
                        <PersonCard
                            key={staffMember.uuid}
                            name={staffMember.fullName}
                            title={staffMember.title}
                            photo={getStaticUrl(staffMember.photoUrl)}
                            birthDate={birthDate}
                        />
                    )
                })}
            </PersonList>
        </PageContent>
    );
}

export default CoachesPage;
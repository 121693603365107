import { FC } from "react";

interface ScreenshotFeedbackItemProps {
    imageUrl: string;
    linkUrl?: string;
}

export const ScreenshotFeedbackItem: FC<ScreenshotFeedbackItemProps> = ({
    imageUrl,
    linkUrl,
}) => {
    return (
        <a href={linkUrl} target="_blank" className="max-w-[500px] w-full bg-white py-2 rounded-lg border border-slate-200 border-solid hover:shadow">
            <img src={imageUrl} className="w-full max-w-[500px]" alt="feedbakItem" />
        </a>
    );
}
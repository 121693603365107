import styled from "styled-components";
import { Layout } from "antd";

const { Header: AntHeader } = Layout;

export const Header = styled(AntHeader)`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
    height: 64px;
    color: white;
    box-sizing: border-box;
`;

export const HeaderContent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    max-width: calc(100% - 100px);
`;

export const Title = styled.div`
    font-size: 19px;
    font-weight: 400;
    line-height: 1.75rem;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-left: 1.25rem;
    max-width: 80%;
`;